/* eslint-disable import/max-dependencies */
// @flow
import React, { type Node, type StatelessFunctionalComponent, useState } from "react";
import { Helmet } from "react-helmet";
import { type CardSetupProps, SetupCard } from "@fas/cpa-cabinet-ui";
import { withStyles } from "@mui/styles";
import {
  Box, Tab, Tabs,
} from "@mui/material";
import { setupCardStyles } from "../../styles";
import type { QuestionTabType, QuestionType } from "./types/Faq.types";
import { questions } from "./questions";
import type { UseState } from "../EditReportButton/types/EditReportButton.types";
import FaqCard from "./FaqCard";
import type { DefaultTheme } from "../../themes";

type Props = {
};

const StyledSetupCard: StatelessFunctionalComponent<CardSetupProps> = withStyles(setupCardStyles)(SetupCard);
const StyledTabs: StatelessFunctionalComponent<*> = withStyles((theme: DefaultTheme): * => ({
  root: {
    paddingTop: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.transparent.light}`,
  },
}))(Tabs);

const Faq: StatelessFunctionalComponent<Props> = () => {
  const [tab, setTab]: UseState<QuestionTabType> = useState("general");
  const visibleQuestions: QuestionType[] = questions.filter((q: QuestionType): boolean => q.tab === tab);
  return (
    <StyledSetupCard title="Need help? Check the FAQ!">
      <Helmet>
        <title>FAQ</title>
      </Helmet>
      <StyledTabs
        variant="scrollable"
        value={tab}
        onChange={(_: *, newTab: QuestionTabType): * => setTab(newTab)}
      >
        <Tab value="general" label="General Questions" />
        <Tab value="started" label="Questions for Getting Started" />
        <Tab value="payment" label="Payment Questions" />
        <Tab value="technical" label="Technical Questions" />
      </StyledTabs>
      <Box sx={{ height: "100%", overflow: "auto" }}>
        {visibleQuestions.map((q: QuestionType): Node => <FaqCard key={q.question} data={q} />)}
      </Box>
    </StyledSetupCard>
  );
};

export default Faq;

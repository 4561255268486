/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import { Helmet } from "react-helmet";
import {
  SetupCard,
  withHookPropsState,
  Table,
  type CardSetupProps,
} from "@fas/cpa-cabinet-ui";
import { withStyles } from "@mui/styles";
import { usePagination } from "@fas/cpa-state-manager/redux/hooks";
import { useSelector } from "react-redux";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import { getTableFields } from "@fas/cpa-state-manager/services/selectors/table";
import { Box } from "@mui/material";
import { setupCardStyles, reportTableStyles, paymentHistory } from "../../styles";
import { PAYMENT_HISTORY_TABLE } from "../../constants";
import {
  usePaymentHistoryActions,
  usePaymentHistoryTable,
} from "../../hooks";
import withNoData from "../WithNoData";
import Pagination from "../Pagination";
import PaymentHistoryActions from "../PaymentHistoryActions";
import type {
  Props,
  PaginationProps,
  ActionsProps,
  TableProps,
} from "./types/PaymentHistory.types";
import ReportSkeletonLoading from "../Report/ReportSkeletonLoading";
import { useTableNoData } from "../../hooks/useTableNoData";
import NoData from "../NoData";

const TableWithNoData: StatelessFunctionalComponent<TableProps> = withHookPropsState(withNoData(
  (): Node => (
    <Box sx={{
      height: 1, display: "flex", justifyContent: "center", alignItems: "center",
    }}
    >
      <NoData
        headerText="No data available for the selected filters"
        text="Select the right filters to see your results, or try new cool offers to give your stats a boost!"
      />
    </Box>
  ),
  (): Node => null
), useTableNoData);

const Actions: StatelessFunctionalComponent<ActionsProps> = withHookPropsState(
  PaymentHistoryActions,
  usePaymentHistoryActions
);
const PaymentHistoryTable: StatelessFunctionalComponent<TableProps> = withHookPropsState(
  Table,
  usePaymentHistoryTable
);
const PaymantHistoryPagination: StatelessFunctionalComponent<PaginationProps> = withHookPropsState(
  Pagination,
  usePagination
);
const ReportLoading: * = withHookPropsState(ReportSkeletonLoading, ({ tableKey }: { tableKey: string }): * => {
  const isLoading: boolean = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, tableKey));
  return {
    isLoading,
    fieldsLength: useSelector((state: *): * => getTableFields(state, tableKey)).length - 1,
  };
});

const StyledSetupCard: StatelessFunctionalComponent<CardSetupProps> = withStyles(setupCardStyles)(SetupCard);
const StyledActions: StatelessFunctionalComponent<ActionsProps> = withStyles(paymentHistory)(Actions);
const StyledTable: StatelessFunctionalComponent<TableProps> = withStyles(reportTableStyles)(PaymentHistoryTable);

const PaymantHistory: StatelessFunctionalComponent<Props> = () => (
  <StyledSetupCard title="Payment History">
    <Helmet>
      <title>Payment history</title>
    </Helmet>
    <StyledActions />
    <StyledTable>
      <ReportLoading hookProps={{ tableKey: PAYMENT_HISTORY_TABLE }} />
    </StyledTable>
    <TableWithNoData hookProps={{ tableKey: PAYMENT_HISTORY_TABLE }} />
    <PaymantHistoryPagination hookProps={PAYMENT_HISTORY_TABLE} />
  </StyledSetupCard>
);

export default PaymantHistory;

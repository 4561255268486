// @flow
/* eslint-disable import/max-dependencies */
import { type StatelessFunctionalComponent, type Node } from "react";
import { useSelector, useDispatch } from "react-redux";
import { addEventsPostbackItem } from "@fas/cpa-state-manager/redux/actions/eventsPostback";
import { selectCurrentTab, type StoreWithTabs } from "@fas/cpa-state-manager/services/selectors/tabs";
import { AddCircleOutline } from "@mui/icons-material";
import { useIsAffiliate } from "@fas/cpa-state-manager/redux/hooks";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";
import { EVENTS_POSTBACK } from "@fas/cpa-state-manager/redux/constants";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import {
  POSTBACKS_PAGE,
  INFO_POSTBACKS_TAB,
} from "../../constants";

export type UseAddInfoPostbacksHook = {
  visible?: boolean,
  children: Node,
  Icon?: StatelessFunctionalComponent<*>,
  onClick: () => mixed,
};

export const useAddInfoPostbacks: () => UseAddInfoPostbacksHook = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const currentTab: string = useSelector((state: StoreWithTabs): string => selectCurrentTab(state, POSTBACKS_PAGE));
  const theme: * = useTheme();
  const isMob: boolean = useMediaQuery(theme.breakpoints.down("lg"));
  const isLoading: * = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, EVENTS_POSTBACK));

  const onClick: () => mixed = () => dispatch(addEventsPostbackItem());

  return {
    disabled: isLoading,
    visible: useIsAffiliate() && currentTab === INFO_POSTBACKS_TAB,
    children: isMob ? "Add new" : "Create new postback",
    Icon: AddCircleOutline,
    onClick,
  };
};

// @flow
import { type DefaultTheme } from "../themes";
import { APP_BAR_HEIGHT } from "../pages/Cabinet/types/Cabinet.types";

const appNavSidebarStyles: (DefaultTheme) => * = (theme) => ({
  paper: {
    justifyContent: "flex-start",
    boxShadow: "none",
  },
  drawer: {
    "& > div": {
      paddingBottom: theme.spacing(2),
      borderRight: "none",
      marginTop: `${APP_BAR_HEIGHT}`,
      height: `calc(100% - ${APP_BAR_HEIGHT})`,
    },
  },
  drawerOpen: {
    width: "260px",
    transition: "none",
    [theme.breakpoints.down("lg")]: {
      width: "200px",
    },
  },
  drawerClose: {
    width: "64px",
    transition: "none",
  },
});

export default appNavSidebarStyles;

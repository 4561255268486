// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import handleError from "@fas/cpa-state-manager/redux/sagas/handleError";

export default function* mainSaga(): Saga<void> {
  return yield all([
    call(handleError),
  ]);
}

// @flow

import { type DefaultTheme } from "../themes";

const tabsSwitcher: (DefaultTheme) => * = (theme) => ({
  divider: {
    flexGrow: 1,
    marginBottom: theme.spacing(1),
    marginTop: theme.spacing(1),
    [theme.breakpoints.down("lg")]: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
});

export default tabsSwitcher;

// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Button,
  Box,
  Typography,
  CircularProgress,
} from "@mui/material";
import { DateRangePicker } from "@fas/cpa-cabinet-ui";
import type { Props } from "./types/PaymentHistoryActions.types";
import DateRangeFooter from "../DateRangeFooter";
import DateInputComponent from "../ConfigureMainReport/DateInputComponent";
import { maxDate, minDate } from "../Reports/presets";
import { presets } from "./presets";
import Download from "../../icons/download";

const TableFilters: StatelessFunctionalComponent<Props> = ({
  isLoading,
  isExportLoading,
  onApply,
  onExport,
  onChangeDate,
  isNoData,
  filters: {
    dateFrom,
    dateTo,
  },
  classes = {},
}) => (
  <Box pt={2}>
    <Box className={classes.exportContainer}>
      <Typography variant="bodyBold" noWrap>
        Export invoices
      </Typography>
      <Button
        variant="transparent"
        onClick={onExport}
        data-testid="export-btn"
        className={classes.exportButton}
        disabled={isNoData || isLoading || isExportLoading}
      >
        {isExportLoading ? <CircularProgress size={24} /> : <Download />}
      </Button>
    </Box>
    <Box display="flex" flexDirection="row" justifyContent="flex-start" alignItems="center" className={classes.filtersContainer ? classes.filtersContainer : ""}>
      <DateRangePicker
        applyOnClose
        applyOnSelect
        onChangeDate={onChangeDate}
        onChangePreset={onChangeDate}
        startDate={String(dateFrom)}
        endDate={String(dateTo)}
        readOnly={false}
        format="YYYY-MM-DD"
        mask="9999-99-99 - 9999-99-99"
        presets={presets}
        minDate={minDate}
        maxDate={maxDate}
        data-testid="date-range-picker"
        disabled={isLoading || isExportLoading}
        FooterComponent={DateRangeFooter}
        DateInputComponent={DateInputComponent}
      />
      <Button
        className={classes.applyFiltersBtn}
        data-testid="apply-btn"
        variant="gradient"
        onClick={onApply}
        disabled={isLoading || isExportLoading}
      >
        apply filters
      </Button>
    </Box>
  </Box>
);

export default TableFilters;

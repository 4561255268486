// @flow

import type { UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import { useDictionary } from "@fas/cpa-state-manager/redux/hooks";
import { useMemo } from "react";

export type UseDictionaryValueListHook = UseDictionaryType;

export function useDictionaryValueList(dictionary: string): UseDictionaryValueListHook {
  const {
    options,
    isLoading,
  }: * = useDictionary(dictionary);

  const optionsList: * = useMemo(
    (): * => options.map(({ value }: *): * => ({ value, title: String(value).toUpperCase() })), [options]
  );

  return {
    isLoading,
    options: optionsList,
  };
}

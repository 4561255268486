/* eslint-disable import/max-dependencies */
// @flow
import type { CombinedReducer, Dispatch, Store } from "redux";
import {
  applyMiddleware, combineReducers, compose, createStore,
} from "redux";
import notifications, {
  initNotificationsState,
  type State as NotificationsState,
} from "@fas/ui-framework/lib/redux/reducers/notifications";
import type { Action as NotificationsActions } from "@fas/ui-framework/lib/redux/actions/notifications";
import Immutable from "immutable";
import createSagaMiddleware, { type SagaMiddleware } from "redux-saga";
import mainSaga from "./saga";

export type State = $ReadOnly<{
  notifications: NotificationsState,
}>;

export type Actions = NotificationsActions

export type CabinetStore = Store<State, Actions>;

export const mapCabinetToState: () => State = () => ({
  notifications: initNotificationsState(),
});

const reducers: CombinedReducer<State, Actions> = combineReducers({
  notifications,
});

const sagaMiddleware: SagaMiddleware<{}> = createSagaMiddleware();

export default (): CabinetStore => {
  // eslint-disable-next-line no-underscore-dangle, flowtype/require-variable-type
  const composeEnhancers = typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
    serialize: {
      immutable: Immutable,
    },
  }) : compose;

  const store: Store<State, Actions> = createStore<State, Actions, Dispatch<Actions>>(
    reducers,
    mapCabinetToState(),
    composeEnhancers(applyMiddleware(sagaMiddleware))
  );

  sagaMiddleware.run(mainSaga);

  return store;
};

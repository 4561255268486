// @flow
import React, { type StatelessFunctionalComponent } from "react";
import environment from "environment";
import {
  Button, IconButton, Modal, Paper, Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Close } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { BackdropComponent } from "./BackdropComponent";
import type { DefaultTheme } from "../../themes";

type Props = {
  open: boolean,
  onClose: () => *,
};

const StyledPaper: Paper = withStyles((theme: DefaultTheme): * => ({
  root: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    maxWidth: 400,
    minWidth: 320,
    boxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.2)",
    padding: theme.spacing(2),
    borderRadius: theme.shape.cardBorderRadius,
    "&:focus-visible": {
      outline: "none",
    },
  },
}))(Paper);

const NoPaymentDetailsModal: StatelessFunctionalComponent<Props> = ({ open, onClose }) => (
  <Modal
    open={open}
    onClose={onClose}
    BackdropComponent={BackdropComponent}
    data-testid="no-payment-details-modal"
  >
    <StyledPaper>
      <IconButton
        data-testid="close"
        onClick={onClose}
        size="small"
        disableRipple
        sx={{
          position: "absolute", right: 0, top: 0, color: "transparent.text",
        }}
      >
        <Close />
      </IconButton>
      <Typography data-testid="modal-title" variant="bodyBold" component="p" sx={{ textAlign: "center" }}>
        For getting payouts in time follow next instructions
      </Typography>
      <Typography data-testid="modal-description" sx={{ mt: 1, mb: 2 }} variant="subtext" component="p">
        Please modify your personal details, choose payout method and provide your payment details below.
        Please make sure you provide accurate payment details.
        Commission payments will be made NET-15 if you reach minimum amount of $500
        (except bank transfer, which is $3000)
      </Typography>
      <Button
        component={Link}
        to={environment.links.userPaymentDetails}
        fullWidth
        variant="gradient"
        data-testid="next"
        onClick={onClose}
      >
        Check Your Payment Info
      </Button>
    </StyledPaper>
  </Modal>
);

export default NoPaymentDetailsModal;

// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { UploadPhotoComponent } from "@fas/cpa-cabinet-ui";
import { withStyles } from "@mui/styles";
import { type Props as UploaderProps } from "@fas/cpa-cabinet-ui/lib/UploadPhotoComponent/UploadPhotoComponent.types";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";
import { useDispatch } from "react-redux";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { uploadPhotoStyles } from "../../styles";

const StyledUploader: StatelessFunctionalComponent<UploaderProps> = withStyles(uploadPhotoStyles)(UploadPhotoComponent);

type Props = {
  name: string,
  value?: string | null | File,
  onChange?: (*) => *,
  disabled?: boolean,
};

const defaultChange: (*) => * = (e) => e;

const UploadPhoto: StatelessFunctionalComponent<Props> = ({
  name,
  value = null,
  onChange = defaultChange,
  disabled,
}) => {
  const dispatch: <A>(A) => A = useDispatch();

  return (
    <StyledUploader
      data-testid={`upload-photo-${name}`}
      value={value}
      disabled={disabled}
      onChange={onChange}
      onError={(): * => dispatch(addNotification({ severity: "error", message: "Photo upload failed. Your photo must be no larger than 3 MB and be in .png, .jpg or .gif format" }))}
      accept="image/png, image/jpeg, image/gif"
      maxSize={3}
    />
  );
};

export default withFormField(UploadPhoto);

// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Person: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="3 3 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15 17C17.2091 17 19 15.2091 19 13C19 10.7909 17.2091 9 15 9C12.7909 9 11 10.7909 11 13C11 15.2091 12.7909 17 15 17C17.2091 17 15 17 15 17ZM15 15C16.1046 15 17 14.1046 17 13C17 11.8954 16.1046 11 15 11C13.8954 11 13 11.8954 13 13C13 14.1046 13.8954 15 15 15C16.1046 15 15 15 15 15ZM20.9261 23.0557C23.397 21.235 25 18.3047 25 15C25 9.47715 20.5228 5 15 5C9.47715 5 5 9.47715 5 15C5 18.3047 6.60301 21.235 9.07388 23.0557C9.11934 23.0892 9.16509 23.1223 9.21112 23.1551C9.65997 23.4742 10.1363 23.7573 10.6359 24C11.9546 24.6407 13.4354 25 15 25C16.5646 25 18.0454 24.6407 19.3641 24C19.3909 23.987 19.4177 23.9738 19.4443 23.9606C19.9657 23.7015 20.4613 23.3982 20.9261 23.0557C23.397 21.235 20.9261 23.0557 20.9261 23.0557ZM20.2287 21.055C21.926 19.588 23 17.4194 23 15C23 10.5817 19.4183 7 15 7C10.5817 7 7 10.5817 7 15C7 17.4194 8.07401 19.588 9.77128 21.055C10.8007 19.2313 12.7565 18 15 18C17.2435 18 19.1993 19.2313 20.2287 21.055C21.926 19.588 20.2287 21.055 20.2287 21.055ZM18.5567 22.1679C17.8921 20.8803 16.5488 20 15 20C13.4512 20 12.1079 20.8803 11.4433 22.1679C12.5147 22.7005 13.7224 23 15 23C16.2776 23 17.4853 22.7005 18.5567 22.1679C18.5567 22.1679 17.8921 20.8803 18.5567 22.1679Z"
    />
  </SvgIcon>
);

export default Person;

// @flow
import { useCallback, useEffect } from "react";
import useFormField from "@fas/ui-framework/lib/services/form/useFormField";
import { useCookies } from "react-cookie";
import { NO_PAYMENT_DETAILS_MODAL } from "../../constants";

export type UseNoPaymentDetailsModalHook = {
  open: boolean,
  onClose: () => void,
};

export const useNoPaymentDetailsModal: () => UseNoPaymentDetailsModalHook = () => {
  const {
    value: open,
    onChange: setOpen,
  }: { value: mixed, onChange: (mixed) => mixed } = useFormField(NO_PAYMENT_DETAILS_MODAL, "open");
  const [cookie, setCookies]: [*, *] = useCookies([NO_PAYMENT_DETAILS_MODAL]);

  const onClose: () => * = useCallback(() => {
    setOpen(false);
    setCookies(NO_PAYMENT_DETAILS_MODAL, "shown", { path: "/", maxAge: 60 * 60 * 24 });
  }, []);

  const isAlreadyShown: boolean = cookie[NO_PAYMENT_DETAILS_MODAL] === "shown";
  useEffect(() => {
    if (open && isAlreadyShown) {
      onClose();
    }
  }, [onClose, open, isAlreadyShown]);

  return {
    open: Boolean(open),
    onClose,
  };
};

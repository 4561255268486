// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Statistics: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M3 20C3 20.553 3.447 21 4 21H21V19H5V3H3V20ZM16.1436 14.0984L21 9.34443L19.6266 8L15.4569 12.0818L13.2298 9.90159C12.85 9.52983 12.2362 9.52983 11.8564 9.90159L7 14.6556L8.37339 16L12.5431 11.9182L14.7702 14.0984C15.15 14.4702 15.7638 14.4702 16.1436 14.0984Z" />
  </SvgIcon>
);

export default Statistics;

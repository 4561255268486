// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import {
  Typography,
  Grid,
  Chip, Box,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { howToChipStyles, howToChipsStyles } from "../../styles";
import type { HowToItem } from "./types/HowToCreatePostback.types";

const StyledChip: StatelessFunctionalComponent<*> = withStyles(howToChipStyles)(Chip);

const items: HowToItem[] = [
  {
    label: ["{payout}"],
    example: "Amount paid to you for conversion",
  },
  {
    label: ["{country}"],
    example: "Country name of transaction",
  },
  {
    label: ["{platform}"],
    example: "Platform of transaction",
  },
  {
    label: ["{clickid}"],
    example: "Your click/transaction ID (You can enter maximum 256 characters)",
  },
  {
    label: ["{Date}", "{Time}", "{DateTime}"],
    example: "Datetime of transaction",
  },
  {
    label: ["{subid}"],
    example: "Additional parameter (to mark your sources/any additional information)",
  },
  {
    label: ["{subid2}"],
    example: "Your own additional parameter 3",
  },
  {
    label: ["{conversionType}"],
    example: "Transaction type (\"lead\", \"sale\", \"install\", \"confirm\")",
  },
  {
    label: ["{tds_cid}"],
    example: "Internal click_id (You can enter maximum 40 characters containing letters and numbers)",
  },
  {
    label: ["{payout_in_currency}"],
    example: "Amount paid to you for conversion in offer currency",
  },
  {
    label: ["{currency}"],
    example: "Offer currency (\"USD\", \"EUR\", \"GBP\" ...)",
  },
];

const StyledItem: StatelessFunctionalComponent<HowToItem> = withStyles(howToChipsStyles)(({
  label, example, classes = {},
}: HowToItem): Node => (
  <Box className={classes.container}>
    <Box className={classes.chips}>
      {label.map((labelItem: string): Node => <StyledChip label={labelItem} key={labelItem} />)}
    </Box>
    <Box className={classes.labels}>
      <Typography component="span" className={classes.label}>
        {example}
      </Typography>
    </Box>
  </Box>
));

const HowToCreatePostback: StatelessFunctionalComponent<{}> = () => (
  <Grid container direction="column" rowSpacing={1}>
    <Grid item xs={1}>
      <Typography variant="gradientH4">How to create own postback URLs</Typography>
    </Grid>
    <Grid item xs={2}>
      <Typography>
        Here you can create your own postback URLs that will be sent any time you have a new transaction.
      </Typography>
      <Typography>
        The example of postback URL:
        <Typography variant="gradientBody" sx={{ display: "inline", ml: 1 }}>
          {"http://your-domain.com/tracking.php?payout={payout}&clickid={clickid}"}
        </Typography>
      </Typography>
      <Typography sx={{ pb: 1 }}>
        After creating the postback URL, ask your manager to approve it. If the code is not approved, it will not work.
      </Typography>
    </Grid>
    <Grid container item xs>
      <Grid item xs={12} sm={6} sx={{ marginRight: { sm: "10px" } }}>
        {items.slice(0, 6).map((item: HowToItem): Node => <StyledItem key={item.example} {...item} />)}
      </Grid>
      <Grid item xs rowSpacing={1}>
        {items.slice(6).map((item: HowToItem): Node => <StyledItem key={item.example} {...item} />)}
      </Grid>
    </Grid>
  </Grid>
);

export default HowToCreatePostback;

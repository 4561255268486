// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import {
  Box,
  Button, Checkbox, FormControlLabel, Modal, Paper, Radio, Skeleton, Tooltip, Typography,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import type { Option } from "@fas/cpa-state-manager/redux/reducers";
import { BackdropComponent } from "./BackdropComponent";
import type { DefaultTheme } from "../../themes";

type Props = {
  isConfirm: boolean,
  open: boolean,
  isLoading: boolean,
  isLoadingOptions: boolean,
  options: Option[],
  selected: string,
  tooltipConfirmButton: string,
  onClose: () => *,
  onChangeNatureOfTraffic: (e: SyntheticInputEvent<HTMLElement>) => *,
  onChangeIsConfirm: (e: SyntheticInputEvent<HTMLElement>) => *,
};

const StyledCheckbox: Checkbox = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      fill: "url(#selected)",
    },
  },
})(Checkbox);

const StyledRadio: Radio = withStyles({
  root: {
    "& .MuiSvgIcon-root": {
      fill: "url(#selected)",
    },
  },
})(Radio);

const StyledPaper: Paper = withStyles((theme: DefaultTheme): * => ({
  root: {
    position: "absolute",
    top: "calc(50% )",
    left: "calc(50%)",
    transform: "translate(-50%, -50%)",
    maxWidth: 700,
    minWidth: 320,
    boxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.2)",
    padding: theme.spacing(2),
    borderRadius: theme.shape.cardBorderRadius,
    "&:focus-visible": {
      outline: "none",
    },
    display: "flex",
    flexDirection: "column",
    maxHeight: `calc(100% - ${theme.spacing(4)})`,
    width: `calc(100% - ${theme.spacing(4)})`,
  },
}))(Paper);

function SkeletonList(): Node {
  return Array.from({ length: 9 }, (_: *, index: number): Node => (
    <Skeleton key={index} sx={{ display: "inline-block", mt: 1, mr: 1 }} width={100} height={38} />
  ));
}

const NatureOfTrafficModal: StatelessFunctionalComponent<Props> = ({
  open,
  onClose,
  options = [],
  selected,
  onChangeNatureOfTraffic,
  isConfirm,
  onChangeIsConfirm,
  isLoading,
  tooltipConfirmButton,
  isLoadingOptions,
}) => (
  <Modal
    open={open}
    BackdropComponent={BackdropComponent}
    data-testid="nature-of-traffic-modal"
  >
    <StyledPaper>
      <Typography data-testid="modal-title" variant="gradientH4" component="p" sx={{ textAlign: "center" }}>
        Tell us more about the nature of your traffic so that we can find the best offers.
      </Typography>
      <Box data-testid="nature-of-traffic-options" textAlign="center" sx={{ overflow: "auto", my: 4 }}>
        {isLoadingOptions ? <SkeletonList /> : (
          options.map((option: Option): Node => (
            <FormControlLabel
              data-testid={option.value}
              key={option.value}
              name={String(option.value)}
              checked={selected === String(option.value)}
              control={<StyledRadio />}
              onChange={onChangeNatureOfTraffic}
              label={option.title}
              disabled={isLoading}
              sx={{ mt: 1, pr: 1, color: "transparent.dark" }}
            />
          ))
        )}
      </Box>
      <FormControlLabel
        data-testid="confirm-checkbox"
        disabled={isLoading}
        checked={isConfirm}
        onChange={onChangeIsConfirm}
        control={<StyledCheckbox />}
        label="I confirm that"
        sx={{ color: "transparent.dark" }}
      />
      <Typography data-testid="modal-description" sx={{ mt: 0.5, mb: 4, color: "transparent.dark" }} variant="body" component="p">
        I do not use any black-hat promotional methods
        <br />
        I do not use any illegal materials
        <br />
        I do not use any spamming systems for promotion
        <br />
        I have the owners permission for all of my promo materials
      </Typography>
      <Tooltip title={tooltipConfirmButton}>
        <span>
          <Button
            fullWidth
            disabled={!isConfirm || isLoading || selected.length === 0}
            variant="gradient"
            data-testid="next"
            sx={{ maxWidth: 300, margin: "auto", display: "flex" }}
            onClick={onClose}
          >
            Confirm
          </Button>
        </span>
      </Tooltip>
    </StyledPaper>
  </Modal>
);

export default NatureOfTrafficModal;

// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import { withStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { type DefaultTheme } from "../../themes";
import WarningIcon from "../../icons/WarningIcon";

type Props = {
  title: string,
  description: string,
  classes?: *,
};

const NoPostbacksData: StatelessFunctionalComponent<Props> = withStyles((theme: DefaultTheme): * => ({
  container: {
    background: theme.palette.transparent.light,
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(2),
  },
  header: {
    color: theme.palette.text.main,
    ...theme.typography.h5,
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.bodyBold,
      textAlign: "center",
    },
  },
  text: {
    color: theme.palette.transparent.default,
    marginTop: theme.spacing(1),
    ...theme.typography.body,
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.subtext,
      textAlign: "center",
    },
  },
  icon: {
    minWidth: "24px",
    minHeight: "24px",
    marginRight: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      marginBottom: theme.spacing(1),
      marginRight: 0,
      minWidth: "60px",
      minHeight: "60px",
    },
  },
}))(({ title, description, classes = {} }: Props): Element<*> => (
  <Box className={classes.container}>
    <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} alignItems="center">
      <WarningIcon className={classes.icon} />
      <Typography className={classes.header}>
        {title}
      </Typography>
    </Box>
    <Typography component="p" className={classes.text}>
      {description}
    </Typography>
  </Box>
));

export default NoPostbacksData;

// @flow
import React, { type Node } from "react";
import OverflowTip from "@fas/cpa-cabinet-ui/lib/OverflowTip";
import { useCopy } from "../SmartlinkList/SmartlinkList";

type Props = {
  children: string,
  // eslint-disable-next-line react/require-default-props
  classes?: *,
  // eslint-disable-next-line react/require-default-props
  wrapProps?: *,
}

// eslint-disable-next-line react/prefer-exact-props
export default function ToolTip(props: Props): Node {
  return (
    <OverflowTip {...props} onCopied={useCopy("")} />
  );
}

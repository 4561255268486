// @flow
import { type DefaultTheme } from "../themes";

const managerSmartlinkCard: (DefaultTheme) => * = (theme) => ({
  root: {
    boxShadow: "none",
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.transparent.light,
    padding: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
  nameContainer: {
    marginBottom: "10px",
  },
  name: {
    ...theme.typography.gradientH5,
    wordBreak: "break-all",
  },
  link: {
    marginTop: theme.spacing(2),
    margin: 0,
    ...theme.typography.subtext,
    color: theme.palette.transparent.dark,
    overflowWrap: "anywhere",
  },
  actions: {
    padding: 0,
    marginTop: theme.spacing(1),
  },
  copyBtn: {
    width: "140px",
  },
});

export default managerSmartlinkCard;

// @flow

import { type DefaultTheme } from "../themes";

const reportTableMainTotal: (DefaultTheme) => * = (theme) => ({
  tableBody: {
    gridArea: "total",
  },
  cell: {
    ...theme.typography.body,
    background: "#3c7065",
    border: "none",
    textAlign: "center",
    whiteSpace: "nowrap",
    fontSize: "16px",
    fontWeight: "500",
    lineHeight: "1",
    padding: "8px 10px",
    "&:last-child": {
      borderTopRightRadius: "4px",
      borderBottomRightRadius: "4px",
    },
    "&:first-child": {
      borderTopLeftRadius: "4px",
      borderBottomLeftRadius: "4px",
    },
    [theme.breakpoints.up("md")]: {
      position: "sticky",
      bottom: 0,
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "12px",
      padding: "4px 5px",
      fontWeight: "400",
      "&:first-child": {
        fontWeight: "700",
      },
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
      padding: "10px",
      border: `1px solid ${theme.palette.background.default}`,
      minHeight: "40px",
      "&:first-child": {
        padding: "10px",
        borderRadius: "0",
        fontWeight: "400",
      },
      "&:last-child": {
        borderRadius: "0",
      },
    },

    [theme.breakpoints.down("sm")]: {
      display: "block",
      fontSize: "9px",
      lineHeight: "18px",
      padding: "2px 3px",
      minHeight: "24px",
      "&:first-child": {
        fontSize: "9px",
        padding: "2px 3px",
      },
    },
  },
});

export default reportTableMainTotal;

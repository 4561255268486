// @flow
import dayjs from "dayjs";
import en from "dayjs/locale/en-au";
import type { Presets, Preset } from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePicker.types";

dayjs.locale(en);

export const presets: Presets = [
  {
    label: "This month",
    value: "thisMonth",
    start: dayjs().startOf("month").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Last month",
    value: "lastmonth",
    start: dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  },
  {
    label: "Last 6 months",
    value: "last6Month",
    start: dayjs().subtract(6, "month").startOf("month").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  },
  {
    label: "This year",
    value: "thisYear",
    start: dayjs().startOf("year").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Last year",
    value: "lastYear",
    start: dayjs().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
  },
];

export const defaultPaymentHistoryPreset: Preset = presets[3];

// @flow
import { type DefaultTheme } from "../themes";

const userInfo: (DefaultTheme) => * = (theme) => ({
  layout: {
    maxHeight: "45%",
  },
  card: {
    background: "none",
    border: "none",
    "& > .MuiCardContent-root": {
      paddingBottom: theme.spacing(2),
      paddingTop: theme.spacing(2),
      [theme.breakpoints.down("lg")]: {
        paddingBottom: theme.spacing(1),
      },
      [theme.breakpoints.down("md")]: {
        paddingTop: theme.spacing(4),
        paddingLeft: "7px",
        paddingRight: "7px",
      },
    },
  },
  profileInfo: {
    marginLeft: theme.spacing(0.5),
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
  profileId: {
    color: theme.palette.text.main,
    ...theme.typography.subtext,
  },
  profileName: {
    whiteSpace: "break-spaces",
    overflowWrap: "anywhere",
    color: theme.palette.text.main,
    ...theme.typography.buttonSmall,
    [theme.breakpoints.down("lg")]: {
      ...theme.typography.subtextBold,
    },
  },
  profileLink: {
    ...theme.typography.subtext,
    color: theme.palette.transparent.dark,
    textDecoration: "none",
    "&:hover": {
      cursor: "pointer",
    },
  },
});

export default userInfo;

// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node, type StatelessFunctionalComponent } from "react";
import { Skeleton } from "@mui/material";
import { withStyles } from "@mui/styles";

const LoadingIndicator: StatelessFunctionalComponent<{}> = withStyles(({ root: { height: "120px", transform: "none" } }))(Skeleton);
const LoadingLinks: () => Node = () => Array.from({ length: 6 }, (v: void, k: number): Node => (
  <LoadingIndicator key={k} />
));

export default LoadingLinks;

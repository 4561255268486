// @flow

import { useEffect, useMemo } from "react";
import type { UseHandleRequestHook } from "@fas/cpa-state-manager/redux/hooks";
import { useDictionary, useHandleRequest } from "@fas/cpa-state-manager/redux/hooks";
import type { UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import type { Option } from "@fas/cpa-state-manager/redux/reducers";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { useDispatch } from "react-redux";
import useFormField from "@fas/ui-framework/lib/services/form/useFormField";
import { getNatureOfTraffic, updateNatureOfTraffic } from "../../services/deprecatedApi";
import { NATURE_OF_TRAFFIC_MODAL } from "../../constants";

export type UseNatureOfTrafficModalHook = {
  open: boolean,
  isLoading: boolean,
  isLoadingOptions: boolean,
  isConfirm: boolean,
  onChangeIsConfirm: (SyntheticInputEvent<HTMLElement>) => *,
  onChangeNatureOfTraffic: (SyntheticInputEvent < HTMLElement >) => *,
  onClose: () => *,
  selected: string,
  tooltipConfirmButton: string,
  options: Option[],
};

function useValue<T>(key: string): { value: T, onChange: (T) => * } {
  const {
    value,
    onChange,
    // $FlowFixMe
  }: { value: T, onChange: (T) => * } = useFormField(NATURE_OF_TRAFFIC_MODAL, key);

  return {
    value,
    onChange,
  };
}

function getTooltipConfirmButton(isConfirm: boolean, selected: string): string {
  const tooltipMap: * = {
    isConfirm: {
      false: "Mark the confirm checkbox to continue",
    },
    isZeroSelected: {
      true: "Please specify nature of traffic",
    },
  };

  return tooltipMap.isConfirm[String(isConfirm)] || tooltipMap.isZeroSelected[String(selected.length === 0)] || "";
}

export const useNatureOfTrafficModal: () => UseNatureOfTrafficModalHook = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const {
    value: open,
    onChange: setOpen,
  }: { value: boolean, onChange: (boolean) => mixed } = useValue("open");
  const {
    value: isConfirm = false,
    onChange: setIsConfirm,
  }: { value: boolean, onChange: (boolean) => mixed } = useValue("isConfirm");
  const {
    value: initialSelected = "",
    onChange: onChangeInitialSelected,
  }: { value: string, onChange: (string) => mixed } = useValue("initialSelected");
  const {
    value: selected = "",
    onChange: onChangeSelected,
  }: { value: string, onChange: (string) => mixed } = useValue("selected");

  const { isLoading: isLoadingOptions, options }: UseDictionaryType = useDictionary("natureOfTraffic");

  const additionalOptions: Option[] = useMemo((): * => (initialSelected ? [initialSelected] : [])
    .filter((value: string): boolean => options.every((option: Option): boolean => option.value !== value))
    .map((value: string): Option => ({ title: value, value })), [initialSelected, options]);

  const {
    isLoading: isLoadingNaturesOfTraffic,
    changeLoading,
    onFail,
    onFinally,
  }: UseHandleRequestHook = useHandleRequest(NATURE_OF_TRAFFIC_MODAL);

  const {
    isLoading,
    changeLoading: changeLoadingSave,
    onFail: onFailSave,
    onFinally: onFinallySave,
  }: UseHandleRequestHook = useHandleRequest(`${NATURE_OF_TRAFFIC_MODAL}-update`);

  useEffect(() => {
    changeLoading(true);
    getNatureOfTraffic()
      .then((initSelected: string) => {
        onChangeInitialSelected(initSelected);
        onChangeSelected(initSelected);
      })
      .catch(onFail)
      .finally(onFinally);
  }, []);

  const saveTrafficSources: () => * = async () => {
    if (!isConfirm) return;

    changeLoadingSave(true);

    updateNatureOfTraffic(selected, isConfirm)
      .then(() => {
        dispatch(addNotification({ severity: "success", message: "Nature of traffic updated successfully" }));
        setOpen(false);
      })
      .catch(onFailSave)
      .finally(onFinallySave);
  };

  return ({
    tooltipConfirmButton: getTooltipConfirmButton(isConfirm, selected),
    isLoadingOptions: isLoadingNaturesOfTraffic || isLoadingOptions,
    isLoading,
    open: Boolean(open),
    isConfirm: Boolean(isConfirm),
    onChangeIsConfirm: (e: SyntheticInputEvent<HTMLElement>): * => setIsConfirm(e.target.checked),
    onClose: saveTrafficSources,
    selected,
    onChangeNatureOfTraffic: (e: SyntheticInputEvent<HTMLElement>): * => onChangeSelected(e.target.name),
    options: options.concat(additionalOptions),
  });
};

// @flow
import React from "react";
import type { Node } from "react";
import {
  Badge,
  Box,
  Button,
  IconButton,
  Typography,
} from "@mui/material";
import { Close } from "@mui/icons-material";
import Flag from "../../icons/Flag";

type Props = {
  sx?: Object,
  open: boolean,
  onClose: () => void
};

function HelpUkraineBanner({ open, onClose, sx }: Props): Node {
  return open && (
    <Box p={2} sx={{ ...sx, borderRadius: 3.75, backgroundColor: "transparent.light" }}>
      <Badge
        sx={{ display: "flex", flexDirection: "column" }}
        componentsProps={{ badge: { sx: { padding: 0, transform: "scale(1) translate(65%, -80%)" } } }}
        badgeContent={(
          <IconButton data-testid="close" size="small" onClick={onClose}>
            <Close sx={{ color: "transparent.default", fontSize: "1.2rem" }} />
          </IconButton>
        )}
      >
        <Box display="flex" alignItems="center">
          <Flag />
          <Typography sx={{ pl: 1, typography: { xs: "subtextBold", md: "subtextBold", lg: "bodyBold" } }}>Save Ukraine</Typography>
        </Box>
        <Box display="flex" sx={{ flexDirection: { xs: "row ", md: "column" }, alignItems: { xs: "center", md: "unset" }, justifyContent: { xs: "space-between", md: "unset" } }}>
          <Typography sx={{ py: 1, whiteSpace: "normal", color: "transparent.default" }} variant="subtext">Support via official site of the NBU. No private funds.</Typography>
          <Button variant="gradient" component="a" target="_blank" href="https://bit.ly/350UJDS" sx={{ height: 36, minWidth: "fit-content" }}>
            Donate
          </Button>
        </Box>
      </Badge>
    </Box>
  );
}

export default HelpUkraineBanner;

// @flow
import React, { type Node } from "react";

const ChatBar: () => Node = () => (
  <svg width="100%" height="100%" viewBox="0 0 120 107" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M33.3333 0.333008V107H53.3333V0.333008H33.3333ZM0 53.6663V107H20V53.6663H0ZM86.6667 20.333V107H66.6667V20.333H86.6667ZM100 40.333V107H120V40.333H100Z"
      fill="white"
      fillOpacity="0.3"
    />
  </svg>
);

export default ChatBar;

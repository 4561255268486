/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import { Outlet, LocationShape, Navigate } from "react-router-dom";
import { useAuth, type UseAuthHook } from "@fas/cpa-state-manager/hooks";
import {
  useLogin,
  useRestorePassword,
  useAutologin,
  useChangePassword,
  type UseLoginHookArgs,
  type UseRestorePasswordHookArgs,
  type UseAutologinHookArgs,
  type UseChangePasswordHookArgs,
} from "@fas/cpa-state-manager/redux/hooks";
import {
  withHookPropsState,
  type AuthRouterProps,
} from "@fas/cpa-cabinet-ui";
import NotificationsComponent from "@fas/cpa-cabinet-ui/lib/Notifications";
import withNotifications from "@fas/cpa-state-manager/services/notifications";
import environment from "environment";
import { Provider } from "react-redux";
import { Paper } from "@mui/material";
import { withStyles } from "@mui/styles";
import { useCookies } from "react-cookie";
import { GoogleReCaptchaProvider } from "react-google-recaptcha-v3";
import LoginForm from "../../components/Login";
import RestorePasswordForm from "../../components/RestorePassword";
import AutologinForm from "../../components/Autologin";
import ChangePasswordForm from "../../components/ChangePassword";
import configureStore, { type AuthStore } from "./store";
import { openZoneBackgroundStyles } from "../../styles";
import { NO_PAYMENT_DETAILS_MODAL } from "../../constants";

// $FlowFixMe
const Notifications: StatelessFunctionalComponent<{}> = withNotifications(NotificationsComponent);
const Background: StatelessFunctionalComponent<*> = withStyles(openZoneBackgroundStyles)(Paper);

const Login: StatelessFunctionalComponent<{ hookProps: UseLoginHookArgs }> = withHookPropsState(
  LoginForm,
  (props: *): * => {
    const [cookie,, removeCookie]: [*, *, *] = useCookies([NO_PAYMENT_DETAILS_MODAL]);
    if (cookie[NO_PAYMENT_DETAILS_MODAL]) {
      removeCookie(NO_PAYMENT_DETAILS_MODAL, { path: "/" });
    }
    return ({ ...useLogin(props) });
  }
);
const Autologin: StatelessFunctionalComponent<{ hookProps: UseAutologinHookArgs }> = withHookPropsState(
  AutologinForm,
  (props: *): * => {
    const [cookie,, removeCookie]: [*, *, *] = useCookies([NO_PAYMENT_DETAILS_MODAL]);
    if (cookie[NO_PAYMENT_DETAILS_MODAL]) {
      removeCookie(NO_PAYMENT_DETAILS_MODAL, { path: "/" });
    }
    return ({ ...useAutologin(props) });
  }
);
const ChangePassword: StatelessFunctionalComponent<{ hookProps: UseChangePasswordHookArgs }> = withHookPropsState(
  ChangePasswordForm,
  useChangePassword
);
const RestorePassword: StatelessFunctionalComponent<{ hookProps: UseRestorePasswordHookArgs }> = withHookPropsState(
  RestorePasswordForm,
  useRestorePassword
);

const routerComponents: $Diff<AuthRouterProps, UseAuthHook> = {
  Login: (): Node => (
    <GoogleReCaptchaProvider reCaptchaKey={environment.reCaptchaKey}>
      <Login hookProps={{ enableGoogleRecaptcha: !!environment.reCaptchaKey }} />
    </GoogleReCaptchaProvider>
  ),
  Autologin: (): Node => <Autologin hookProps={{ enableGoogleRecaptcha: false }} />,
  ChangePassword: (): Node => <ChangePassword hookProps={{ enableGoogleRecaptcha: false }} />,
  RestorePassword: (): Node => <RestorePassword hookProps={{ enableGoogleRecaptcha: false }} />,
};

type AuthProps = {
  redirectUrl?: string | LocationShape,
  Component: StatelessFunctionalComponent<*>,
};
const Auth: StatelessFunctionalComponent<AuthProps> = ({
  redirectUrl = "/",
  Component,
}) => {
  const { isAuthenticated } = useAuth();
  if (isAuthenticated) {
    return <Navigate to={redirectUrl} />;
  }

  return <Component />;
};

export const AuthLogin: StatelessFunctionalComponent<{}> = () => (
  <Auth Component={routerComponents.Login} redirectUrl={environment.links.home} />
);
export const AuthAutologin: StatelessFunctionalComponent<{}> = () => (
  <Auth Component={routerComponents.Autologin} redirectUrl={environment.links.home} />
);

export const AuthChangePassword: StatelessFunctionalComponent<{}> = () => (
  <Auth Component={routerComponents.ChangePassword} redirectUrl={environment.links.home} />
);

export const AuthRestorePassword: StatelessFunctionalComponent<{}> = () => (
  <Auth Component={routerComponents.RestorePassword} redirectUrl={environment.links.home} />
);

const store: AuthStore = configureStore();

const AuthLayout: StatelessFunctionalComponent<{}> = () => (
  <Provider store={store}>
    <Background>
      <Notifications />
      <Outlet />
    </Background>
  </Provider>
);

export default AuthLayout;

// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  Box, Button, Typography,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { styled } from "@mui/styles";
import image from "../../icons/404.png";

type Props = {};

const Image: StatelessFunctionalComponent<{ src: string }> = styled("img")({
  display: "block",
  maxWidth: "100%",
  maxHeight: "50dvh",
  width: "auto",
  height: "auto",
  margin: "auto",
  WebkitFilter: "drop-shadow(0 0 50px #000)",
  filter: "drop-shadow(0 0 50px #000)",
});

const PageNotFound: StatelessFunctionalComponent<Props> = ({}) => (
  <>
    <Helmet>
      <title>Not found</title>
    </Helmet>
    <Box sx={{
      width: "100%",
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      textAlign: "center",
    }}
    >

      <Image src={image} />
      <Box m={2}>
        <Typography variant="gradientH1">Oops</Typography>
        <Typography sx={{ my: 2, color: "transparent.default" }}>Please return to your dashboard, following the link below</Typography>
        <Button
          component="a"
          href="/"
          variant="gradient"
          fullWidth
          sx={{ maxWidth: "280px" }}
        >
          Go to dashboard
        </Button>
      </Box>
    </Box>
  </>
);

export default PageNotFound;

// @flow
import { all, call } from "redux-saga/effects";
import type { Saga } from "redux-saga";
import handleError from "@fas/cpa-state-manager/redux/sagas/handleError";
import getManagerInfoSaga from "@fas/cpa-state-manager/redux/sagas/getManagerInfo";
import getUserInfoSaga from "@fas/cpa-state-manager/redux/sagas/getUserInfo";
import { getManagerInfo, getUserInfo } from "../../services/deprecatedApi";
import watchGetReport from "../../sagas/getReportSaga";

const fetchManagerInfo: () => Saga<void> = getManagerInfoSaga({ fetchMethod: getManagerInfo });
const fetchUserInfo: () => Saga<void> = getUserInfoSaga({ fetchMethod: getUserInfo });

export default function* mainSaga(): Saga<void> {
  return yield all([
    call(handleError),
    call(fetchUserInfo),
    call(fetchManagerInfo),
    call(watchGetReport),
  ]);
}

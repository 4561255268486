// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import {
  AppNavSidebar as AppNavSidebarComponent,
  AppNavSidebarAdditionalComponent,
  AppNavSidebarMenuComponent as Menu,
  type AppNavSidebarMenuComponentProps as MenuProps,
  type AppNavSidebarProps,
  ManagerInfo,
  type ManagerInfoProps,
  OutstandingBalanceInfo,
  UserInfo,
  type UserInfoProps,
  withState,
} from "@fas/cpa-cabinet-ui";
import { useTheme, withStyles } from "@mui/styles";
import {
  useManagerInfo,
  type UseManagerInfoHook,
  useUserInfo,
  type UseUserInfoHook,
} from "@fas/cpa-state-manager/redux/hooks";
import { Box, useMediaQuery } from "@mui/material";
import { TabletManagerInfo } from "@fas/cpa-cabinet-ui/lib/ManagerInfo/ManagerInfo";
import OutstandingBalanceIcon from "../../icons/OutstandingBalanceIcon";
import { useOutstandingBalance } from "../../hooks";
import {
  managerInfoStyles, navBarMenuComponentStyles, outstandingBalanceStyles, userInfoStyles, appNavSidebarStyles,
} from "../../styles";
import links from "./links";
import { RenderMediaQueryComponent } from "../../utils";
import tabletManagerInfo from "../../styles/tabletManagerInfo";
import HelpUkraineBanner from "../Dashboard/HelpUkraineBanner";
import type { UseStorageHook } from "../../hooks/useStorage";
import { useLocalStorage } from "../../hooks/useStorage";

const StyledAppNavSidebarComponent: StatelessFunctionalComponent<AppNavSidebarProps> = withStyles(appNavSidebarStyles)(
  AppNavSidebarComponent
);
const StyledMenu: StatelessFunctionalComponent<MenuProps> = withStyles(navBarMenuComponentStyles)(Menu);
const StyledUserInfo: StatelessFunctionalComponent<UserInfoProps> = withStyles(userInfoStyles)(UserInfo);
const StyledManagerInfo: StatelessFunctionalComponent<ManagerInfoProps> = withStyles(managerInfoStyles)(ManagerInfo);
const StyledTabledManagerInfo: StatelessFunctionalComponent<ManagerInfoProps> = withStyles(tabletManagerInfo)(
  TabletManagerInfo
);
const StyledOutstandingBalance: StatelessFunctionalComponent<*> = withStyles(outstandingBalanceStyles)(
  OutstandingBalanceInfo
);

// $FlowFixMe
const User:StatelessFunctionalComponent<$Diff<UserInfoProps, UseUserInfoHook>> = withState(StyledUserInfo, useUserInfo);
const Manager: StatelessFunctionalComponent<$Diff<ManagerInfoProps, UseManagerInfoHook>> = withState(
  StyledManagerInfo,
  useManagerInfo
);
const TabletManager: StatelessFunctionalComponent<$Diff<ManagerInfoProps, UseManagerInfoHook>> = withState(
  StyledTabledManagerInfo,
  useManagerInfo
);
const OutstandingBalance: StatelessFunctionalComponent<*> = withState(StyledOutstandingBalance, useOutstandingBalance);

const AdditionalComponent: StatelessFunctionalComponent<*> = withStyles({
  additionalComponent: {
    flexGrow: 1,
  },
})(AppNavSidebarAdditionalComponent);

const AdditionalComponentOpen: StatelessFunctionalComponent<*> = withStyles((theme) => ({
  additionalComponent: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(1),
  },
  additionalComponentClose: {
    display: "none",
  },
}))(AppNavSidebarAdditionalComponent);

const Banner: * = withState(HelpUkraineBanner, (): * => {
  const [open, setOpen]: UseStorageHook<boolean> = useLocalStorage<boolean>("ukrBannerModal", true);
  return {
    open,
    onClose: (): * => setOpen(false),
  };
});

const NavBar: StatelessFunctionalComponent<{}> = () => {
  const theme: * = useTheme();
  const isExpand: boolean = useMediaQuery(theme.breakpoints.up("md"));

  return (
    <StyledAppNavSidebarComponent
      permanentIconsVisible
      isOpen={isExpand}
    >
      <AppNavSidebarAdditionalComponent>
        <User />
      </AppNavSidebarAdditionalComponent>
      <AdditionalComponent>
        <Box data-testid="menu-sidebar">
          <StyledMenu links={links} />
        </Box>
      </AdditionalComponent>
      <AdditionalComponentOpen>
        <Banner />
      </AdditionalComponentOpen>

      <AppNavSidebarAdditionalComponent>
        <Box pl={{ md: 2 }}>
          <OutstandingBalance renderIcon={<OutstandingBalanceIcon name="navbar" />} />
          <RenderMediaQueryComponent mediaKey="sm" method="not">
            <Manager title="Personal manager" />
          </RenderMediaQueryComponent>
          <RenderMediaQueryComponent mediaKey="sm" method="only">
            <TabletManager title="Personal manager" />
          </RenderMediaQueryComponent>
        </Box>
      </AppNavSidebarAdditionalComponent>
    </StyledAppNavSidebarComponent>
  );
};

export default NavBar;

/* eslint-disable import/max-dependencies */
// @flow
import environment from "environment";
import React, { type StatelessFunctionalComponent, useEffect, useState } from "react";
import { Typography, Button } from "@mui/material";
import { Helmet } from "react-helmet";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { catchError } from "@fas/cpa-state-manager/redux/actions/errors";
import type { AuthService, MessageResponse } from "@fas/cpa-state-manager/services/auth";
import { authService } from "@fas/cpa-state-manager/services/auth";
import AuthFormGrid from "../AuthFormGrid";
import type { UseState } from "../EditReportButton/types/EditReportButton.types";

const typographySx: * = { mb: 4, textAlign: "center", minWidth: { sm: "max-content" } };
const btnSx: * = { mb: 1 };

type Props = {
};

const Unsubscribe: StatelessFunctionalComponent<Props> = () => {
  const dispatch: * = useDispatch();
  const { token }: * = useParams();
  const [message, setMessage]: UseState<string> = useState("");

  const { unsubscribe }: AuthService = authService(environment);

  useEffect(() => {
    unsubscribe({ token })
      .then((data: MessageResponse) => {
        setMessage(data.message);
      })
      .catch((r: *) => {
        if (!r.response) {
          dispatch(catchError(r));
        }
        const msg: string = (r && r.response && r.response.data && r.response.data.message) || "Error";
        setMessage(msg);
      });
  }, [token]);
  return (
    <>
      <Helmet>
        <title>Unsubscribe</title>
      </Helmet>
      <AuthFormGrid>
        <Typography variant="gradientH1" sx={typographySx}>Unsubscribe</Typography>
        <Typography sx={{ textAlign: "center", pb: 2 }}>
          {message}
        </Typography>
        <Button
          sx={btnSx}
          data-testid="unsubscribe"
          fullWidth
          variant="gradient"
          type="submit"
          component="a"
          href="/"
        >
          Go to dashboard
        </Button>
      </AuthFormGrid>
    </>
  );
};

export default Unsubscribe;

// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import { Box } from "@mui/material";
import ScrollableCardContent from "../ScrollableCardContent";
import HowToCreate from "../HowToCreatePostback";

type Props = {
  children: Node,
};

const PostbackLayout: StatelessFunctionalComponent<Props> = ({ children }) => (
  <ScrollableCardContent>
    <Box display="flex" flexDirection="column" justifyContent="flex-start" sx={{ minHeight: "100%" }}>
      <Box>
        {children}
      </Box>
      <Box>
        <HowToCreate />
      </Box>
    </Box>
  </ScrollableCardContent>
);

export default PostbackLayout;

// @flow
import type { Column } from "@fas/cpa-cabinet-ui/lib/Table/Table.types";
import { useSelector } from "react-redux";
import { getTableFields } from "@fas/cpa-state-manager/services/selectors/table";
import { useTable, type UseTableHook } from "@fas/cpa-state-manager/redux/hooks";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";

export type UseReportTableHookArgs = {
  tableKey: string,
  columnsMap: { [string]: Column<*> },
};

export type UseReportTableHook = {
  isLoading: boolean,
  columns: Column<*>[],
  ...$Exact<UseTableHook<*>>,
};

export const useReportTable: (UseReportTableHookArgs) => UseReportTableHook = ({ tableKey, columnsMap }) => {
  const baseProps: UseTableHook<*> = useTable(tableKey);
  const fields: string[] = useSelector((state: *): string[] => getTableFields(state, tableKey));
  const isLoadingExport: boolean = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, `${tableKey}-export`));

  const columns: Column<*>[] = Object
    .keys(columnsMap)
    .filter((key: string): boolean => fields.includes(key))
    .reduce((acc: Column<*>[], key: string): Column<*>[] => [...acc, columnsMap[key]], []);

  const isLoadingTable: boolean = baseProps.isLoading || isLoadingExport;
  return {
    ...baseProps,
    columns,
    isLoading: isLoadingTable,
    data: baseProps.isLoading ? [] : baseProps.data,
  };
};

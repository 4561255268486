// @flow
import { type DefaultTheme } from "../themes";

const verticalDivider: (DefaultTheme) => * = (theme) => ({
  root: {
    margin: `${theme.spacing(2)} ${theme.spacing(2)}`,
    background: theme.palette.transparent.light,
  },
});

export default verticalDivider;

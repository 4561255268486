// @flow
import React, { type Node } from "react";

const LogoMob: () => Node = () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.22954 6.84548V10.4521L9.21315 9.6558L11.9766 7.31387V2.63L8.22954 6.84548Z" fill="url(#paint0_linear_5038_19541)" />
    <path d="M8.22954 15.3233L5.74709 16.6347V9.6558L2 13.8713V21.3654L5.74709 19.9603L8.22954 19.0235L11.9766 17.6184V10.8268L8.22954 13.2155V15.3233Z" fill="url(#paint1_linear_5038_19541)" />
    <path d="M15.7235 6.84548V10.4521L14.7868 9.6558L11.9764 7.31387V2.63L15.7235 6.84548Z" fill="url(#paint2_linear_5038_19541)" />
    <path d="M15.7235 15.3233L18.2528 16.6347V9.6558L21.9999 13.8713V21.3654L18.2528 19.9603L15.7235 19.0235L11.9764 17.6184V10.8268L15.7235 13.2155V15.3233Z" fill="url(#paint3_linear_5038_19541)" />
    <defs>
      <linearGradient id="paint0_linear_5038_19541" x1="2" y1="11.9977" x2="11.9766" y2="11.9977" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id="paint1_linear_5038_19541" x1="2" y1="11.9977" x2="11.9766" y2="11.9977" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id="paint2_linear_5038_19541" x1="11.9764" y1="11.9977" x2="21.9999" y2="11.9977" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id="paint3_linear_5038_19541" x1="11.9764" y1="11.9977" x2="21.9999" y2="11.9977" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
    </defs>
  </svg>

);

export default LogoMob;

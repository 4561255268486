/* eslint-disable import/max-dependencies */
// @flow
import React, { type Element, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHandleRequest,
  type UseHandleRequestHook,
} from "@fas/cpa-state-manager/redux/hooks";
import {
  getTableData,
} from "@fas/cpa-state-manager/services/selectors";
import { useEnvironment } from "@fas/cpa-state-manager/hooks";
import type { Column, Sorting } from "@fas/cpa-cabinet-ui/lib/Table/Table.types";
import { Box } from "@mui/material";
import type { Environment } from "@fas/cpa-state-manager/services/auth";
import { BEST_OFFERS_LIST } from "@fas/cpa-state-manager/redux/constants";
import { changeTableSorting, setTableData } from "@fas/cpa-state-manager/redux/actions";
import { setLoading } from "@fas/cpa-state-manager/redux/actions/loading";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { type Offer, getBestOffers } from "../../services/deprecatedApi";
import { formatCurrency } from "../../utils";
import { BEST_OFFERS_INFO } from "../../constants";

export type UseBestOffersHook = {
  data: Offer[],
  columns: Column<Offer>[],
  isLoading: boolean,
  onChangeSorting: (Sorting) => mixed,
};

// @TODO move this hook to state manager
export const useBestOffers: () => UseBestOffersHook = () => {
  const columns: Column<Offer>[] = [
    {
      field: "country",
      label: "Country",
      render: ({ country }: Offer): Element<*> => (
        <Box
          display="flex"
          flexDirection="row"
          alignItems="center"
          justifyContent="center"
        >
          <img src={`/new/img/flags/${country}.png`} alt={country} style={{ maxHeight: "18px", maxWidth: "24px" }} />
          <Box sx={{ ml: 2, textTransform: "uppercase" }}>
            {country}
          </Box>
        </Box>
      ),
    },
    {
      field: "webMetric",
      label: "Web",
      render: ({ webMetric }: Offer): Element<*> | string => (
        <Box
          display="flex"
          direction="row"
          justifyContent="center"
        >
          {typeof webMetric !== "number" ? "-" : formatCurrency("USD", webMetric)}
        </Box>
      )
      ,
    },
    {
      field: "mobMetric",
      label: "Mob",
      render: ({ mobMetric }: Offer): Element<*> | string => (
        <Box
          display="flex"
          direction="row"
          justifyContent="center"
        >
          {typeof mobMetric !== "number" ? "-" : formatCurrency("USD", mobMetric)}
        </Box>
      ),
    },
  ];

  const dispatch: <A>(A) => A = useDispatch();
  const data: Offer[] = useSelector((state: *): Offer[] => getTableData(state, BEST_OFFERS_LIST));

  const environment: Environment = useEnvironment();
  const {
    isLoading,
    changeLoading,
    onFail,
    onFinally,
  }: UseHandleRequestHook = useHandleRequest(BEST_OFFERS_LIST);

  const onChangeSorting: (Sorting) => mixed = (newSorting) => {
    dispatch(changeTableSorting(BEST_OFFERS_LIST, newSorting));
  };

  useEffect(() => {
    changeLoading(true);

    getBestOffers({ environment })
      .then(({ title, data: list }) => {
        dispatch(setFormData(BEST_OFFERS_INFO, { title }));
        dispatch(setTableData(BEST_OFFERS_LIST, list));
        dispatch(setLoading(`${BEST_OFFERS_LIST}-fetched`, true));
      })
      .catch(onFail)
      .finally(onFinally);
  }, []);

  return {
    data,
    columns,
    isLoading,
    onChangeSorting,
  };
};

export function useBestOffersInfo() {
  const { title } = useSelector((state) => getFormData(state, BEST_OFFERS_INFO));
  const {
    isLoading,
  }: UseHandleRequestHook = useHandleRequest(BEST_OFFERS_LIST);

  return {
    title,
    isLoading,
  };
}

// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Logout: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16 12L11 8V11H2V13H11V16L16 12ZM9 3C7.897 3 7 3.897 7 5V9H9V5H18V19H9V15H7V19C7 20.103 7.897 21 9 21H18C19.103 21 20 20.103 20 19V5C20 3.897 19.103 3 18 3H9Z" fill="white" />
  </SvgIcon>
);

export default Logout;

// @flow

import type { Node } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import { Box, IconButton, Typography } from "@mui/material";
import { setLoading } from "@fas/cpa-state-manager/redux/actions/loading";
import { Close } from "@mui/icons-material";
import React from "react";
import { INFO_MAIN_POPUP } from "../../constants";

export default function InfoMainPopup(): Node {
  const dispatch: <A>(A) => A = useDispatch();
  const isOpen: boolean = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, INFO_MAIN_POPUP));
  return isOpen && (
    <Box
      display="flex"
      alignItems="flex-start"
      justifyContent="space-between"
      sx={(theme: *): * => ({
        background: theme.palette.background.hints,
        borderRadius: 3.75,
        p: 2,
        mb: 1,
      })}
    >
      <Typography sx={{ typography: { lg: "bodyBold", xs: "subtext" } }}>
        Conversions are subjected to automatic fraud check conducted by
        our system within 10 minutes after receiving them.
        Upon check completion, all conversions in &ldquo;Approved&ldquo; status will be sent to
        you via S2S Postback (in case you installed it). Please note that the advertiser reserves the right to conduct
        an additional fraud check within the current payment period.
      </Typography>
      <IconButton size="small" onClick={(): * => dispatch(setLoading(INFO_MAIN_POPUP, false))}>
        <Close />
      </IconButton>
    </Box>
  );
}

// @flow

import { createTheme } from "@mui/material";

type BaseTheme = {
  breakpoints: {
    down: *,
    up: *,
  },
  zIndex: *,
  palette: {
    divider: string,
    error: *,
    common: {
      white: string,
      black: string,
    },
  },
  breakpoints: *,
  typography: *,
};

type OverrideTheme = {|
  spacing: (number) => number,
  theme: string,
  props: *,
  typography: *,
  shape: *,
  buttons: *,
  palette: {|
    mode: *,
    background: *,
    text: *,
    icons: *,
    gradient: {
      start: string,
      stop: string,
    },
    error: *,
    transparent: {
      default: string,
      text: string,
      disabled: string,
      light: string,
      dark: string,
      main: string,
      full: string,
      contrast: string,
    },
  |},
  components: *,
  breakpoints: { values: { [string]: number }, [string]: (*, *) => * },
|};

export type DefaultTheme = {
  ...BaseTheme,
  ...OverrideTheme,
  palette: {
    ...$PropertyType<BaseTheme, "palette">,
    ...$PropertyType<OverrideTheme, "palette">,
  },
};

const start: string = "#FF9000";
const stop: string = "#FFD33A";
const breakpoints: * = {
  values: {
    xs: 0,
    sm: 600,
    md: 900,
    lg: 1444,
    xl: 1536,
  },
};

const gradientText: { [string]: string } = {
  background: `linear-gradient(90deg, ${start} 0%, ${stop} 100%)`,
  WebkitBackgroundClip: "text",
  WebkitTextFillColor: "transparent",
};
const theme: * = createTheme({
  breakpoints,
});

const themeDefaultObject: OverrideTheme = {
  theme: "default",
  props: {},
  spacing: (f: number): number => f * 10,
  breakpoints,
  typography: {
    fontFamily: "Montserrat",
    fontStyle: "normal",
    gradientH1: {
      ...gradientText,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(50),
      lineHeight: 1.4,
      [theme.breakpoints.down("sm")]: { // h2
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(40),
        lineHeight: 1.12,
      },
    },
    gradientH4: {
      ...gradientText,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 1.5,
      [theme.breakpoints.down("lg")]: { // h5
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(18),
        lineHeight: 1.67,
      },
    },
    gradientH5: {
      ...gradientText,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.67,
      [theme.breakpoints.down("md")]: { // body bold
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 1.5,
      },
    },
    gradientBody: {
      ...gradientText,
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
    gradientBodyBold: {
      ...gradientText,
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
    gradientSubtextBold: {
      ...gradientText,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.5,
    },
    h1: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(50),
      lineHeight: 1.4,
    },
    h4: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(28),
      lineHeight: 1.5,
    },
    h5: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.687,
    },
    responsiveH5: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(18),
      lineHeight: 1.687,
      [theme.breakpoints.down("sm")]: { // body bold
        fontWeight: 500,
        fontSize: theme.typography.pxToRem(16),
        lineHeight: 1.5,
      },
    },
    button: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.625,
    },
    gradientButton: {
      ...gradientText,
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.625,
    },
    buttonSmall: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(14),
      lineHeight: 1.428,
    },
    responsiveButton: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.625,
      [theme.breakpoints.down("lg")]: { // buttonSmall
        fontWeight: 700,
        fontSize: theme.typography.pxToRem(14),
        lineHeight: 1.428,
      },
    },
    bodyBold: {
      fontWeight: 500,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
    body: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(16),
      lineHeight: 1.5,
    },
    subtext: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.5,
    },
    subtextBold: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(12),
      lineHeight: 1.5,
    },
    tableMob: {
      fontWeight: 400,
      fontSize: theme.typography.pxToRem(9),
      lineHeight: 2,
    },
    tableMobBold: {
      fontWeight: 700,
      fontSize: theme.typography.pxToRem(9),
      lineHeight: 2,
    },
  },
  shape: {
    borderRadius: 4,
    cardBorderRadius: 15,
  },
  buttons: {
    main: {},
    secondary: {},
  },
  palette: {
    mode: "dark",
    background: {
      default: "#021A2D",
      light: "rgb(22,44,61)",
      list: "rgb(22,44,61)",
      backdrop: "rgba(0, 26, 45, 0.9)",
      hints: "linear-gradient(90deg, #366DC0 0%, #4589EF 100%);",
    },
    text: {
      main: "#FFF",
    },
    icons: {},
    gradient: {
      start,
      stop,
    },
    error: {
      main: "rgba(241, 25, 37, 1)",
    },
    transparent: {
      default: "rgba(255, 255, 255, 0.8)",
      text: "rgba(255, 255, 255, 0.5)",
      disabled: "rgba(255, 255, 255, 0.38)",
      light: "rgba(255, 255, 255, 0.08)",
      dark: "rgba(255, 255, 255, 0.6)",
      main: "rgba(255, 255, 255, 0.1)",
      full: "rgba(255, 255, 255, 0)",
      contrast: "rgba(0, 0, 0, 0.65)",
    },
  },
  components: {
    MuiAlert: {
      styleOverrides: {
        root: {
          wordBreak: "break-word",
          whiteSpace: "break-spaces",
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          maxHeight: "40vh",
          whiteSpace: "break-spaces",
          overflowWrap: "anywhere",
          overflowY: "auto",
          display: "flex",
          alignItems: "flex-start",
          padding: 20,
          background: "linear-gradient(90deg, #366DC0 0%, #4589EF 100%);",
          borderRadius: "15px",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "24px",
          color: "rgba(255, 255, 255, 0.85)",
          "& button": {
            marginLeft: 10,
            position: "sticky",
            top: 0,
          },
        },
      },
    },
    MuiUseMediaQuery: {
      defaultProps: {
        noSsr: true,
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: "none",
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        shrink: true,
      },
      styleOverrides: {
        root: {
          transform: "translate(0, -4px) scale(1)",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          transform: "translate(0, -4px) scale(1)",
          fontWeight: 500,
          fontSize: theme.typography.pxToRem(16),
          lineHeight: 1.5,
          "&.Mui-disabled": {
          },
          "&.Mui-focused": {
          },
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          "& label": {
            position: "unset",
          },
          "& .MuiInput-root": {
            marginTop: "0px",
          },
          "& div.Mui-error": {
            borderLeft: "2px solid rgba(241, 25, 37, 1)",
          },
        },
      },
    },
    MuiInput: {
      defaultProps: {
        disableUnderline: true,
      },
      styleOverrides: {
        root: {
          borderRadius: "4px",
          background: "rgba(255, 255, 255, 0.08)",
        },
        input: {
          padding: "13px 10px",
          lineHeight: 1.5,
          fontSize: theme.typography.pxToRem(16),
          height: theme.typography.pxToRem(24),
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "standard",
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "gradient" },
          style: {
            background: `linear-gradient(to right, ${start}, ${stop})`,
            fontWeight: 700,
            paddingTop: "12px",
            paddingBottom: "12px",
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 1.625,
            minWidth: "50px",
            "&:hover": {
              background: `linear-gradient(to left, ${start}, ${stop})`,
            },
          },
        },
        {
          props: { variant: "gradientText" },
          style: {
            ...gradientText,
            textTransform: "none",
            fontWeight: 700,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 1.625,
            paddingBottom: 0,
            paddingTop: 0,
            "&:hover": {
              ...gradientText,
            },
          },
        },
        {
          props: { variant: "transparent" },
          style: {
            background: "rgba(255, 255, 255, 0.08)",
            fontWeight: 700,
            paddingTop: "12px",
            paddingBottom: "12px",
            minWidth: "50px",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.1)",
            },
          },
        },
        {
          props: { variant: "gradientContrast" },
          style: {
            color: "black",
            backgroundColor: "white",
            fontWeight: 700,
            paddingTop: "12px",
            paddingBottom: "12px",
            minWidth: "50px",
            "&:hover": {
              background: "rgba(255, 255, 255, 0.5)",
            },
            "&.Mui-disabled": {
              color: "rgba(0, 0, 0, 0.3)",
            },
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          background: "#021A2D",
          borderRadius: 0,
          "& .MuiMenu-list, .MuiAutocomplete-listbox": {
            background: "rgba(255, 255, 255, 0.08)",
            color: "#FFF",
            "& .Mui-selected, li[aria-checked=true]": {
              background: "rgba(255, 255, 255, 0.08)",
            },
            "& .Mui-selected:hover, .Mui-focused:hover": {
              background: "rgba(255, 255, 255, 0.08) !important",
            },
            "& .MuiMenuItem-root:hover, .MuiAutocomplete-option:hover": {
              cursor: "pointer",
              background: "rgba(255, 255, 255, 0)",
            },
          },
        },
      },
    },
    MuiTabsSwitcher: {
      styleOverrides: {
        root: {
          margin: 0,
        },
      },
    },
    MuiTabs: {
      styleOverrides: {
        indicator: {
          background: "rgba(255, 255, 255, 0.8)",
        },
        root: {
          minHeight: "auto",
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          color: "rgba(255, 255, 255, 0.6)",
          textTransform: "none",
          fontWeight: 700,
          fontSize: theme.typography.pxToRem(18),
          lineHeight: 1.667,
          padding: "0 0 20px 0",
          marginRight: "30px",
          minHeight: "auto",
          minWidth: "auto",

          [theme.breakpoints.down("lg")]: {
            fontSize: theme.typography.pxToRem(12),
            lineHeight: 1.5,
            marginRight: "20px",
            padding: "0 0 10px 0",
          },
          [theme.breakpoints.down("md")]: {
            fontSize: theme.typography.pxToRem(16),
            fontWeight: 500,
          },

          "&.Mui-selected": {
            color: "#FFF",
          },
          ":hover": {
            color: "#FFF",
          },
        },
      },
    },
    MuiGrid: {
      styleOverrides: {
        root: {
          "& label": {
            color: "#FFF",
          },
          "& label.Mui-focused": {
            color: "#FFF",
            fontWeight: 700,
          },
        },
      },
    },
    MuiPickerStaticWrapper: {
      styleOverrides: {
        root: {
          "& button": {
            "&.Mui-selected": {
              background: `linear-gradient(to right, ${start}, ${stop})`,
              "&:hover": {
                background: `linear-gradient(to left, ${start}, ${stop})`,
              },
            },
          },
          "& .MuiPickersDay-root": {
            fontWeight: "400 !important",
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 1.5,
          },
          "& .PrivatePickersYear-yearButton": {
            fontWeight: 400,
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 1.5,
          },
          "& .MuiPickersCalendarHeader-label": {
            fontSize: theme.typography.pxToRem(16),
            lineHeight: 1.667,
            fontWeight: 700,
          },
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          height: "auto",
          margin: "0px 1px",
          padding: "12px 20.5px",
          fontSize: theme.typography.pxToRem(16),
          lineHeight: 1.625,
          fontWeight: 700,

          [theme.breakpoints.down("lg")]: {
            padding: "10px 16px",
            fontWeight: 700,
            fontSize: theme.typography.pxToRem(14),
            lineHeight: 1.428,
          },
        },
      },
    },
  },
};

export default themeDefaultObject;

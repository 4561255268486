/* eslint-disable import/max-dependencies */
// @flow
import React, { type Element, type Node, type StatelessFunctionalComponent } from "react";
import {
  Table, TableFilters, type TableFiltersProps, withHookPropsState,
} from "@fas/cpa-cabinet-ui";
import { usePagination, type UsePaginationHook } from "@fas/cpa-state-manager/redux/hooks";
import { withStyles } from "@mui/styles";
import type { Column } from "@fas/cpa-cabinet-ui/lib/Table/Table.types";
import FilterButton from "@fas/cpa-cabinet-ui/lib/Table/FilterButton";
import { useSelector } from "react-redux";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import { Box } from "@mui/material";
import { getTablePendingFields } from "@fas/cpa-state-manager/services/selectors/table";
import Pagination, { type PaginationProps as TablePaginationProps } from "../Pagination";
import {
  useReportTable, useTableFilters, type UseTableFiltersArgs, type UseTableFiltersHook,
} from "../../hooks";
import { reportTableStyles, tableFiltersStyles } from "../../styles";
import EditReportButton from "../EditReportButton";
import withNoData from "../WithNoData";
import NoData from "../NoData";
import ReportSkeletonLoading from "./ReportSkeletonLoading";
import { useTableNoData } from "../../hooks/useTableNoData";
import { useApplyFilters } from "../../hooks/useTableFilters/useTableFilters";
import ApplyButton from "./ApplyButton";
import filterComponentMap from "./filterComponentMap";
import ReportEmptyIcon from "../../icons/ReportEmpty";

type Props = {|
  tableKey: string,
  configureComponent: Element<*>,
  headerTotal?: Element<*>,
  bodyTotal?: Element<*>,
  footerTotal?: Element<*>,
  columnsMap: { [string]: Column<*> },
|};

type FiltersProps = $Diff<TableFiltersProps, UseTableFiltersHook> & { hookProps: UseTableFiltersArgs };
type PaginationProps = $Diff<TablePaginationProps, UsePaginationHook> & { hookProps: string };

const ReportWithNoData: StatelessFunctionalComponent<*> = withHookPropsState(withNoData(
  ({ isFetched }: { isFetched?: boolean }): Node => (
    <Box sx={{
      height: 1, display: "flex", justifyContent: "center", alignItems: "center",
    }}
    >
      <NoData
        display={isFetched ? "flex" : "none"}
        Icon={<Box><ReportEmptyIcon /></Box>}
        headerText="No data available for the selected filters"
        text="Select the right filters to see your results, or try new cool offers to give your stats a boost!"
      />
    </Box>
  ),
  (): Node => null
), useTableNoData);

const Filters: StatelessFunctionalComponent<FiltersProps> = withHookPropsState(TableFilters, useTableFilters);
const ReportTable: StatelessFunctionalComponent<*> = withHookPropsState(Table, useReportTable);
const ReportPagination: StatelessFunctionalComponent<PaginationProps> = withHookPropsState(
  Pagination,
  usePagination
);

const StyledFilters: StatelessFunctionalComponent<FiltersProps> = withStyles(tableFiltersStyles)(Filters);
const StyledTable: StatelessFunctionalComponent<*> = withStyles(reportTableStyles)(ReportTable);
const StyledFilterButton: StatelessFunctionalComponent<*> = withHookPropsState(withStyles((theme: *): * => ({
  iconButton: {
    "&.MuiIconButton-colorPrimary": {
      color: "rgba(113, 216, 160, 1)",
    },
    "&.MuiIconButton-colorPrimary.Mui-disabled": {
      color: "rgba(113, 216, 160, 0.3)",
    },
  },
  popoverPaper: {
    marginTop: theme.spacing(1),
    width: "360px",
    minWidth: "fit-content",
    borderRadius: theme.shape.cardBorderRadius,
    boxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.2)",
  },
}))(FilterButton), ({ tableKey }) => ({
  onApply: useApplyFilters({ tableKey }),
}));

const ReportLoading: * = withHookPropsState(ReportSkeletonLoading, ({ tableKey }: { tableKey: string }): * => {
  const isLoading: boolean = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, tableKey));
  return {
    isLoading,
    fieldsLength: useSelector((state: *): * => getTablePendingFields(state, tableKey)).length,
  };
});

const Apply = withHookPropsState(ApplyButton, ({ tableKey }) => ({
  onApply: useApplyFilters({ tableKey }),
}));

const Report: StatelessFunctionalComponent<Props> = ({
  tableKey,
  configureComponent,
  columnsMap,
  headerTotal,
  bodyTotal,
  footerTotal,
}) => {
  // eslint-disable-next-line react/no-unstable-nested-components
  const FilterButtonComponent = (props) => <StyledFilterButton {...props} hookProps={{ tableKey }} />;
  return (
    <>
      {headerTotal}
      <StyledFilters hookProps={{ tableKey }} filterComponentMap={filterComponentMap}>
        <Apply hookProps={{ tableKey }} />
        <EditReportButton>
          {configureComponent}
        </EditReportButton>
      </StyledFilters>
      <StyledTable
        hookProps={{ tableKey, columnsMap }}
        FilterButton={FilterButtonComponent}
      >
        <ReportLoading hookProps={{ tableKey }} />
        {bodyTotal}
      </StyledTable>
      {footerTotal}
      <ReportWithNoData hookProps={{ tableKey }} />
      <ReportPagination hookProps={tableKey} />
    </>
  );
};

export default Report;

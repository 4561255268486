// @flow
import { type DefaultTheme } from "../themes";

const tabletManagerInfo: (DefaultTheme) => * = (theme) => ({
  popover: {
    "& .MuiPopover-paper": {
      borderRadius: theme.shape.cardBorderRadius,
    },
  },
  name: {
    color: theme.palette.text.main,
    ...theme.typography.subtextBold,
    whiteSpace: "break-spaces",
    overflowWrap: "anywhere",
    textAlign: "center",
  },
  title: {
    color: theme.palette.transparent.default,
    ...theme.typography.subtext,
  },
  avatar: {
    width: "40px",
    height: "40px",
  },
  avatarLoading: {
    width: "40px",
    height: "40px",
  },
});

export default tabletManagerInfo;

// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node, type StatelessFunctionalComponent } from "react";
import { Helmet } from "react-helmet";
import { Box, Grid, Typography } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import { SetupCard, SmartlinkGenerator, withState } from "@fas/cpa-cabinet-ui";
import type { CardSetupProps } from "@fas/cpa-cabinet-ui";
import DividerBlock from "../Dashboard/DividerBlock";
import { useSmartlinkGenerator } from "../../hooks/useSmartlinkGenerator";
import { useSmartlinkList } from "../../hooks/useSmartlinkList";
import smartlinkGenerator from "../../styles/smartlinkGenerator";
import SmartlinkList from "../SmartlinkList";
import { setupCardStyles } from "../../styles";

const SmartlinkListWithState: StatelessFunctionalComponent<{}> = withState(SmartlinkList, useSmartlinkList);

const StyledSmartlinkGenerator: StatelessFunctionalComponent<{}> = withState(
  withStyles(smartlinkGenerator)(SmartlinkGenerator),
  useSmartlinkGenerator
);

const useStyles: * = makeStyles((theme: *): * => ({
  smartlinkGeneratorWrapper: {
    backgroundColor: theme.palette.transparent.light,
    height: "fit-content",
    padding: theme.spacing(2),
    borderRadius: theme.shape.borderRadius,
    marginBottom: theme.spacing(2),
    minWidth: "200px",
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      marginLeft: "-10px",
      marginRight: "-10px",
      borderRadius: 0,
    },
  },
}));
const StyledSetupCard: StatelessFunctionalComponent<CardSetupProps> = withStyles(setupCardStyles)(SetupCard);

function Smartlinks(): Node {
  const classes: * = useStyles();
  return (
    <StyledSetupCard title="">
      <Helmet>
        <title>My links</title>
      </Helmet>
      <Grid
        container
        sx={{
          height: { xs: "auto", lg: "100%" },
          flexDirection: { xs: "column-reverse", lg: "row" },
          flexWrap: "nowrap",
        }}
      >
        <Grid
          item
          xs={12}
          lg
          sx={{
            flexDirection: "column",
            display: { xs: "block", lg: "flex" },
            height: { lg: "100%" },
            width: "auto",
          }}
          width="fit-content"
          data-testid="smartlink-list-wrapper"
        >
          <Box sx={{ display: { xs: "none", lg: "block" } }}>
            <Typography variant="gradientH4">My Links</Typography>
            <Typography
              variant="h5"
              mt={1}
              mb={2}
            >
              Please contact your manager for more information about your smartlink or in case of some special request.
            </Typography>
          </Box>
          <Box sx={{ height: { xs: "auto", lg: "100%" }, overflowY: { xs: "hidden", lg: "auto" }, overflowX: "hidden" }}>
            <SmartlinkListWithState />
          </Box>
        </Grid>
        <DividerBlock
          sx={{
            display: { xs: "none", lg: "block" },
          }}
        />
        <Grid
          item
          xs={12}
          lg="auto"
          xl="auto"
          sx={{
            overflowY: { xs: "unset", lg: "auto" },
            maxWidth: { xs: "100%", lg: "410px" },
            marginBottom: { xs: "20px", lg: 0 },
          }}
        >
          <Box sx={{ display: { xs: "block", lg: "none" } }}>
            <Typography variant="gradientH4">My Links</Typography>
            <Typography
              sx={{
                typography: {
                  xs: "bodyBold",
                  sm: "body",
                  md: "body",
                },
                mt: 1,
                mb: 2,
              }}
            >
              Please contact your manager for more information about your smartlink or in case of some special request.
            </Typography>
          </Box>
          <Box className={classes.smartlinkGeneratorWrapper} data-testid="smartlink-generator-wrapper">
            <Typography variant="gradientH4">Smartlink Generator</Typography>
            <StyledSmartlinkGenerator />
          </Box>
        </Grid>
      </Grid>
    </StyledSetupCard>
  );
}

export default Smartlinks;

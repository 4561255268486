// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent, type Node } from "react";
import { Helmet } from "react-helmet";
import { StyledEngineProvider, ThemeProvider } from "@mui/material/styles";
import {
  Route,
  Routes,
  Navigate,
  BrowserRouter,
} from "react-router-dom";
import { ConfirmProvider } from "material-ui-confirm";
import { CabinetProvider } from "@fas/cpa-state-manager/services/CabinetProvider";
import environment from "environment";
import {
  AuthRoute,
  withState,
  type AuthRouteProps,
} from "@fas/cpa-cabinet-ui";
import { useAuth, type UseAuthHook } from "@fas/cpa-state-manager/hooks";
import { CssBaseline, Typography } from "@mui/material";
import theme from "../../theme";
import AuthLayout from "../../pages/Auth";
import {
  AuthLogin, AuthAutologin, AuthChangePassword, AuthRestorePassword,
} from "../../pages/Auth/Auth";
import CabinetLayout from "../../pages/Cabinet";
import { CabinetComponents } from "../../pages/Cabinet/Cabinet";
import Unsubscribe from "../Unsubscribe";
import Service from "../../pages/Service/Service";
import PageNotFound from "../PageNotFound";
import { BackdropComponent } from "../NatureOfTrafficModal/BackdropComponent";

const PrivateRoute: StatelessFunctionalComponent<$Diff<AuthRouteProps, UseAuthHook>> = withState(AuthRoute, useAuth);

const Gradient: () => Node = (): Node => (
  <svg width={0} height={0} style={{ position: "absolute" }}>
    <linearGradient id="selected">
      <stop offset={0} stopColor="#FF9000" />
      <stop offset={1} stopColor="#FFD33A" />
    </linearGradient>
    <linearGradient id="selected-disabled">
      <stop offset={0} stopColor="rgb(129,85,22)" />
      <stop offset={1} stopColor="rgb(129,118,50)" />
    </linearGradient>
  </svg>
);

const confirmProviderDefaultOptions: * = {
  confirmationText: <Typography variant="gradientButton">Ok</Typography>,
  cancellationText: <Typography variant="gradientButton">Cancel</Typography>,
  confirmationButtonProps: { color: "transparent" },
  cancellationButtonProps: { color: "transparent" },
  dialogProps: {
    BackdropComponent,
    PaperProps: {
      sx: ({ borderRadius: 3.75, boxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.2)" }),
    },
  },
};

const HomeComponent = (): Node => <Navigate to={environment.links.home} />;
const AnyPathComponent = (): Node => <Navigate to={environment.links.notFound} />;

export const App: StatelessFunctionalComponent<{}> = () => (
  <CabinetProvider environment={environment}>
    <Helmet>
      <title>Adsempire Smartlink</title>
    </Helmet>
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Gradient />
        <ConfirmProvider defaultOptions={confirmProviderDefaultOptions}>
          <BrowserRouter>
            <Routes>
              <Route index path="/" element={<PrivateRoute component={HomeComponent} />} />
              <Route path={environment.links.notFound} element={<PrivateRoute component={PageNotFound} />} />

              <Route element={<AuthLayout />}>
                <Route path="/login" element={<AuthLogin />} />
                <Route path="/autologin" element={<AuthAutologin />} />
                <Route path="/restorePassword" element={<AuthRestorePassword />} />
                <Route path="/changePassword" element={<AuthChangePassword />} />
              </Route>

              <Route element={<PrivateRoute component={CabinetLayout} />}>
                <Route path="/profile/:page" element={<CabinetComponents.Profile />} />
                <Route path="/dashboard" element={<CabinetComponents.Dashboard />} />
                <Route path="/postbacks/:type" element={<CabinetComponents.Postbacks />} />
                <Route path="/reports/:type" element={<CabinetComponents.Reports />} />
                <Route path="/logout" element={<CabinetComponents.Logout />} />
                {CabinetComponents.Links && (
                  <Route path="/links" element={<CabinetComponents.Links />} />
                )}
                {CabinetComponents.Faq && (
                  <Route path="/faq" element={<CabinetComponents.Faq />} />
                )}
                {CabinetComponents.PaymentHistory && (
                  <Route path="/paymentHistory" element={<CabinetComponents.PaymentHistory />} />
                )}
              </Route>

              <Route element={<Service />}>
                <Route path="/unsubscribe/:token" element={<Unsubscribe />} />
              </Route>
              <Route path="*" element={<PrivateRoute component={AnyPathComponent} />} />
            </Routes>
          </BrowserRouter>
        </ConfirmProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  </CabinetProvider>
);

export default App;

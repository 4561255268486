// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Phone: () => Node = () => (
  <SvgIcon width="24" height="32" viewBox="0 0 24 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 27C6.45 27 5.97933 26.8043 5.588 26.413C5.196 26.021 5 25.55 5 25V7C5 6.45 5.196 5.979 5.588 5.587C5.97933 5.19567 6.45 5 7 5H17C17.55 5 18.021 5.19567 18.413 5.587C18.8043 5.979 19 6.45 19 7V25C19 25.55 18.8043 26.021 18.413 26.413C18.021 26.8043 17.55 27 17 27H7ZM7 22V25H17V22H7ZM12 24.5C12.2833 24.5 12.521 24.404 12.713 24.212C12.9043 24.0207 13 23.7833 13 23.5C13 23.2167 12.9043 22.9793 12.713 22.788C12.521 22.596 12.2833 22.5 12 22.5C11.7167 22.5 11.4793 22.596 11.288 22.788C11.096 22.9793 11 23.2167 11 23.5C11 23.7833 11.096 24.0207 11.288 24.212C11.4793 24.404 11.7167 24.5 12 24.5ZM7 20H17V10H7V20ZM7 8H17V7H7V8Z" />
  </SvgIcon>
);

export default Phone;

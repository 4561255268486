// @flow
import React, {
  type StatelessFunctionalComponent, type Element, useRef, useState,
} from "react";
import useStateRef from "react-usestateref";
import {
  Backdrop,
  ClickAwayListener,
  FormControl,
  IconButton,
  Paper,
  Popper,
} from "@mui/material";
import type { Option } from "@fas/cpa-state-manager/redux/reducers";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";
import { ArrowDropDown, ArrowDropUp, Close } from "@mui/icons-material";
import { mergeClasses } from "@fas/cpa-cabinet-ui/lib/utils";
import { makeStyles } from "@mui/styles";
import { TextFieldForm } from "../TextFieldForm/TextFieldForm";
import SelectBody, { toOptionValue } from "./SelectBody";

export type Props = {
  value?: *,
  onChange?: (*) => *,
  defaultValue: string | string[],
  options: Option[],
  isLoading: boolean,
  disabled?: boolean,
  disableBackdrop?: boolean,
  disableCloseOnSelect?: boolean,
  isSplitListBoxColumn?: boolean,
  disableSearch?: boolean,
  disableClearable?: boolean,
  name: string,
  label?: string,
  modalLabel?: string,
  classes?: *,
  inputProps?: *,
  formControlSx?: *,
  StartIcon?: ?Element<*>,
  EndIcon?: ?Element<*>,
  getOptionDisabled?: (*) => boolean,
  maxSelection?: number,
};

const sx: * = {
  fontSize: "inherit",
  color: "inherit",
  "&&&": {
    fill: "currentColor",
  },
};

const useStyles: * = makeStyles((theme: *): * => ({
  header: {
    display: "none",
  },
  paperBody: {
    backgroundColor: theme.palette.background.list,
    padding: 5,
  },
  inputField: {
    "&:hover": {
      "& $adornmentButton": {
        visibility: "visible",
      },
    },
  },
  adornmentButton: {
    visibility: "hidden",
  },
  icon: {
    marginRight: "-4px",
    padding: 0,
  },
}));

const SelectPopup: StatelessFunctionalComponent<Props> = ({
  defaultValue,
  disableCloseOnSelect,
  disableSearch,
  disableClearable,
  isSplitListBoxColumn,
  value,
  onChange,
  options = [],
  isLoading,
  name,
  label,
  disabled,
  classes: propClasses = {},
  disableBackdrop = false,
  formControlSx,
  getOptionDisabled,
  maxSelection,
  ...props
}) => {
  const classes: { [string]: * } = mergeClasses(useStyles(), propClasses);
  const [anchorEl, setAnchorEl]: * = useState<null | HTMLElement>(null);
  const [pendingValue, setPendingValue, pendingValueRef]: * = useStateRef([]);
  const [error, setError] = useState<string>("");
  const ref: * = useRef(null);

  const handleOpen: * = () => {
    if (anchorEl || disabled) {
      return;
    }
    setPendingValue(value);
    setAnchorEl(ref.current && ref.current.firstChild);
  };

  const handleClose: * = (e?: SyntheticMouseEvent<Node>) => {
    if (value !== pendingValueRef.current && onChange) {
      onChange(pendingValueRef.current);
    }
    setAnchorEl(null);
    setError("");
    e && e.preventDefault();
  };

  const inputValue: Option[] | Option = toOptionValue(value || "", options);
  const hasValue: boolean = Array.isArray(value) ? !!value.length : !!value;
  const textFieldValue: string = Array.isArray(inputValue) ? inputValue.map(({ title }: *): * => title).join(", ") : inputValue.title;
  const isIdValue: boolean = typeof value === "number";
  const isLoadingOptionsForIdValue: boolean = isIdValue && options.length === 0;

  return (
    <>
      <FormControl ref={ref} fullWidth>
        <TextFieldForm
          {...props}
          disabled={disabled}
          formControlSx={formControlSx}
          name={name}
          label={label || ""}
          isAuthenticated
          value={isLoadingOptionsForIdValue ? "" : textFieldValue}
          className={classes.inputField}
          inputProps={{
            ...props.inputProps,
            readOnly: true,
            onClick: handleOpen,
          }}
          error={error}
          EndIcon={(
            <>
              {hasValue && !disabled && !disableClearable ? (
                <IconButton
                  disabled={disabled}
                  data-testid="clear"
                  size="small"
                  onClick={(): * => onChange && onChange(defaultValue)}
                  className={classes.adornmentButton}
                >
                  <Close sx={sx} />
                </IconButton>
              ) : null}
              <IconButton
                disabled={disabled}
                data-testid="open"
                size="small"
                onClick={handleOpen}
                className={classes.icon}
              >
                {anchorEl ? <ArrowDropUp sx={sx} /> : <ArrowDropDown sx={sx} />}
              </IconButton>
              {props.EndIcon}
            </>
          )}
        />
        <Backdrop invisible sx={{ zIndex: "modal" }} open={!disableBackdrop && Boolean(anchorEl)} onClick={handleClose} />
      </FormControl>
      <Popper
        disabled={disabled}
        open={Boolean(anchorEl)}
        sx={{ zIndex: "modal", width: anchorEl && anchorEl.clientWidth }}
        anchorEl={anchorEl}
        placement="bottom-start"
      >
        <ClickAwayListener onClickAway={handleClose}>
          <Paper className={classes.paperBody}>
            <SelectBody
              label={label}
              value={pendingValue}
              onChange={(newValue) => {
                if (!maxSelection || (Array.isArray(newValue) && maxSelection && newValue.length <= maxSelection)) {
                  setPendingValue(newValue);
                  setError("");
                }
                if (maxSelection && newValue.length > maxSelection) {
                  setError(`You can select up to ${maxSelection} items.`);
                }
              }}
              onClose={handleClose}
              options={options}
              isLoading={isLoading}
              defaultValue={defaultValue}
              disableCloseOnSelect={disableCloseOnSelect}
              isSplitListBoxColumn={isSplitListBoxColumn}
              disableSearch={disableSearch}
              classes={classes}
              getOptionDisabled={getOptionDisabled}
            />
          </Paper>
        </ClickAwayListener>
      </Popper>
    </>
  );
};

const SelectPopupField: * = withFormField(SelectPopup);

export {
  SelectPopupField,
};

export default SelectPopup;

/* eslint-disable import/max-dependencies */
// @flow
import React, { type Node, type StatelessFunctionalComponent } from "react";
import { Helmet } from "react-helmet";
import {
  type CardSetupProps,
  SetupCard,
  type TabsSwitcherProps,
  withHookPropsState,
} from "@fas/cpa-cabinet-ui";
import TabsSwitcher from "@fas/cpa-cabinet-ui/lib/TabsSwitcher";
import { withStyles } from "@mui/styles";
import { Navigate } from "react-router-dom";
import environment from "environment";
import { Box } from "@mui/material";
import { setupCardStyles } from "../../styles";
import {
  MAIN_REPORT_TABLE,
  REPORTS_PAGE,
  TRANSACTION_REPORT_TABLE,
} from "../../constants";
import {
  useConfigureReport,
  type UseConfigureReportHookArgs,
  useTabs,
  type UseTabsArgs,
} from "../../hooks";
import ConfigureMainReport from "../ConfigureMainReport";
import ConfigureTransactionReport from "../ConfigureTransactionReport";
import Report from "../Report";
import { transactionReportColumnsMap } from "./transactionReportColumnsMap";
import { mainReportColumnsMap } from "./mainReportColumnsMap";
import AdditionalTabButton from "./AdditionalTabButton";
import { useExportReport } from "../../hooks/useExportReport";
import TotalMain from "./TotalMain";
import TotalTransaction from "./TotalTransaction";
import reportTableMainTotal from "../../styles/reportTableMainTotal";
import reportTableTotalTransaction from "../../styles/reportTableTotalTransaction";
import InfoMainPopup from "./InfoMainPopup";
import tabsSwitcher from "../../styles/tabsSwitcher";

type Props = {
  param: string,
};

type ConfigureMainReportProps = {
  hookProps: UseConfigureReportHookArgs,
  onClose?: () => mixed,
  onApply?: () => mixed,
  open?: boolean,
  onUpdatePosition?: () => mixed,
};
type ConfigureTransactionReportProps = {
  hookProps: UseConfigureReportHookArgs,
  onClose?: () => mixed,
  onApply?: () => mixed,
  open?: boolean,
  onUpdatePosition?: () => mixed,
};

const StyledSetupCard: StatelessFunctionalComponent<CardSetupProps> = withStyles(setupCardStyles)(SetupCard);
const StyledTotalMain: StatelessFunctionalComponent<*> = withStyles(reportTableMainTotal)(TotalMain);
const StyledTotalTransaction: StatelessFunctionalComponent<
  *> = withStyles(reportTableTotalTransaction)(TotalTransaction);
const StyledTabs: StatelessFunctionalComponent<
  $Diff<TabsSwitcherProps, { classes: * }>
  > = withStyles(tabsSwitcher)(TabsSwitcher);
const Tabs: StatelessFunctionalComponent<{
  hookProps: UseTabsArgs,
  allowScrollButtonsMobile?: boolean,
  scrollButtons?: string | boolean,
  variant?: string,
}> = withHookPropsState(StyledTabs, useTabs);
const ConfigureMainReportComponent: StatelessFunctionalComponent<
  ConfigureMainReportProps
> = withHookPropsState(ConfigureMainReport, useConfigureReport);
const ConfigureTransactionReportComponent: StatelessFunctionalComponent<
  ConfigureTransactionReportProps
> = withHookPropsState(ConfigureTransactionReport, useConfigureReport);

export const paramsToComponents: { [string]: StatelessFunctionalComponent<*> } = {
  main: (): Node => (
    <>
      <Helmet>
        <title>Main report</title>
      </Helmet>
      <Report
        headerTotal={<InfoMainPopup />}
        bodyTotal={<StyledTotalMain />}
        tableKey={MAIN_REPORT_TABLE}
        columnsMap={mainReportColumnsMap}
        configureComponent={<ConfigureMainReportComponent hookProps={{ tableKey: MAIN_REPORT_TABLE }} />}
      />
    </>
  ),
  transaction: (): Node => (
    <>
      <Helmet>
        <title>Transaction report</title>
      </Helmet>
      <Report
        footerTotal={<StyledTotalTransaction />}
        tableKey={TRANSACTION_REPORT_TABLE}
        columnsMap={transactionReportColumnsMap}
        configureComponent={<ConfigureTransactionReportComponent hookProps={{ tableKey: TRANSACTION_REPORT_TABLE }} />}
      />
    </>
  ),
  default: (): Node => <Navigate to={environment.links.notFound} />,
};

const ExportButton: StatelessFunctionalComponent<*> = withHookPropsState(AdditionalTabButton, useExportReport);

const Reports: StatelessFunctionalComponent<Props> = ({ param }) => (
  <StyledSetupCard title="Statistics">
    <Box display="flex">
      <Box flexGrow={1} minWidth={0}>
        <Tabs hookProps={{ key: REPORTS_PAGE }} />
      </Box>
      <ExportButton hookProps={param}>Export report</ExportButton>
    </Box>
    {paramsToComponents[param]()}
  </StyledSetupCard>
);

export default Reports;

// @flow
import environment from "environment";
import { getRequestService } from "@fas/cpa-state-manager/services/request";
import { type Response } from "@fas/ui-framework/lib/services/request";
import type { PaymentHistoryData } from "../components/PaymentHistory";

const requestService: $Call<typeof getRequestService> = getRequestService();
export type RequestBody = {
  filters: { [string]: * },
  sorting: { current: string, direction: mixed },
  fields: Array<string>,
  page: number,
  limit: number,
}

export type ExportRequestBody = {
  ...RequestBody,
  fields: Array<{ fieldName: string, name: string }>
}

type ListData = { data: Array<PaymentHistoryData>, count: number }

export const fetchPaymentHistoryList: (RequestBody) => Promise<ListData> = (
  params
) => requestService
  .post(environment.endpoints.post.payoutHistory, params)
  .then(({ data }: Response<ListData>): ListData => data);

export const exportToZip: (RequestBody) => Promise<Response<mixed>> = ({ filters }) => requestService
  .post(environment.endpoints.post.payoutHistoryExport, {
    filters,
  }, { responseType: "blob" });

export const exportToPdf: (id: number) => Promise<Response<mixed>> = (id) => requestService
  .post(environment.endpoints.post.payoutHistoryExport, {
    filters: { id },
  }, { responseType: "blob" });

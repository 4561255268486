// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Payments: () => Node = () => (
  <SvgIcon width="32" height="26" viewBox="0 0 32 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M18 14C17.1667 14 16.4583 13.7083 15.875 13.125C15.2917 12.5417 15 11.8333 15 11C15 10.1667 15.2917 9.45833 15.875 8.875C16.4583 8.29167 17.1667 8 18 8C18.8333 8 19.5417 8.29167 20.125 8.875C20.7083 9.45833 21 10.1667 21 11C21 11.8333 20.7083 12.5417 20.125 13.125C19.5417 13.7083 18.8333 14 18 14ZM11 17C10.45 17 9.97933 16.804 9.588 16.412C9.196 16.0207 9 15.55 9 15V7C9 6.45 9.196 5.97933 9.588 5.588C9.97933 5.196 10.45 5 11 5H25C25.55 5 26.021 5.196 26.413 5.588C26.8043 5.97933 27 6.45 27 7V15C27 15.55 26.8043 16.0207 26.413 16.412C26.021 16.804 25.55 17 25 17H11ZM13 15H23C23 14.45 23.1957 13.979 23.587 13.587C23.979 13.1957 24.45 13 25 13V9C24.45 9 23.979 8.804 23.587 8.412C23.1957 8.02067 23 7.55 23 7H13C13 7.55 12.804 8.02067 12.412 8.412C12.0207 8.804 11.55 9 11 9V13C11.55 13 12.0207 13.1957 12.412 13.587C12.804 13.979 13 14.45 13 15ZM24 21H7C6.45 21 5.97933 20.8043 5.588 20.413C5.196 20.021 5 19.55 5 19V8H7V19H24V21Z" />
  </SvgIcon>
);

export default Payments;

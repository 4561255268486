// @flow
/* eslint-disable import/max-dependencies */
import environment from "environment";
import type { Saga } from "redux-saga";
import {
  call,
  put,
  select,
  takeEvery,
} from "redux-saga/effects";
import type { Sorting, Filters } from "@fas/cpa-state-manager/redux/actions/table/actions";
import {
  getTableFilters,
  getTablePage,
  getTablePageSize,
  getTablePendingFields,
  getTableSorting,
} from "@fas/cpa-state-manager/services/selectors/table";
import { setLoading } from "@fas/cpa-state-manager/redux/actions/loading";
import {
  changeTableItemsTotalAmount,
  changeTablePage,
  setTableData,
  setTableFields,
} from "@fas/cpa-state-manager/redux/actions/table";
import isEqual from "lodash.isequal";
import { changeTableSorting } from "@fas/cpa-state-manager/redux/actions";
import { setTableExportParams, setTableTotalData } from "@fas/cpa-state-manager/redux/actions/table/actions";
import { catchError } from "@fas/cpa-state-manager/redux/actions/errors";
import {
  fetchData,
  getAllowedSorting,
  isEqualPrevious,
  type TableResponse,
} from "../../hooks/useTableFilters/useTableFilters";
import { MAIN_REPORT_TABLE, TRANSACTION_REPORT_TABLE } from "../../constants";

export function* makeFetch({ type: tableKey }: { type: string }): Saga<void> {
  try {
    const filters: * = yield select((state: *): Filters => getTableFilters(state, tableKey));
    const tableSorting: * = yield select((state: *): Sorting => getTableSorting(state, tableKey));
    const page: number = yield select((state: *): number => getTablePage(state, tableKey));
    const limit: number = yield select((state: *): number => getTablePageSize(state, tableKey));
    const pendingFields: string[] = yield select((state: *): string[] => getTablePendingFields(state, tableKey));

    yield put(setLoading(tableKey, true));

    if (!isEqualPrevious(`${tableKey}-filters`, filters) && page !== 1) {
      yield put(changeTablePage(tableKey, 1));
      return;
    }

    const preparedSorting: Sorting = getAllowedSorting(tableSorting, pendingFields);

    if (!isEqual(preparedSorting, tableSorting)) {
      yield put(changeTableSorting(tableKey, preparedSorting));
      return;
    }

    const method = tableKey === MAIN_REPORT_TABLE ? "mainReport" : "transactionReport";

    const { data: { data, count, total: [total] } }: TableResponse<{
      data: *[],
      count: number,
      total: [*]
  }> = yield call(fetchData, {
    filters,
    sorting: preparedSorting,
    page,
    limit,
    fields: pendingFields,
    path: environment.endpoints.post[method],
  });
    yield put(setLoading(`${tableKey}-fetched`, true));
    yield put(setTableData(tableKey, data));
    yield put(setTableTotalData(tableKey, total));
    yield put(changeTableItemsTotalAmount(tableKey, count));
    yield put(setTableFields(tableKey, pendingFields));
    yield put(setTableExportParams(tableKey, {
      filters,
    }));
  }
  catch (error) {
    yield put(catchError(error));
    // eslint-disable-next-line no-console
    console.error(error);
  }
  finally {
    yield put(setLoading(tableKey, false));
  }
}

export default function* watchGetReport(): Saga<void> {
  yield takeEvery(MAIN_REPORT_TABLE, (makeFetch: Function));
  yield takeEvery(TRANSACTION_REPORT_TABLE, (makeFetch: Function));
}

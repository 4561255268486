// @flow
import { type DefaultTheme } from "../themes";

const additionalPostback: (DefaultTheme) => * = (theme) => ({
  container: {
    width: "100%",
    "& + .MuiGrid-container": {
      marginTop: "40px",
      paddingTop: "0",
      [theme.breakpoints.up("sm")]: {
        marginTop: "0",
      },
    },
  },

  root: {
    background: theme.palette.transparent.light,
  },
  label: {
    color: theme.palette.text.main,
  },
  item: {
    borderRadius: theme.shape.borderRadius,
    background: theme.palette.transparent.light,
    padding: "13px 10px",
    marginRight: theme.spacing(1),
    marginTop: theme.spacing(0.5),
    marginBottom: theme.spacing(1),
    maxWidth: "700px",
  },
  row: {
    [theme.breakpoints.down("sm")]: {
      marginBottom: "30px",
    },
  },
  skeleton: {
    marginBottom: "20px",
  },
});

export default additionalPostback;

// @flow
/* eslint-disable import/max-dependencies, no-unused-vars */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { serialize } from "object-to-formdata";
import { useHandleRequest, type UseHandleRequestHook, useIsAffiliate } from "@fas/cpa-state-manager/redux/hooks";
import { useEnterPress, type UseEnterPressHook } from "@fas/cpa-state-manager/hooks";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { getUserInfo as getUserInfoSaga } from "@fas/cpa-state-manager/redux/actions/userInfo";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import type { ErrorsMapper, FetchError } from "@fas/ui-framework/lib/redux/reducers/errors";
import { USER_INFO_FORM } from "../../constants";
import { getUserInfo, postUserInfo, type User } from "../../services/deprecatedApi";

export type UseUserInfoFormHook = {
  isLoading: boolean,
  disabled: boolean,
  onSave: () => mixed,
  onKeyPress: (SyntheticKeyboardEvent<*>) => mixed,
};

export const prepareFetchErrors: (FetchError[]) => ErrorsMapper = (errors) => errors.reduce(
  (errorsMapper: ErrorsMapper, error: FetchError): ErrorsMapper => {
    const prevMessage: string = (errorsMapper[error.field] || { message: "" }).message;
    // $FlowFixMe
    const currentMessage: string = (error.error || error.message || "");
    const actualMessage: string = prevMessage ? prevMessage.concat(`\n${currentMessage}`) : currentMessage;
    return Object.assign(errorsMapper, {
      [error.field]: { message: actualMessage },
    });
  }, {}
);

export const useUserInfoForm: () => UseUserInfoFormHook = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const {
    isLoading,
    changeLoading,
    onFail,
    onFinally,
  }: UseHandleRequestHook = useHandleRequest(USER_INFO_FORM);
  const form: * = useSelector((state: *): * => getFormData(state, USER_INFO_FORM));

  useEffect(() => {
    changeLoading(true);

    getUserInfo()
      // $FlowFixMe
      .then(({ data: { id, ...user } }: { data: User }): mixed => dispatch(setFormData(USER_INFO_FORM, user))) // eslint-disable-line
      .catch(onFail)
      .finally(onFinally);
  }, []);

  const onSave: () => mixed = () => {
    changeLoading(true);

    const {
      howFoundOutComment,
      howFoundOut,
      email,
      photo,
      ...body
    }: * = form;
    if (photo instanceof File || photo === null) {
      // $FlowFixMe
      body.photo = photo;
    }

    postUserInfo(serialize(body, { allowEmptyArrays: true }))
      .then(() => {
        dispatch(addNotification({ severity: "success", message: "Personal info updated successfully" }));
        dispatch(getUserInfoSaga());
      })
      .catch(async (err: *) => {
        await onFail(err);

        if (err.isAxiosError
          && err.response
          && err.response.data
          && err.response.data.errors
          && Array.isArray(err.response.data.errors)) {
          dispatch(setErrors(prepareFetchErrors(err.response.data.errors)));
        }
      })
      .finally(onFinally);
  };

  const { onKeyPress }: UseEnterPressHook = useEnterPress(onSave);

  return {
    isLoading,
    disabled: !useIsAffiliate(),
    onSave,
    onKeyPress,
  };
};

// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const ScreenSearchDesktop: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="4 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M19.45 17.05L20.55 16L18.45 13.9C18.6333 13.6167 18.771 13.3167 18.863 13C18.9543 12.6833 19 12.35 19 12C19 11.0167 18.6543 10.1873 17.963 9.512C17.271 8.83733 16.45 8.5 15.5 8.5C14.55 8.5 13.7293 8.83733 13.038 9.512C12.346 10.1873 12 11.0167 12 12C12 12.9833 12.346 13.8127 13.038 14.488C13.7293 15.1627 14.55 15.5 15.5 15.5C15.85 15.5 16.1793 15.4543 16.488 15.363C16.796 15.271 17.1 15.1333 17.4 14.95L19.45 17.05ZM15.5 14C14.95 14 14.4793 13.804 14.088 13.412C13.696 13.0207 13.5 12.55 13.5 12C13.5 11.45 13.696 10.979 14.088 10.587C14.4793 10.1957 14.95 10 15.5 10C16.0333 10 16.5 10.1957 16.9 10.587C17.3 10.979 17.5 11.45 17.5 12C17.5 12.55 17.3043 13.0207 16.913 13.412C16.521 13.804 16.05 14 15.5 14ZM6 23C5.71667 23 5.47933 22.904 5.288 22.712C5.096 22.5207 5 22.2833 5 22C5 21.7167 5.096 21.4793 5.288 21.288C5.47933 21.096 5.71667 21 6 21H26C26.2833 21 26.5207 21.096 26.712 21.288C26.904 21.4793 27 21.7167 27 22C27 22.2833 26.904 22.5207 26.712 22.712C26.5207 22.904 26.2833 23 26 23H6ZM8 20C7.45 20 6.97933 19.8043 6.588 19.413C6.196 19.021 6 18.55 6 18V7C6 6.45 6.196 5.979 6.588 5.587C6.97933 5.19567 7.45 5 8 5H24C24.55 5 25.021 5.19567 25.413 5.587C25.8043 5.979 26 6.45 26 7V18C26 18.55 25.8043 19.021 25.413 19.413C25.021 19.8043 24.55 20 24 20H8ZM8 18H24V7H8V18Z" />
  </SvgIcon>
);

export default ScreenSearchDesktop;

// @flow
import { type DefaultTheme } from "../themes";

const trackingCode: (DefaultTheme) => * = (theme) => {
  const gradientButton: * = theme.components.MuiButton.variants.find((v: *): boolean => v.props.variant === "gradient") || {};

  return ({
    container: {
      flexWrap: "nowrap",
      alignItems: "flex-start",
      "& > div:nth-child(1)": {
        width: "100%",
        maxWidth: "100%",
        flexBasis: "100%",
      },
      "& > div:nth-child(2)": {
        marginTop: "10px",
        width: "100%",
        flexBasis: "100%",
        minWidth: "150px",
        maxWidth: "150px",
        [theme.breakpoints.up("sm")]: {
          marginTop: "25px",
        },
      },
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
        "& > div:nth-child(2)": {
          maxWidth: "100%",
        },
      },
    },
    input: {
      marginRight: theme.spacing(2),
      [theme.breakpoints.down("sm")]: {
        marginRight: 0,
      },
      "& div.Mui-error": {
        borderLeft: `2px solid ${theme.palette.error.main}`,
        backgroundColor: `${theme.palette.transparent.light}`,
      },
      "& .MuiInputLabel-root": {
        color: theme.palette.text.main,
      },
      "& .MuiInputLabel-root.Mui-focused": {
        color: theme.palette.text.main,
        fontWeight: 700,
      },
    },
    button: {
      ...gradientButton.style,
      color: theme.palette.text.main,
    },
    helper: {
      color: theme.palette.transparent.text,
      marginLeft: 0,
      ...theme.typography.subtext,
    },
  });
};

export default trackingCode;

// @flow
import type { ErrorsMapper } from "@fas/ui-framework/lib/redux/reducers/errors";
import dayjs from "dayjs";
import contentDisposition from "content-disposition";
import isEqual from "lodash.isequal";
import type { Node } from "react";
import { useTheme } from "@mui/styles";
import { useMediaQuery } from "@mui/material";

type ObjDef = { [*]: *, ..., };

export const getValidation: (*) => (ObjDef) => ErrorsMapper = (schema: *) => (value: ObjDef): ErrorsMapper => {
  const { error: { details = {} } = {} }: * = schema.validate(value, { abortEarly: false });

  return Object.keys(details).reduce((acc: ErrorsMapper, key: string): ErrorsMapper => ({
    ...acc,
    [details[key].path.join(".")]: { message: details[key].message },
  }), {});
};

const downloadData: ({ url: string, name: string }) => void = ({ url, name }) => {
  const hiddenElement: HTMLAnchorElement = document.createElement("a");
  hiddenElement.href = url;
  hiddenElement.target = "_blank";
  hiddenElement.download = name;
  document.body && document.body.appendChild(hiddenElement); // for FF
  hiddenElement.click();
  hiddenElement.remove();
};

export function downloadCsv(data: string, name?: string) {
  downloadData({
    url: `data:text/csv;charset=utf-8,${encodeURI(data)}`,
    name: name || `Report-${dayjs().format("dddd_MMMM_Do_YYYY_H_mm_ss")}.csv`,
  });
}

export function downloadPdf(blob: Blob, name?: string) {
  downloadData({
    url: window.URL.createObjectURL(new Blob([blob], { type: "application/octet-stream" })),
    name: name || `Invoice-${dayjs().format("dddd_MMMM_Do_YYYY_H_mm_ss")}.pdf`,
  });
}

export function downloadZip(blob: Blob, name?: string) {
  downloadData({
    url: window.URL.createObjectURL(new Blob([blob], { type: "application/octet-stream" })),
    name: name || `Invoice-${dayjs().format("dddd_MMMM_Do_YYYY_H_mm_ss")}.zip`,
  });
}

export function getFileNameFromHeaders(headers: ?{[key: string]: mixed, ...}, defaultName: string = ""): string {
  if (!headers) return defaultName;
  try {
    const disposition: string = String(headers["content-disposition"] || headers["Content-Disposition"] || headers["Content-disposition"] || "");
    return contentDisposition.parse(disposition).parameters.filename || defaultName;
  }
  catch {
    return defaultName;
  }
}

export const isEqualPreviousBuilder: () => (*) => boolean = () => {
  let oldVal: *;
  return function isEqualPrevious(currentVal: *): boolean {
    const isEqualValue: boolean = isEqual(currentVal, oldVal);
    oldVal = currentVal;
    return isEqualValue;
  };
};

export const readObjFromFile: (file: Blob | File) => Promise<*> = (file: Blob | File) => {
  const fileReader: FileReader = new FileReader();
  return new Promise((resolve: (*) => void) => {
    fileReader.onload = () => {
      // $FlowFixMe
      resolve(JSON.parse(fileReader.result));
    };

    fileReader.readAsText(file);
  });
};
export function numberWithCommas(x: number | string): string {
  return Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(Number(x || 0));
}

export function formatCurrency(currency: string, value: string | number): string {
  const result: string = Intl.NumberFormat("en-US", { style: "currency", currency: currency || "USD" }).format(Number(value));
  return `${result.split("").splice(0, 1).join("")} ${result.split("").splice(1).join("")}`;
}

export function RenderMediaQueryComponent({ children, mediaKey, method = "down" }: { children: Node, mediaKey: "xs" | "sm" | "md" | "lg", method: "up" | "down" | "only" | "not"}): Node {
  const theme: * = useTheme();
  const isMatch: boolean = useMediaQuery(theme.breakpoints[method](mediaKey));
  return isMatch && children;
}

export const onKeyWrapper: * = (keys: string | string[]) => (fn: (*) => mixed): * => {
  const keyList: string[] = Array.isArray(keys) ? keys : [keys];

  return function wrapped(...args: *) {
    const [{ key }]: * = args;

    if (keyList.includes(key)) fn.apply(this, args);
  };
};

// @flow
import { type DefaultTheme } from "../themes";

const smartlinkGenerator: (DefaultTheme) => * = (theme) => ({
  layout: {
    padding: 0,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
    },
  },
  root: {
    "& > div": {
      marginBottom: theme.spacing(2),
    },
    "& > div:last-child": {
      marginBottom: 0,
    },
  },
  radioGroup: {
    flexDirection: "row",
    "& label": {
      color: theme.palette.transparent.default,
    },
    "& .Mui-checked .MuiSvgIcon-root": {
      fill: "url(#selected)",
    },
  },
  radioBtn: {
    color: theme.palette.common.white,
    ...theme.typography.body,
  },
  groupTitle: {
    ...theme.typography.h5,
    [theme.breakpoints.down("lg")]: {
      ...theme.typography.buttonSmall,
    },
    [theme.breakpoints.down("md")]: {
      ...theme.typography.bodyBold,
    },
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.h5,
    },
  },
  btnsLayout: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "20px",
    marginTop: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      marginTop: theme.spacing(1),
      gridGap: "10px",
    },
  },
});

export default smartlinkGenerator;

// @flow
import { type DefaultTheme } from "../themes";

const howToChips: (DefaultTheme) => * = (theme) => ({
  container: {
    display: "flex",
    marginBottom: "1px",
    alignItems: "center",
    [theme.breakpoints.up("sm")]: {
      display: "block",
      marginBottom: "10px",
    },
  },
  chips: {
    display: "grid",
    gridGap: "1px",
    width: "40%",
    [theme.breakpoints.up("sm")]: {
      display: "inline",
    },
  },
  labels: {
    display: "block",
    textAlign: "center",
    width: "60%",
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      textAlign: "left",
    },
  },
  label: {
    display: "block",
    ...theme.typography.tableMob,
    lineHeight: "1.5",
    [theme.breakpoints.up("sm")]: {
      display: "inline",
      textAlign: "left",
      lineHeight: "2",
      ...theme.typography.body,
    },
  },
});

export default howToChips;

// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import {
  FormControl,
  InputAdornment,
  Input,
  InputLabel,
  FormHelperText,
  Typography, Tooltip,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import { withFormField } from "@fas/ui-core/lib/Form/FormContext";
import { useAuth } from "@fas/cpa-state-manager/hooks";
import { withState } from "@fas/cpa-cabinet-ui";
import {
  formFieldLabelStyles,
  formFieldInputStyles,
  formFieldGradientIconStyles,
} from "../../styles";

const StyledInputLabel: StatelessFunctionalComponent<*> = withStyles(formFieldLabelStyles)(InputLabel);
const StyledInput: StatelessFunctionalComponent<*> = withStyles(formFieldInputStyles)(Input);
const SpacedStyledInput: StatelessFunctionalComponent<*> = StyledInput;
const StyledInputAdornment: StatelessFunctionalComponent<*> = withStyles(formFieldGradientIconStyles)(InputAdornment);

export type Props = {
  name: string,
  label: string,
  disabled?: boolean,
  type?: string,
  "data-testid"?: string,
  placeholder?: string,
  StartIcon?: ?Element<*>,
  EndIcon?: ?Element<*>,
  error?: string,
  value?: *,
  onChange?: (*) => *,
  onClick?: (*) => *,
  onKeyPress?: (SyntheticKeyboardEvent<*>) => *,
  autoFocus?: boolean,
  sx?: *,
  slotProps?: *,
  formControlSx?: *,
  inputProps?: *,
  className?: *,
};

const defaultChange: (*) => * = (e) => e;
const defaultType: string = "text";

export const TextFieldForm: StatelessFunctionalComponent<Props> = ({
  name,
  label,
  error,
  value,
  StartIcon,
  EndIcon,
  sx,
  onKeyPress,
  autoFocus,
  placeholder,
  disabled = false,
  type = defaultType,
  onChange = defaultChange,
  onClick,
  "data-testid": dataTestId,
  // $FlowFixMe
  isAuthenticated = false,
  inputProps,
  className,
  formControlSx = { mb: 2 },
  slotProps,
}) => (
  <Tooltip title="" {...slotProps?.tooltip}>
    <FormControl sx={formControlSx} variant="standard" fullWidth>
      <StyledInputLabel htmlFor={name} authenticated={String(isAuthenticated)}><Typography component="p">{label}</Typography></StyledInputLabel>
      <SpacedStyledInput
        authenticated={String(isAuthenticated)}
        data-testid={dataTestId || name}
        id={name}
        sx={sx}
        fullWidth
        disableUnderline
        variant="filled"
        type={type}
        margin="dense"
        placeholder={placeholder || label}
        value={value}
        disabled={disabled}
        autoFocus={Boolean(autoFocus)}
        onChange={(e: SyntheticInputEvent<HTMLInputElement>): * => onChange(e.target.value)}
        onClick={onClick}
        onKeyPress={onKeyPress}
        error={Boolean(error)}
        startAdornment={StartIcon && <StyledInputAdornment position="end">{StartIcon}</StyledInputAdornment>}
        endAdornment={EndIcon && <InputAdornment position="start">{EndIcon}</InputAdornment>}
        inputProps={inputProps}
        className={className}
      />
      <FormHelperText sx={{ whiteSpace: "pre-wrap" }} data-testid={`${name}-error`} error={Boolean(error)}>{error}</FormHelperText>
    </FormControl>
  </Tooltip>
);

// $FlowFixMe
export default withState(withFormField(TextFieldForm), useAuth);

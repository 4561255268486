// @flow
import React, { type Node } from "react";

const Logo: () => Node = () => (
  <svg width="140" height="30" viewBox="0 0 140 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.975 6.75V12.525L11.55 11.25L15.975 7.5V0L9.975 6.75Z" fill="url(#paint0_linear)" />
    <path d="M9.975 20.325L6 22.425V11.25L0 18V30L6 27.75L9.975 26.25L15.975 24V13.125L9.975 16.95V20.325Z" fill="url(#paint1_linear)" />
    <path d="M21.9751 6.75V12.525L20.4751 11.25L15.9751 7.5V0L21.9751 6.75Z" fill="url(#paint2_linear)" />
    <path d="M21.9751 20.325L26.0251 22.425V11.25L32.0251 18V30L26.0251 27.75L21.9751 26.25L15.9751 24V13.125L21.9751 16.95V20.325Z" fill="url(#paint3_linear)" />
    <path d="M44.85 25.5751H42V8.02513C42 5.62513 43.125 4.42513 45.375 4.42513H47.475C49.725 4.42513 50.85 5.62513 50.85 8.02513V25.5751H48V17.8501H44.775V25.5751H44.85ZM48.075 15.3001V8.25013C48.075 7.42513 47.7 7.05013 46.875 7.05013H46.125C45.3 7.05013 44.925 7.42513 44.925 8.25013V15.3001H48.075Z" fill="white" />
    <path d="M54.225 25.5751V4.42513H59.175C61.8 4.42513 63.15 5.77513 63.15 8.47513V22.5001C63.15 24.5251 62.025 25.5751 59.775 25.5751H54.225ZM57 23.0251H59.1C59.85 23.0251 60.225 22.7251 60.225 22.0501V8.55013C60.225 7.50013 59.775 7.05013 58.95 7.05013H57V23.0251Z" fill="white" />
    <path d="M71.475 10.1251V8.25013C71.475 7.42513 71.1 6.97513 70.275 6.97513H69.75C68.925 6.97513 68.55 7.42513 68.55 8.25013V11.7751C68.55 12.1501 68.625 12.4501 68.775 12.6001C68.925 12.7501 69.15 12.9001 69.525 13.1251L72.225 14.5501C72.825 14.8501 73.2 15.1501 73.5 15.3001C73.725 15.4501 73.95 15.6751 74.1 15.9751C74.25 16.2751 74.325 16.7251 74.325 17.4751V21.9751C74.325 24.3751 73.2 25.5751 70.875 25.5751H69.15C66.9 25.5751 65.775 24.3001 65.775 21.6751V20.6251H68.625V21.7501C68.625 22.5751 69.075 22.9501 69.9 22.9501H70.425C71.175 22.9501 71.55 22.5751 71.55 21.7501V18.1501C71.55 17.7751 71.475 17.4751 71.325 17.3251C71.175 17.1751 70.95 17.0251 70.575 16.8001L67.875 15.3001C67.275 15.0001 66.825 14.7001 66.6 14.4751C66.3 14.2501 66.15 13.9501 66 13.6501C65.85 13.2751 65.85 12.8251 65.85 12.3001V7.95013C65.85 5.55013 66.975 4.35013 69.225 4.35013H71.025C73.275 4.35013 74.4 5.55013 74.4 7.95013V9.97513H71.475V10.1251Z" fill="white" />
    <path d="M77.7749 25.5749V4.4249H84.7499V6.9749H80.5499V13.4249H84.2249V15.9749H80.5499V23.0249H84.7499V25.5749H77.7749Z" fill="url(#paint4_linear)" />
    <path d="M93.3749 16.8749L95.9999 4.4249H99.1499V25.5749H96.2999L96.5999 11.6999L94.4999 21.7499H92.2499L90.1499 11.6999L90.4499 25.5749H87.5999V4.4249H90.7499L93.3749 16.8749Z" fill="url(#paint5_linear)" />
    <path d="M102.6 4.4249H107.775C110.025 4.4249 111.225 5.6249 111.225 8.0249V14.3249C111.225 16.7249 110.1 17.9249 107.85 17.9249H105.525V25.5749H102.675V4.4249H102.6ZM105.375 15.3749H107.175C107.925 15.3749 108.3 14.9999 108.3 14.1749V8.2499C108.3 7.4249 107.85 7.0499 107.025 7.0499H105.375V15.3749Z" fill="url(#paint6_linear)" />
    <path d="M114.15 25.5749V4.4249H117V25.5749H114.15Z" fill="url(#paint7_linear)" />
    <path d="M126 4.4249C128.175 4.4249 129.225 5.6249 129.225 8.0249V13.2749C129.225 15.1499 128.7 16.2749 127.575 16.7999L129.525 25.5749H126.825L124.95 17.0249H123.225V25.5749H120.375V4.4249H126ZM123.3 14.6249H125.175C126 14.6249 126.45 14.0999 126.45 13.1249V8.5499C126.45 7.4999 126 7.0499 125.175 7.0499H123.3V14.6249Z" fill="url(#paint8_linear)" />
    <path d="M132.375 25.5749V4.4249H139.35V6.9749H135.15V13.4249H138.825V15.9749H135.15V23.0249H139.35V25.5749H132.375Z" fill="url(#paint9_linear)" />
    <defs>
      <linearGradient id="paint0_linear" x1="0" y1="15" x2="15.975" y2="15" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id="paint1_linear" x1="0" y1="15" x2="15.975" y2="15" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id="paint2_linear" x1="15.9751" y1="15" x2="32.0251" y2="15" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id="paint3_linear" x1="15.9751" y1="15" x2="32.0251" y2="15" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id="paint4_linear" x1="77.7749" y1="14.9999" x2="139.35" y2="14.9999" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id="paint5_linear" x1="77.7749" y1="14.9999" x2="139.35" y2="14.9999" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id="paint6_linear" x1="77.7749" y1="14.9999" x2="139.35" y2="14.9999" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id="paint7_linear" x1="77.7749" y1="14.9999" x2="139.35" y2="14.9999" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id="paint8_linear" x1="77.7749" y1="14.9999" x2="139.35" y2="14.9999" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id="paint9_linear" x1="77.7749" y1="14.9999" x2="139.35" y2="14.9999" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
    </defs>
  </svg>
);

export default Logo;

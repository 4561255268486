// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import { Box, Button, Typography } from "@mui/material";
import { Helmet } from "react-helmet";
import {
  withHookPropsState,
  type PaymentInfoFormComponent,
  type PaymentInfoFormProps,
} from "@fas/cpa-cabinet-ui";
import { useDictionary } from "@fas/cpa-state-manager/redux/hooks";
import useFormField from "@fas/ui-framework/lib/services/form/useFormField";
import type { Props as FormProps } from "@fas/ui-framework/lib/services/form/withForm";
import withForm from "@fas/ui-framework/lib/services/form/withForm";
import { Form } from "@fas/ui-core";
import PaymentInfoForm from "@fas/cpa-cabinet-ui/lib/PaymentInfoForm/PaymentInfoForm";
import { withStyles } from "@mui/styles";
import type { ComponentProps } from "@fas/cpa-cabinet-ui/lib/withState/withState.types";
import type { Dictionaries } from "@fas/cpa-state-manager/redux/reducers";
import type { UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import {
  ActivePaxumIcon, PaxumIcon, ActivePaypalIcon, PaypalIcon,
  PayoneerIcon,
} from "@fas/cpa-cabinet-ui/lib/icons";
import { LockOutlined } from "@mui/icons-material";
import TextFieldForm from "../TextFieldForm";
import { PAYMENT_METHOD_FORM } from "../../constants";
import SelectPaymentMethod from "./SelectPaymentMethod";
import type { UsePaymentInfoFormHook } from "../../hooks";
import paymentInfoForm from "../../styles/paymentInfoForm";
import { getValidation } from "../../utils";
import schema from "./validation";
import type { Props as SelectProps } from "../SelectPopup/SelectPopup";
import { SelectPopupField } from "../SelectPopup/SelectPopup";
import Paxum from "./Paxum";
import PayPal from "./PayPal";
import Payoneer from "./Payoneer";

const DictionarySelectFieldForm: StatelessFunctionalComponent<
  ComponentProps<SelectProps, Dictionaries, UseDictionaryType>
> = withHookPropsState(SelectPopupField, useDictionary);

const SelectPaymentMethodField: $Call<
  typeof withHookPropsState, typeof SelectPaymentMethod, typeof useDictionary
> = withHookPropsState(SelectPaymentMethod, useDictionary);
const FormContainer: StatelessFunctionalComponent<FormProps> = withForm(Form);

const components: PaymentInfoFormComponent = {
  Button,
  TextFieldForm: ({ disabled, ...props }) => (
    <TextFieldForm
      {...props}
      disabled={disabled}
      {...{
        ...(disabled ? { EndIcon: <LockOutlined /> } : {}),
        slotProps: {
          tooltip: {
            title: disabled && "For any updates in this field, please contact your account manager.",
          },
        },
      }}
    />
  ),
  DictionarySelectFieldForm: ({ disabled, ...props }) => (
    <DictionarySelectFieldForm
      getOptionDisabled={(option) => option?.hidden}
      disabled={disabled}
      {...props}
      {...{
        ...(disabled ? { EndIcon: <LockOutlined /> } : {}),
        slotProps: {
          tooltip: {
            title: disabled && "For any updates in this field, please contact your account manager.",
          },
        },
      }}
    />
  ),
  FormContainer,
};

type Props = UsePaymentInfoFormHook;

const StyledPaymentInfo: StatelessFunctionalComponent<
  PaymentInfoFormProps
> = withStyles(paymentInfoForm)(PaymentInfoForm);

const PaymentInfo: StatelessFunctionalComponent<Props> = ({
  onSave, isLoading, reset, isChanged, disabled,
}: Props) => {
  const {
    value: paymentMethodId,
  }: $Call<typeof useFormField, "", ""> = useFormField(PAYMENT_METHOD_FORM, "paymentMethodId");
  const {
    value: isEdit,
  }: $Call<typeof useFormField, "", ""> = useFormField(PAYMENT_METHOD_FORM, "isEdit");

  const currency = useFormField(PAYMENT_METHOD_FORM, "currency");

  return (
    <Box>
      <Helmet>
        <title>Payment info</title>
      </Helmet>
      <SelectPaymentMethodField disabled={!isEdit || disabled} hookProps="paymentMethod" reset={reset} isChanged={isChanged} />
      <Box height={20} />
      <Box
        display="flex"
        alignItems="flex-start"
        justifyContent="space-between"
        flexDirection="column"
        sx={(theme: *): * => ({
          background: theme.palette.background.hints,
          borderRadius: 3.75,
          p: 2,
          mb: 2,
          maxWidth: 800,
          display: !isEdit || disabled ? "block" : "none",
        })}
      >
        <Typography sx={{ pb: 1, typography: { lg: "h5", xs: "bodyBold" } }}>Warning! </Typography>
        <Typography sx={{ typography: { lg: "bodyBold", xs: "subtext" } }}>To change the payment method or make adjustments to the current one - please contact your account manager.</Typography>
      </Box>
      <Box
        sx={{
          display: "flex", alignItems: "center", mb: 1, justifyContent: "flex-end", maxWidth: 800,
        }}
      >
        <Typography mr={1}>Currency: </Typography>
        <Typography>{currency.value}</Typography>
      </Box>
      <StyledPaymentInfo
        id={PAYMENT_METHOD_FORM}
        onSave={onSave}
        disabled={!isEdit || isLoading || disabled}
        components={components}
        paymentMethodId={Number(paymentMethodId)}
        validate={getValidation(schema)}
        mapMethods={{
          "2": {
            id: 2,
            label: "Paxum",
            icon: <PaxumIcon />,
            iconActive: <ActivePaxumIcon />,
            Form: Paxum,
          },
          "9": {
            id: 9,
            label: "Payoneer",
            icon: <PayoneerIcon />,
            iconActive: <PayoneerIcon />,
            Form: Payoneer,
          },
          "10": {
            id: 10,
            label: "PayPal",
            icon: <PaypalIcon />,
            iconActive: <ActivePaypalIcon />,
            Form: PayPal,
          },
        }}
      />
    </Box>
  );
};

export default PaymentInfo;

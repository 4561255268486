// @flow

import type { StatelessFunctionalComponent } from "react";
import { withStyles } from "@mui/styles";
import DateRangeInput from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangeInput";
import type { DefaultTheme } from "../../themes";

const DateInputComponent: StatelessFunctionalComponent<*> = withStyles((theme: DefaultTheme): * => ({
  root: {
    width: "100%",
    "& .MuiInputBase-root": {
      background: theme.palette.transparent.light,
    },
    "& .MuiInputBase-root.Mui-focused": {
      background: theme.palette.transparent.main,
    },
    "&:before": {
      content: "none",
    },
    "& input": {
      padding: "13px 10px",
      lineHeight: 1.5,
      height: "24px",
    },
    "& fieldset": {
      border: "none",
    },
    "&:after": {
      content: "none",
    },
    "& .MuiSvgIcon-root": {
      fill: "url(#selected)",
    },
  },
}))(DateRangeInput);

export default DateInputComponent;

// @flow
import React from "react";
import type { StatelessFunctionalComponent, Node } from "react";
import { Box, Typography } from "@mui/material";
import { type LineInfoType } from "./types/Chart.types";

type Props = {
  payload: LineInfoType[],
}

const ChartYaxesLegend: StatelessFunctionalComponent<Props> = ({ payload }: Props) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: { xs: "10px 0", lg: "20px 0" } }}>
    {payload.map(({ dataKey, labelLegend }: LineInfoType): Node => (
      <Typography
        variant="span"
        key={dataKey}
        sx={{
          textTransform: "uppercase", fontWeight: "700", fontSize: { xs: "14px", lg: "16px" }, lineHeight: { xs: "20px", lg: "26px" },
        }}
      >
        {labelLegend}
      </Typography>
    ))}
  </Box>
);

export default ChartYaxesLegend;

// @flow

import { type DefaultTheme } from "../themes";

const reportTableMainTotal: (DefaultTheme) => * = (theme) => ({
  container: {
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    gridGap: "10px",
    [theme.breakpoints.down("sm")]: {
      gridTemplateColumns: "1fr",
      marginTop: "20px",
    },
    "&[data-empty=\"true\"]": {
      display: "none",
    },
  },
  item: {
    background: "#3c7065",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    borderRadius: "4px",
    padding: "2px 40px",
    fontSize: "16px",
    fontWeight: "500",
    [theme.breakpoints.down("lg")]: {
      padding: "1px 40px",
      fontSize: "12px",
      fontWeight: "700",
    },
    [theme.breakpoints.down("md")]: {
      padding: "5px 20px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "5px 10px",
    },
  },
  label: {
    marginRight: "10px",
  },
  data: {
    [theme.breakpoints.down("md")]: {
      ...theme.typography.h5,
    },
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.subtextBold,
    },
  },
});

export default reportTableMainTotal;

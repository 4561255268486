// @flow
import React from "react";
import { Link, Typography } from "@mui/material";
import type { QuestionType } from "./types/Faq.types";

// eslint-disable-next-line import/prefer-default-export
export const questions: QuestionType[] = [
  // general
  {
    question: "What is AdsEmpire?",
    answer: "AdsEmpire is a fast-growing affiliate network which provides plenty of profitable opportunities with many verticals for affiliates and advertisers. We have the best technologies to help you monetize 100% of your traffic in any convenient way, via direct CPA offers or Smartlink.",
    keywords: ["network", "direct", "smartlink"],
    tab: "general",
  },
  {
    question: "What are the main AdsEmpire verticals?",
    answer: "We have a wide range of profitable verticals, such as dating, games and sweepstakes.",
    keywords: ["vertical", "dating", "games", "crypto", "sweepstakes"],
    tab: "general",
  },
  {
    question: "What traffic types does AdsEmpire accept?",
    answer: "We allow all kinds of legal traffic that doesn’t violate the corporate rules and policies of our advertisers. We don’t accept any kind of fraudulent traffic, chat traffic, or incentive traffic. Please note that such traffic sources as Email, SMS, Celebrity, Clickunder, or Popunders traffic are restricted by some advertisers. You must notify your account manager about such traffic beforehand, otherwise we reserve the right to withhold part or the entire sum of your traffic value. There might also be some other general traffic restrictions for exact offers, so please read the description of each specific offer.",
    keywords: ["traffic", "incentive", "chat", "crypto", "fraudulent"],
    tab: "general",
  },
  {
    question: "What GEOs does AdsEmpire accept?",
    answer: "We accept traffic from all over the world.",
    keywords: ["geo", "country"],
    tab: "general",
  },
  {
    question: "What is the best targeting option for me?",
    answer: "Targeting options depend on the advertiser’s requirements for each offer and traffic source. You can ask your manager for the best targeting recommendation for specific offers.",
    keywords: ["targeting"],
    tab: "general",
  },

  // started
  {
    question: "How can I join AdsEmpire?",
    answer: (
      <>
        <span>You can sign up as a CPA network affiliate</span>
        {" "}
        <Typography component={Link} variant="gradientBody" href="https://adsempire.com/signup-direct.html">here</Typography>
        .
        <br />
        <span>You can sign up as a Smartlink affiliate</span>
        {" "}
        <Typography component={Link} variant="gradientBody" href="https://adsempire.com/signup-smartlink.html">here</Typography>
        .
        <br />
        <span>You can sign up as an advertiser</span>
        {" "}
        <Typography component={Link} variant="gradientBody" href="https://adsempire.com/signup-adv.html">here</Typography>
        .
      </>
    ),
    keywords: ["join", "register", "smartlink"],
    tab: "started",
  },
  {
    question: "How long will it take for my application to be approved?",
    answer: "We typically need 48 hours to review an application.",
    keywords: ["application", "registration", "approve"],
    tab: "started",
  },
  {
    question: "How can I contact your support?",
    answer: (
      <>
        <span>You can contact our Support Team using the form</span>
        {" "}
        <Typography component={Link} variant="gradientBody" href="https://adsempire.com/">here</Typography>
        .
      </>
    ),
    keywords: ["traffic", "incentive", "chat", "fraudulent"],
    tab: "started",
  },

  // payment
  {
    question: "What are the payment terms?",
    answer: "Payment terms are monthly net30, but you can get upgraded to weekly payments if your weekly traffic volumes exceed $1,500.",
    keywords: ["payment", "payout"],
    tab: "payment",
  },
  {
    question: "What payment options do you have?",
    answer: "You can get paid via Bitcoin, USDT, Wire, Paxum, PayPal payment systems.",
    keywords: ["payment", "wire", "bitcoin", "usdt", "paxum", "paypal"],
    tab: "payment",
  },
  {
    question: "What is the threshold for monthly and weekly payments?",
    answer: (
      <>
        <span>The threshold for weekly payments is $1,500 for Bitcoin, USDT.</span>
        <br />
        <span>$2,000 for Wire, Paxum, PayPal payment methods.</span>
        <br />
        <span>The threshold for monthly payments is $250 for Bitcoin, USDT.</span>
        <br />
        <span>$2,000 for Wire, Paxum, PayPal payment methods.</span>
      </>
    ),
    keywords: ["payment", "wire", "bitcoin", "usdt", "paxum", "paypal"],
    tab: "payment",
  },

  // technical
  {
    question: "How do I set up S2S postback?",
    answer: "You can set up your postback in the ‘Postback URLs’ section of your account.",
    keywords: ["s2s", "postback", "tracking"],
    tab: "technical",
  },
  {
    question: "What does ‘Declined’ status mean?",
    answer: "Your leads may be declined for several reasons, a list of which you can find in the ‘Transaction Report’ section. To receive further information, please contact your personal affiliate manager.",
    keywords: ["decline", "report", "conversion"],
    tab: "technical",
  },
  {
    question: "How often is statistics data updated?",
    answer: "All statistics are updated every 15 minutes in the ‘Reports’ section of your account.",
    keywords: ["statistics", "update", "data"],
    tab: "technical",
  },
];

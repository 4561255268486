/* eslint-disable import/max-dependencies */
// @flow
import React, { type Node, type StatelessFunctionalComponent } from "react";
import { Helmet } from "react-helmet";
import { Box, Typography } from "@mui/material";
import { withStyles } from "@mui/styles";
import type { CardSetupProps } from "@fas/cpa-cabinet-ui";
import {
  SetupCard,
  withState,
} from "@fas/cpa-cabinet-ui";
import Chart from "../Chart";
import {
  useChart, type UseChartHook,
} from "../../hooks";
import type { Props as ChartProps } from "../Chart/Chart";
import DividerBlock from "./DividerBlock";
import {
  setupCardStyles,
} from "../../styles";
import { useLocalStorage, type UseStorageHook } from "../../hooks/useStorage";
import HelpUkraineBanner from "./HelpUkraineBanner";
import BestOffers from "../BestOffers";

const ChartComponent: StatelessFunctionalComponent<$Diff<ChartProps, UseChartHook>> = withState(Chart, useChart);

const StyledSetupCard: StatelessFunctionalComponent<CardSetupProps> = withStyles(setupCardStyles)(SetupCard);

const Banner: * = withState(HelpUkraineBanner, (): * => {
  const [open, setOpen]: UseStorageHook<boolean> = useLocalStorage<boolean>("ukrBannerModal", true);
  return {
    open,
    onClose: (): * => setOpen(false),
  };
});

function Dashboard(): Node {
  return (
    <StyledSetupCard title="">
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      <Box width={1} height={1} flexWrap="nowrap" sx={{ display: "flex", flexDirection: { xs: "column", lg: "row" }, alignItems: "stretch" }}>
        <Box display="flex" flexDirection="column" width={1} flexWrap="nowrap" sx={{ overflow: { xs: "visible", lg: "hidden" } }}>
          <Banner sx={{ display: { xs: "block", md: "none" }, mb: 2 }} />
          <Box width={1} flexDirection="column" sx={{ display: "flex", flex: "1", overflow: { xs: "visible", lg: "hidden" } }}>
            <Box>
              <Typography variant="gradientH4">Dashboard</Typography>
            </Box>
            <ChartComponent />
          </Box>
        </Box>
        <DividerBlock />
        <Box
          data-testid="best-opportunities-component"
          sx={{
            flexDirection: "column",
            display: { xs: "block", lg: "flex" },
            height: { xs: "fit-content", lg: "100%" },
            marginTop: { xs: "40px", lg: "0" },
            "& .MuiTableContainer-root": {
              overflowX: {
                sx: "auto",
                md: "hidden",
              },
            },
          }}
        >
          <BestOffers />
        </Box>
      </Box>
    </StyledSetupCard>
  );
}

export default Dashboard;

// @flow
import environment from "environment";
import { Dashboard } from "@mui/icons-material";
import React from "react";

import Smartlink from "../../icons/Smartlink";
import Payment from "../../icons/Payment";
import Statistics from "../../icons/Statistics";
import TrackingTools from "../../icons/TrackingTools";

export default [
  {
    url: environment.links.dashboard,
    text: "DASHBOARD",
    icon: <Dashboard />,
  },
  {
    url: environment.links.links,
    text: "SMARTLINKS",
    icon: <Smartlink />,
  },
  {
    url: [
      environment.links.reports.replace(":type", "main"),
      environment.links.reports.replace(":type", "transaction"),
    ],
    text: "STATISTICS",
    icon: <Statistics />,
  },
  {
    url: environment.links.paymentHistory,
    text: "PAYMENT HISTORY",
    icon: <Payment />,
  },
  {
    url: [
      environment.links.postbacks.replace(":type", "defaulPostback"),
      environment.links.postbacks.replace(":type", "additionalPostback"),
      environment.links.postbacks.replace(":type", "infoPostback"),
    ],
    text: "TRACKING TOOLS",
    icon: <TrackingTools />,
  },
];

// @flow
import { type DefaultTheme } from "../themes";

const tableFilters: (DefaultTheme) => * = (theme) => {
  const gradientTextButton: * = theme.components.MuiButton.variants.find((v: *): boolean => v.props.variant === "gradientText") || {};

  return {
    popoverPaper: {
      marginTop: theme.spacing(1),
      borderRadius: theme.shape.cardBorderRadius,
      boxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.2)",
    },
    filtersContainer: {
      justifyContent: "space-between",
      "& > :nth-child(1)": {
        display: "flex",
        [theme.breakpoints.down("md")]: {
          marginBottom: theme.spacing(1),
        },
      },
      "& > :nth-child(3)": {
        marginLeft: "auto",
        [theme.breakpoints.down("md")]: {
          width: "100%",
        },
      },
      ...theme.typography.bodyBold,
      [theme.breakpoints.down("lg")]: {
        ...theme.typography.subtext,
      },
    },
    deleteAllBtn: {
      ...gradientTextButton.style,
      paddingTop: "8px",
      paddingBottom: "8px",
      marginLeft: theme.spacing(1),
      color: theme.palette.transparent.dark,
      border: `2px solid ${theme.palette.transparent.light}`,
      "&:hover": {
        border: `2px solid ${theme.palette.transparent.main}`,
      },
      ...theme.typography.body,
      [theme.breakpoints.down("lg")]: {
        ...theme.typography.subtext,
      },
    },
    chipsContainer: {
      minWidth: "230px",
      display: "flex",
      flexWrap: "noWrap",
      overflowX: "auto",
      marginRight: "10px",
      "& > :nth-child(1) .MuiChip-deleteIcon": {
        display: "none",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "0px",
      },
    },
    filtersChips: {
      maxWidth: "250px",
      marginLeft: theme.spacing(1),
      backgroundColor: theme.palette.transparent.light,
      borderRadius: theme.shape.borderRadius,
      border: "2px solid transparent",
      "&:hover": {
        backgroundColor: "transparent",
        borderColor: theme.palette.transparent.light,
      },
      "& .MuiSvgIcon-root": {
        [theme.breakpoints.down("lg")]: {
          fontSize: "18px",
        },
      },
      height: "auto",
      paddingTop: "8px",
      paddingBottom: "8px",
      ...theme.typography.body,
      [theme.breakpoints.down("lg")]: {
        ...theme.typography.subtext,
      },
      [theme.breakpoints.down("md")]: {
        marginBottom: theme.spacing(1),
      },
    },
  };
};

export default tableFilters;

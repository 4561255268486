// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import { withStyles } from "@mui/styles";
import { Box, Button, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { type DefaultTheme } from "../../themes";
import ChatBar from "../../icons/chatBar";

type Props = {
  Icon?: Element<*>,
  headerText: string,
  text: string,
  display?: string,
  classes?: *,
};

const NoData: StatelessFunctionalComponent<Props> = withStyles((theme: DefaultTheme): * => ({
  container: {
    marginTop: "20px",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
    height: "100%",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
    [theme.breakpoints.up("lg")]: {
      margin: "20px 0",
    },
  },
  chatbar: {
    width: "80px",
    marginBottom: "10px",
    [theme.breakpoints.up("md")]: {
      width: "160px",
      marginBottom: "20px",
    },
  },
  header: {
    color: theme.palette.text.main,
    ...theme.typography.h5,
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.bodyBold,
    },
    maxWidth: "560px",
    marginBottom: "5px",
    [theme.breakpoints.up("md")]: {
      marginBottom: "10px",
    },
  },
  text: {
    color: theme.palette.transparent.default,
    maxWidth: "560px",
    marginBottom: theme.spacing(2),
    ...theme.typography.body,
    fontSize: "12px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
    [theme.breakpoints.up("md")]: {
      marginBottom: theme.spacing(4),
    },
  },
  button: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
}))(({
  classes = {},
  Icon = <Box className={classes.chatbar}><ChatBar /></Box>,
  headerText = "Oops! Looks like you still have no profit with us.",
  text = "There are no statistics yet. Don’t worry, we have lots of great offers for you.",
  display,
}: Props): Element<*> => (
  <Box display={display || "flex"} className={classes.container}>
    {Icon}
    <Box component={Typography} className={classes.header}>
      {headerText}
    </Box>
    <Box component={Typography} className={classes.text}>
      {text}
    </Box>
    <Button component={Link} to="/links" variant="gradient" className={classes.button}>Grab your link</Button>
  </Box>
));

export default NoData;

// @flow
import { type DefaultTheme } from "../themes";

const howToChip: (DefaultTheme) => * = (theme) => ({
  root: {
    background: theme.palette.transparent.light,
    borderRadius: theme.shape.borderRadius,
    marginRight: theme.spacing(1),
    height: "44px",
    [theme.breakpoints.down("sm")]: {
      borderRadius: 0,
    },
  },
  label: {
    ...theme.typography.gradientBodyBold,
    padding: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.tableMobBold,
    },
  },
});

export default howToChip;

// @flow
import { type DefaultTheme } from "../themes";

const accordion: (DefaultTheme) => * = () => ({
  root: {
    margin: 0,
    "&.Mui-expanded": {
      margin: "10px 0 0",
    },
    "& .MuiAccordionSummary-root": {
      minHeight: "auto",
      padding: "0 10px",
    },
    "& .MuiFormControl-root.MuiFormControl-fullWidth": {
      marginBottom: 0,
    },
    "& .MuiAccordionSummary-content": {
      margin: 0,
      padding: "12px 0",
    },
    "&.Mui-expanded:first-of-type": {
      margin: 0,
    },
    "& .MuiAccordionSummary-content .MuiTypography-root": {
      fontWeight: 500,
    },
    "& .MuiAccordionDetails-root": {
      padding: "10px 10px 20px",
    },
  },
});

export default accordion;

// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Tax: () => Node = () => (
  <SvgIcon width="30" height="26" viewBox="0 0 30 26" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14.715 11C14.715 9.849 13.866 9 12.715 9C11.564 9 10.715 9.849 10.715 11C10.715 12.151 11.563 13 12.715 13C13.866 13 14.715 12.151 14.715 11ZM17 10V12H21V10H17ZM18 14V16H21V14H18ZM16.43 17H9C9 15.626 10.676 13.75 12.715 13.75C14.754 13.75 16.43 15.626 16.43 17ZM23 5H7C5.897 5 5 5.841 5 6.875V19.125C5 20.159 5.897 21 7 21H23C24.103 21 25 20.159 25 19.125V6.875C25 5.841 24.103 5 23 5ZM23 19L7 18.989V7L23 7.011V19Z" />
  </SvgIcon>
);

export default Tax;

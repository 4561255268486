// @flow
import isEqual from "lodash.isequal";

// eslint-disable-next-line import/prefer-default-export
export const isEqualPreviousBuilder: () => (*) => boolean = () => {
  let oldVal: *;
  return function isEqualPrev(currentVal: *): boolean {
    const isEqualValue: boolean = isEqual(currentVal, oldVal);
    oldVal = currentVal;
    return isEqualValue;
  };
};

// @flow
import React, { type StatelessFunctionalComponent, type Element } from "react";
import { withStyles } from "@mui/styles";
import { Box, Typography } from "@mui/material";
import { type DefaultTheme } from "../../themes";
import WarningIcon from "../../icons/WarningIcon";

type Props = {
  classes?: *,
};

const NoBestOffersData: StatelessFunctionalComponent<*> = withStyles((theme: DefaultTheme): * => ({
  container: {
    whiteSpace: "noWrap",
    background: theme.palette.transparent.light,
    borderRadius: theme.shape.borderRadius,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    "& svg": {
      marginRight: theme.spacing(1),
    },
  },
  header: {
    color: theme.palette.text.main,
    ...theme.typography.h5,
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.bodyBold,
    },
  },
}))(({ classes = {} }: Props): Element<*> => (
  <Box className={classes.container}>
    <Box display="flex" flexDirection="row" alignItems="center">
      <WarningIcon />
      <Typography className={classes.header}>
        Sorry, no offers for you yet
      </Typography>
    </Box>
  </Box>
));

export default NoBestOffersData;

// @flow
import React from "react";
import type { Node, StatelessFunctionalComponent } from "react";
import { Box, Typography } from "@mui/material";
import type { QuestionType } from "./types/Faq.types";

const FaqCard: StatelessFunctionalComponent<{ data: QuestionType }> = ({ data }): Node => (
  <Box pt={4} data-testid="question-block">
    <Typography variant="h5" data-testid="question">{data.question}</Typography>
    <Box sx={{ color: "transparent.default", py: 0.5 }} data-testid="answer-block">
      {typeof data.answer === "string" ? <Typography variant="body">{data.answer}</Typography> : data.answer}
    </Box>
    <Box display="none">
      <Typography variant="subtext" sx={{ color: "transparent.dark" }}>
        Keywords:
      </Typography>
      {data.keywords.map((text: string): Node => <Typography key={text} sx={{ pl: 1 }} variant="subtext" data-testid="keyword">{text}</Typography>)}
    </Box>
  </Box>
);

export default FaqCard;

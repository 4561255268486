// @flow
import { type DefaultTheme } from "../themes";

const cabinetAppBar: (DefaultTheme) => * = (theme) => ({
  root: {
    backgroundColor: theme.palette.background.default,
    position: "fixed",
    width: "100%",
    zIndex: theme.zIndex.drawer + 1,
    paddingLeft: theme.spacing(2),
    borderBottom: `1px solid ${theme.palette.transparent.light}`,
  },
});

export default cabinetAppBar;

// @flow
import { type DefaultTheme } from "../themes";

const setupCard: (DefaultTheme) => * = (theme) => ({
  card: {
    [theme.breakpoints.up("sm")]: {
      background: theme.palette.transparent.light,
      borderRadius: theme.shape.cardBorderRadius,
    },
    [theme.breakpoints.down("sm")]: {
      background: "transparent",
      borderRadius: 0,
      flex: 1,
    },
    height: "100%",
    maxHeight: "100%",
    boxShadow: "none",
    color: theme.palette.text.main,
    "& > div": {
      flexGrow: 1,
    },
    "& > div + div": {
      flexGrow: 1,
    },
    display: "flex",
    flexDirection: "column",
  },
  cardContent: {
    padding: theme.spacing(3),
    margin: 0,
    display: "flex",
    flexDirection: "column",
    overflow: "scroll",
    position: "relative",
    [theme.breakpoints.down("lg")]: {
      padding: theme.spacing(2),
    },
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
      overflow: "initial",
    },
    "& > div:first-of-type": {
      margin: 0,
      [theme.breakpoints.down("sm")]: {
        marginTop: theme.spacing(1),
      },
    },
  },
  title: {
    ...theme.typography.gradientH4,
  },
});

export default setupCard;

// @flow
import React, { type Node } from "react";

const Flag: () => Node = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <mask id="a" style={{ maskType: "alpha" }} maskUnits="userSpaceOnUse" x="0" y="2" width="16" height="12">
      <path d="M0 3.333C0 2.597.597 2 1.333 2h13.334C15.403 2 16 2.597 16 3.333v8.76c0 .737-.597 1.334-1.333 1.334H1.333A1.333 1.333 0 0 1 0 12.093v-8.76Z" fill="red" />
    </mask>
    <g mask="url(#a)">
      <path d="M14.857 2H1.143C.512 2 0 2.512 0 3.143v9.143c0 .63.512 1.143 1.143 1.143h13.714c.631 0 1.143-.512 1.143-1.143V3.143C16 2.512 15.488 2 14.857 2Z" fill="#fff" />
      <path d="M16 2H0v6.095h16V2Z" fill="#4588EF" />
      <path d="M16 8.095H0v5.333h16V8.095Z" fill="#FFD33A" />
    </g>
  </svg>
);

export default Flag;

// @flow
import Joi from "joi";

const schema: * = Joi.object().options({ allowUnknown: true })
  .messages({
    "number.base": "This field is required",
    "string.empty": "This field is required",
    "string.email": "Email is not valid",
  });

export default schema;

// @flow
/* eslint-disable import/max-dependencies */
import React, {
  type StatelessFunctionalComponent, type Element, useState, useEffect,
} from "react";
import {
  type Filters,
  type Sorting,
} from "@fas/cpa-state-manager/redux/actions/table/actions";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Grid,
  Box,
  Button,
  IconButton,
} from "@mui/material";
import { Close, ExpandMore } from "@mui/icons-material";
import { useTheme, withStyles } from "@mui/styles";
import { DateRangePickerField } from "@fas/cpa-cabinet-ui/lib/Table/SearchComponent";
import type { ComponentProps } from "@fas/cpa-cabinet-ui/lib/withState/withState.types";
import type { Dictionaries } from "@fas/cpa-state-manager/redux/reducers";
import type { UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import { withHookPropsState } from "@fas/cpa-cabinet-ui";
import { transactionReportColumnsMap as columnsMap } from "../Reports";
import { accordionStyles } from "../../styles";
import Checkbox, { type Props as CheckboxProps } from "../ConfigureMainReport/Checkbox";
import { maxDate, minDate, presets } from "../Reports/presets";
import DateInputComponent from "../ConfigureMainReport/DateInputComponent";
import DateRangeFooter from "../DateRangeFooter";
import type { Props as PropsSelect } from "../SelectPopup/SelectPopup";
import SelectPopup, { SelectPopupField } from "../SelectPopup/SelectPopup";
import { useDictionaryValueList } from "../../hooks/useDictionaryValueList";
import FieldMultiselect, { type Props as PropsTextFieldMultiselect } from "../TextFieldMultiselect";
import { useMemoryOptions, type UseMemoryOptionsHook } from "../../hooks/useMemoryOptions";
import { useConfigureAccordion } from "../../hooks/useConfigureAccordion";

type Props = {
  filters: Filters,
  fields: string[],
  onChangeFilters: (Filters) => mixed,
  onChangeSorting: (Sorting) => mixed,
  onChangeFields: (string[]) => mixed,
  onClose?: () => mixed,
  onApply?: () => mixed,
  onUpdatePosition?: () => mixed,
  open?: boolean,
};

const StyledAccordion: StatelessFunctionalComponent<*> = withStyles(accordionStyles)(
  withHookPropsState(Accordion, useConfigureAccordion)
);

const CountrySelect: StatelessFunctionalComponent<
  ComponentProps<PropsSelect, Dictionaries, UseDictionaryType>
  > = withHookPropsState(SelectPopup, useDictionaryValueList);

const TextFieldMultiselect: StatelessFunctionalComponent<
  ComponentProps<PropsTextFieldMultiselect, string, UseMemoryOptionsHook>
  > = withHookPropsState(FieldMultiselect, useMemoryOptions);

const Select: StatelessFunctionalComponent<*> = SelectPopupField;

const ConfigureTransactionReport: StatelessFunctionalComponent<Props> = ({
  filters,
  fields,
  onChangeFields,
  onChangeFilters,
  onClose,
  onApply,
  open,
  onUpdatePosition,
}) => {
  const theme: * = useTheme();
  const [pendingFilters, setPendingFilters]: * = useState(filters);
  const [pendingFields, setPendingFields]: * = useState(fields);
  function isDisableChecked(key) {
    return pendingFields.length <= 2 && pendingFields.includes(key);
  }

  const getCheckboxProps: (string) => CheckboxProps = (key) => ({
    checked: pendingFields.includes(key),
    label: columnsMap[key].label,
    disabled: isDisableChecked(key),
    onChange: (checked: boolean) => {
      if (checked) {
        setPendingFields([...pendingFields, key]);
      }
      else {
        setPendingFields(pendingFields.filter((field: string): boolean => field !== key));
      }
    },
  });

  const columnsKeys: string[] = Object.keys(columnsMap);

  const handleApply: * = () => {
    onChangeFilters(pendingFilters);
    onChangeFields(pendingFields);
    onApply && onApply();
    onClose && onClose();
  };
  useEffect((): * => () => {
    if (!open) { // save fields & filters on close modal
      onChangeFilters(pendingFilters);
      onChangeFields(pendingFields);
    }
  }, [open, pendingFilters, pendingFields]);

  return (
    <>
      <Box sx={(): * => ({
        zIndex: 1,
        position: "sticky",
        top: theme.spacing(-1),
        margin: "-10px -10px 0",
        padding: "2px 0 2px 30px",
        bgcolor: "background.light",
        display: { xs: "flex", sm: "none" },
        justifyContent: "space-between",
        alignItems: "center",
      })}
      >
        <Typography variant="h5">Filter</Typography>
        <IconButton data-testid="close-btn" onClick={onClose}>
          <Close />
        </IconButton>
      </Box>
      <Box sx={{ margin: { xs: "0 -10px", sm: 0 } }}>
        <StyledAccordion
          hookProps={{
            name: "dateRangeTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Date range">
            <Typography>Date range</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <DateRangePickerField
              applyOnClose
              applyOnSelect
              DateInputComponent={DateInputComponent}
              presets={presets}
              minDate={minDate}
              maxDate={maxDate}
              onChange={(dateFilters: *): * => setPendingFilters({
                ...pendingFilters,
                ...dateFilters,
              })}
              value={pendingFilters.date}
              filters={pendingFilters}
              filterKey="date"
              data-testid="date"
              readOnly={false}
              format="YYYY-MM-DD"
              mask="9999-99-99 - 9999-99-99"
              FooterComponent={DateRangeFooter}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "showColumnsTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Show columns">
            <Typography>Show columns</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container>
              <Grid item xs>
                {columnsKeys
                  .slice(0, Math.ceil(columnsKeys.length / 2))
                  .map((key: string): Element<*> => (<Checkbox key={key} {...getCheckboxProps(key)} />))}
              </Grid>
              <Grid item xs>
                {columnsKeys
                  .slice(Math.ceil(columnsKeys.length / 2), columnsKeys.length)
                  .map((key: string): Element<*> => (<Checkbox key={key} {...getCheckboxProps(key)} />))}
              </Grid>
            </Grid>
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "countryTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Country">
            <Typography>Country</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <CountrySelect
              value={pendingFilters.country}
              name="country"
              data-testid="country"
              onChange={(country: *): * => setPendingFilters({
                ...pendingFilters,
                country,
              })}
              hookProps="country"
              placeholder="Select country(s)"
              defaultValue={[]}
              isSplitListBoxColumn
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "subIdTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Subid">
            <Typography>Subid</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextFieldMultiselect
              hookProps="subIdTransaction"
              name="subId"
              placeholder="Input subId"
              placeholderInput="Input subId"
              value={pendingFilters.subId}
              onChange={(e: *): mixed => setPendingFilters({
                ...pendingFilters,
                subId: e,
              })}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "platformTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Platform">
            <Typography>Platform</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Select
              disableSearch
              isLoading={false}
              options={[{ title: "Web", value: "web" }, { title: "Mobile", value: "mobile" }]}
              value={pendingFilters.platform}
              name="platform"
              data-testid="platform"
              placeholder="Select platform"
              onChange={(platform: *): * => setPendingFilters({
                ...pendingFilters,
                platform,
              })}
              defaultValue=""
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "statusTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Status">
            <Typography>Status</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Select
              disableSearch
              isLoading={false}
              options={[{ title: "Approved", value: "Approved" }, { title: "Declined", value: "Declined" }]}
              value={pendingFilters.status}
              name="status"
              data-testid="status"
              placeholder="Select status"
              onChange={(status: *): * => setPendingFilters({
                ...pendingFilters,
                status,
              })}
              defaultValue=""
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "clickIdTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Clickid">
            <Typography>Clickid</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextFieldMultiselect
              hookProps="clickIdTransaction"
              name="clickId"
              placeholder="Input clickId"
              placeholderInput="Input clickId"
              value={pendingFilters.clickId}
              onChange={(e: *): mixed => setPendingFilters({
                ...pendingFilters,
                clickId: e,
              })}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "subId2Transaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Subid2">
            <Typography>Subid2</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextFieldMultiselect
              hookProps="subId2Transaction"
              name="subId2"
              placeholder="Input subId2"
              placeholderInput="Input subId2"
              value={pendingFilters.subId2}
              onChange={(e: *): mixed => setPendingFilters({
                ...pendingFilters,
                subId2: e,
              })}
            />
          </AccordionDetails>
        </StyledAccordion>
        <StyledAccordion
          hookProps={{
            name: "utm_termTransaction",
            onUpdatePosition,
          }}
        >
          <AccordionSummary expandIcon={<ExpandMore />} data-testid="Utm term">
            <Typography>Utm term</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <TextFieldMultiselect
              hookProps="utm_termTransaction"
              name="utm_term"
              placeholder="Input utm term"
              placeholderInput="Input utm term"
              value={pendingFilters.utm_term || ""}
              onChange={(e: *): mixed => setPendingFilters({
                ...pendingFilters,
                utm_term: e,
              })}
            />
          </AccordionDetails>
        </StyledAccordion>
      </Box>
      <Box sx={(): * => ({
        zIndex: 1,
        position: "sticky",
        bottom: theme.spacing(-1),
        marginTop: { xs: theme.spacing(4), sm: theme.spacing(2) },
        bgcolor: "background.default",
      })}
      >
        <Button disabled={pendingFields.length < 2} data-testid="apply-btn" variant="gradient" fullWidth onClick={handleApply}>
          Apply filters
        </Button>
      </Box>
    </>
  );
};

export default ConfigureTransactionReport;

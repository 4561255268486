// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Box, Container } from "@mui/material";

type Props = {
  children: *,
};

const AuthFormGrid: StatelessFunctionalComponent<Props> = ({ children }) => (
  <Container
    component="main"
    maxWidth="xs"
    sx={{
      position: "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      boxShadow: "none",
    }}
  >
    <Box display="flex" flexDirection="column" alignItems="center" p={2}>
      {children}
    </Box>
  </Container>
);

export default AuthFormGrid;

// @flow

import type { StatelessFunctionalComponent } from "react";
import { withStyles } from "@mui/styles";
import { Backdrop } from "@mui/material";
import type { DefaultTheme } from "../../themes";

// eslint-disable-next-line import/prefer-default-export
export const BackdropComponent: StatelessFunctionalComponent<*> = withStyles((theme: DefaultTheme): * => ({
  root: {
    backgroundColor: theme.palette.background.backdrop,
  },
}))(Backdrop);

/* eslint-disable import/max-dependencies */
// @flow
import environment from "environment";
import React, { type StatelessFunctionalComponent, type Node } from "react";
import { Form } from "@fas/ui-core";
import withForm, { type Props as FormProps } from "@fas/ui-framework/lib/services/form/withForm";
import {
  Button,
  Box,
} from "@mui/material";
import { withHookPropsState, withState } from "@fas/cpa-cabinet-ui";
import {
  EmailOutlined,
  MoreHoriz,
  Mediation,
  Language,
  ChatOutlined,
  AccountBoxOutlined,
  CategoryOutlined,
  LockOutlined,
} from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { withStyles } from "@mui/styles";
import type { ComponentProps } from "@fas/cpa-cabinet-ui/lib/withState/withState.types";
import type { Dictionaries } from "@fas/cpa-state-manager/redux/reducers";
import type { UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import { useDictionary } from "@fas/cpa-state-manager/redux/hooks";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { useDispatch } from "react-redux";
import { USER_INFO_FORM } from "../../constants";
import TextField, { type TextFieldFormProps } from "../TextFieldForm";
import { usePasswordField, type UsePasswordFieldHook } from "../../hooks";
import FileUploader from "../UploadPhoto";
import schema from "./validation";
import { getValidation } from "../../utils";
import type { Props as SelectProps } from "../SelectPopup/SelectPopup";
import { SelectPopupField } from "../SelectPopup/SelectPopup";
import ShowByValue from "./ShowByValue";
import Person from "../../icons/Person";
import Key from "../../icons/Key";
import Logout from "../../icons/Logout";
import Payments from "../../icons/Payments";
import ScreenSearchDesktop from "../../icons/ScreenSearchDesktop";

const UserInfoForm: StatelessFunctionalComponent<FormProps> = withForm(Form);
const PasswordField: StatelessFunctionalComponent<$Diff<TextFieldFormProps, UsePasswordFieldHook>> = withState(
  TextField,
  usePasswordField
);

const DictionarySelect: StatelessFunctionalComponent<
  ComponentProps<SelectProps, Dictionaries, UseDictionaryType>
> = withHookPropsState(SelectPopupField, useDictionary);

export type Props = {
  isLoading: boolean,
  disabled: boolean,
  onSave: () => mixed,
  onKeyPress: () => mixed,
};

const Layout: StatelessFunctionalComponent<*> = withStyles((theme: *): * => ({
  root: {
    maxWidth: "800px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      "& > div:nth-child(1n)": {
        width: `calc(50% - ${theme.spacing(2)})`,
      },
      "& > div:has([data-testid=\"email\"]), div:has([data-testid=\"natureOfTraffic\"]), div:has([data-testid=\"topCountries\"]), div:has([data-testid=\"revenue\"])": {
        width: "100%",
      },
      "& div > div:has([data-testid=\"howFoundOutComment\"]), div > div:has([data-testid=\"topVerticalsComment\"])": {
        marginLeft: theme.spacing(4),
      },
    },
    "& > hr": {
      width: "100%",
      border: 0,
      margin: 0,
    },

    "& > button": {
      display: "block",
      marginTop: theme.spacing(1),
      maxWidth: "210px",
    },
    [theme.breakpoints.down("sm")]: {
      "& > button": {
        maxWidth: "100%",
      },
    },
  },
}))(({ children, classes }: *): Node => <Box className={classes.root}>{children}</Box>);

// eslint-disable-next-line complexity
const UserInfo: StatelessFunctionalComponent<Props> = ({
  isLoading,
  disabled,
  onSave,
  onKeyPress,
}) => {
  const dispatch: <A>(A) => A = useDispatch();

  const onChangeTopVerticals: * = (topVerticals) => {
    dispatch(setFormData(USER_INFO_FORM, {
      topVerticals,
      topVerticalsComment: "",
    }));
  };

  const tooltipIconProps = {
    ...(disabled ? { EndIcon: <LockOutlined /> } : {}),
    slotProps: {
      tooltip: {
        title: disabled && "For any updates in this field, please contact your account manager.",
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Personal info</title>
      </Helmet>
      <UserInfoForm id={USER_INFO_FORM} onSave={onSave} validate={getValidation(schema)}>
        <Box>
          <Box data-testid="photo" pb={2} sx={{ textAlign: { xs: "center", sm: "left" } }}>
            <FileUploader name="photo" disabled={isLoading || disabled} />
          </Box>
          <Layout>
            <TextField
              {...tooltipIconProps}
              name="firstName"
              label="First Name"
              placeholder="What is your first name?"
              StartIcon={<Person />}
              onKeyPress={onKeyPress}
              disabled={isLoading || disabled}
            />
            <TextField
              {...tooltipIconProps}
              name="lastName"
              label="Last Name"
              placeholder="What is your last name?"
              StartIcon={<Person />}
              onKeyPress={onKeyPress}
              disabled={isLoading || disabled}
            />
            <TextField
              name="email"
              label="Email"
              placeholder="What is your email?"
              StartIcon={<EmailOutlined />}
              onKeyPress={onKeyPress}
              disabled
              EndIcon={<LockOutlined />}
              slotProps={{
                tooltip: {
                  title: "For any updates in this field, please contact your account manager.",
                },
              }}
            />
            <PasswordField
              {...tooltipIconProps}
              name="password"
              label="Password"
              placeholder="Create a strong password"
              StartIcon={<Key />}
              onKeyPress={onKeyPress}
              disabled={isLoading || disabled}
            />
            <PasswordField
              {...tooltipIconProps}
              name="confirmation"
              label="Confirmation Password"
              placeholder="Please enter your password again"
              StartIcon={<Key />}
              onKeyPress={onKeyPress}
              disabled={isLoading || disabled}
            />
            <DictionarySelect
              {...tooltipIconProps}
              disableSearch
              name="imType"
              label="Messenger"
              placeholder="Select your messenger"
              defaultValue=""
              hookProps="imHandle"
              StartIcon={<ChatOutlined />}
              disabled={isLoading || disabled}
            />
            <TextField
              {...tooltipIconProps}
              name="imName"
              label="Messenger ID"
              placeholder="What is your messenger ID?"
              StartIcon={<AccountBoxOutlined />}
              onKeyPress={onKeyPress}
              disabled={isLoading || disabled}
            />
            <Box sx={{ display: { md: "flex" } }} style={{ width: "100%" }}>
              <TextField
                name="howFoundOut"
                label="How did you get to know about us?"
                placeholder="How did you find out about AdsEmpire? Let us know"
                StartIcon={<ScreenSearchDesktop />}
                onKeyPress={onKeyPress}
                disabled
                EndIcon={<LockOutlined />}
                slotProps={{
                  tooltip: {
                    title: "For any updates in this field, please contact your account manager.",
                  },
                }}
              />
              <ShowByValue name="howFoundOut" compare={(v: string): boolean => ["Other", "Recommendation", "Webinar"].some((substr: string): boolean => String(v).includes(substr))}>
                <TextField
                  name="howFoundOutComment"
                  label="How did you get to know about us comment"
                  placeholder="Please specify"
                  StartIcon={<MoreHoriz />}
                  onKeyPress={onKeyPress}
                  disabled
                  EndIcon={<LockOutlined />}
                  slotProps={{
                    tooltip: {
                      title: "For any updates in this field, please contact your account manager.",
                    },
                  }}
                />
              </ShowByValue>
            </Box>
            <Box sx={{ display: { md: "flex" } }} style={{ width: "100%" }}>
              <DictionarySelect
                {...tooltipIconProps}
                disableSearch
                name="topVerticals"
                onChange={onChangeTopVerticals}
                label="Vertical"
                placeholder="What business vertical are you working with?"
                defaultValue=""
                hookProps="topVerticals"
                StartIcon={<CategoryOutlined />}
                disabled={isLoading || disabled}
              />
              <ShowByValue name="topVerticals" compare={(v: string): boolean => v === "Other"}>
                <TextField
                  {...tooltipIconProps}
                  name="topVerticalsComment"
                  label="Vertical comment"
                  placeholder="Please specify"
                  StartIcon={<MoreHoriz />}
                  onKeyPress={onKeyPress}
                  disabled={isLoading || disabled}
                />
              </ShowByValue>
            </Box>
            <DictionarySelect
              {...tooltipIconProps}
              disableSearch
              name="natureOfTraffic"
              label="Nature of traffic"
              placeholder="What natures of traffic are you working with?"
              defaultValue=""
              hookProps="natureOfTraffic"
              StartIcon={<Mediation />}
              disabled={isLoading || disabled}
            />
            <DictionarySelect
              {...tooltipIconProps}
              disableSearch
              defaultValue=""
              name="revenue"
              hookProps="revenue"
              label="Monthly Volumes"
              placeholder="What are your monthly volumes"
              StartIcon={<Payments />}
              onKeyPress={onKeyPress}
              disabled={isLoading || disabled}
            />
            <TextField
              {...tooltipIconProps}
              name="topCountries"
              label="Top GEOs"
              placeholder="What are the top GEOs you work with?"
              StartIcon={<Language />}
              onKeyPress={onKeyPress}
              disabled={isLoading || disabled}
            />
            <hr />
            <Button
              data-testid="save-user-info"
              fullWidth
              variant="gradient"
              type="submit"
              disabled={isLoading || disabled}
            >
              Update
            </Button>
          </Layout>
        </Box>
      </UserInfoForm>
      <Button
        variant="transparent"
        sx={{ mt: 2, display: { xs: "inline-flex", sm: "none" } }}
        size="large"
        color="inherit"
        component="a"
        href={environment.links.logout}
      >
        <Box component="span" sx={{ mr: 1, display: "inline-flex" }}>
          <Logout />
        </Box>
        Log out
      </Button>
    </>
  );
};

export default UserInfo;

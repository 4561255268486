// @flow

import type { Node } from "react";
import { useSelector } from "react-redux";
import { getTableTotalData, getTableTotalAmount } from "@fas/cpa-state-manager/services/selectors";
import type { StoreWithTable } from "@fas/cpa-state-manager/services/selectors";
import { Grid, Box, Typography } from "@mui/material";
import React from "react";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import { TRANSACTION_REPORT_TABLE } from "../../constants";
import { formatCurrency } from "../../utils";

function TotalTransaction({ classes }: *): Node {
  const {
    commission = 0, paid = 0,
  }: * = useSelector((state: *): * => getTableTotalData(state, TRANSACTION_REPORT_TABLE));

  const count: number = useSelector((
    state: StoreWithTable
  ): number => getTableTotalAmount(state, TRANSACTION_REPORT_TABLE));

  const isLoading: * = useSelector((state: StoreWithLoading): * => getLoadingState(state, TRANSACTION_REPORT_TABLE));
  if (isLoading || !count) return null;

  return (
    <Grid className={classes.container}>
      <Box className={classes.item}>
        <Typography variant="span" className={classes.label}>Summary</Typography>
        <Typography variant="span" className={classes.data} data-testid="total-commission" color="text.main">
          {formatCurrency("USD", commission)}
        </Typography>
      </Box>
      <Box className={classes.item}>
        <Typography variant="span" className={classes.label}>Paid</Typography>
        <Typography variant="span" className={classes.data} data-testid="total-paid" color="text.main">
          {formatCurrency("USD", paid)}
        </Typography>
      </Box>
    </Grid>
  );
}

export default TotalTransaction;

// @flow
import React, { type Node } from "react";

const ReportEmpty: () => Node = () => (
  <svg width="80" height="80" viewBox="0 0 80 80" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M36.6667 23.3333V56.6667H43.3333V23.3333H36.6667ZM50 33.3333V56.6667H56.6667V33.3333H50ZM23.3333 40V56.6667H30V40H23.3333ZM10 63.3333V16.6667C10 12.99 12.99 10 16.6667 10H63.3333C67.01 10 70 12.99 70 16.6667V63.3333C70 67.01 67.01 70 63.3333 70H16.6667C12.99 70 10 67.01 10 63.3333ZM16.6667 63.3333H63.3367L63.3333 16.6667H16.6667V63.3333Z" fill="white" fillOpacity="0.3" />
  </svg>
);

export default ReportEmpty;

// @flow
import React from "react";
import { CheckBoxOutlineBlank, CheckBoxOutlined } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import type { StatelessFunctionalComponent } from "react";

type Props = {
  hide: boolean,
  label: string,
  dataKey: string,
  onChange: (string, boolean) => mixed,
  style: {
    fill?: string,
  },
}

const LegendItem: StatelessFunctionalComponent<Props> = ({
  hide, label, dataKey, onChange, style,
}: Props) => (
  <FormControlLabel
    sx={{ marginLeft: "-5px", marginRight: { xs: "10px", md: "40px" } }}
    control={(
      <Checkbox
        size="small"
        sx={{ padding: 0.5, margin: "0 10px 0 0" }}
        checkedIcon={<CheckBoxOutlined style={style} />}
        icon={<CheckBoxOutlineBlank style={style} />}
        onChange={(): * => onChange(dataKey, !hide)}
        checked={!hide}
        data-testid={dataKey}
      />
    )}
    label={<Typography variant="responsiveButton" style={{ textTransform: "uppercase" }}>{label}</Typography>}
  />
);

export default LegendItem;

// @flow
import { type DefaultTheme } from "../themes";

const managerInfo: (DefaultTheme) => * = (theme) => ({
  layout: {
    padding: "20px",
    background: theme.palette.transparent.light,
    borderRadius: theme.shape.cardBorderRadius,
    width: "100%",
    [theme.breakpoints.only("sm")]: {
      background: "none",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "20px 10px",
    },
    [theme.breakpoints.down("sm")]: {
      padding: "10px",
    },
  },
  name: {
    color: theme.palette.text.main,
    ...theme.typography.bodyBold,
    whiteSpace: "break-spaces",
    overflowWrap: "anywhere",
    textAlign: "center",

    [theme.breakpoints.only("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("lg")]: {
      ...theme.typography.subtextBold,
    },
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.gradientSubtextBold,
    },
  },
  title: {
    color: theme.palette.transparent.default,
    ...theme.typography.subtext,

    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  avatar: {
    width: "60px",
    height: "60px",
    [theme.breakpoints.only("sm")]: {
      width: "40px",
      height: "40px",
    },
  },
  badge: {
    marginBottom: "10px",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.only("md")]: {
      display: "none",
    },
  },
  avatarLoading: {
    width: "60px",
    height: "60px",
    [theme.breakpoints.only("sm")]: {
      width: "40px",
      height: "40px",
    },
    [theme.breakpoints.only("md")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    marginBottom: "10px",
  },
  contactsLoading: {
    [theme.breakpoints.only("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "-4px",
    },
  },
  nameLoading: {
    [theme.breakpoints.only("sm")]: {
      display: "none",
    },
  },
  titleLoading: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  contacts: {
    "& .MuiIconButton-root": {
      padding: 0,
      margin: "10px 10px 0",
      [theme.breakpoints.down("sm")]: {
        marginTop: "8px",
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        padding: 0,
      },
    },
    [theme.breakpoints.only("sm")]: {
      display: "none",
    },
    "& svg": {
      [theme.breakpoints.down("sm")]: {
        width: 16,
        height: 16,
      },
    },
  },
});

export default managerInfo;

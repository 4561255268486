// @flow
import { type DefaultTheme } from "../themes";

const eventPostback: (DefaultTheme) => * = (theme) => {
  const gradientButton: * = theme.components.MuiButton.variants.find((v: *): boolean => v.props.variant === "gradient") || {};

  return ({
    labels: {
      marginBottom: 4,
    },
    container: {
      [theme.breakpoints.down("lg")]: {
        marginBottom: theme.spacing(4),
      },
      [theme.breakpoints.up("lg")]: {
        flexWrap: "noWrap",
      },
    },
    iconButton: {
      color: theme.palette.text.main,
      minWidth: 0,
      paddingTop: "12px",
      paddingBottom: "12px",
      marginRight: theme.spacing(1),
    },
    button: {
      ...gradientButton.style,
      color: theme.palette.text.main,
    },
    skeleton: {
    },
    errorHelper: {
      marginLeft: theme.spacing(0),
    },
  });
};

export default eventPostback;

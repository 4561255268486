// @flow
import {
  PROFILE_PAGE,
  USER_INFO_TAB,
  CONTACT_INFO_TAB,
  PAYMENT_METHODS_TAB,
} from "../../constants";
import { useParamTabs } from "..";

export type UseProfileHook = {
  param: string,
};

export const paramsToTabs: { [string]: string } = {
  user: USER_INFO_TAB,
  contact: CONTACT_INFO_TAB,
  payment: PAYMENT_METHODS_TAB,
};

export const useProfile: () => UseProfileHook = () => useParamTabs({
  paramKey: "page",
  pageKey: PROFILE_PAGE,
  paramsToTabs,
});

// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Key: () => Node = () => (
  <SvgIcon width="24" height="12" viewBox="3 6 26 12" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11 14C10.45 14 9.97933 13.804 9.588 13.412C9.196 13.0207 9 12.55 9 12C9 11.45 9.196 10.979 9.588 10.587C9.97933 10.1957 10.45 10 11 10C11.55 10 12.0207 10.1957 12.412 10.587C12.804 10.979 13 11.45 13 12C13 12.55 12.804 13.0207 12.412 13.412C12.0207 13.804 11.55 14 11 14ZM11 18C9.33333 18 7.91667 17.4167 6.75 16.25C5.58333 15.0833 5 13.6667 5 12C5 10.3333 5.58333 8.91667 6.75 7.75C7.91667 6.58333 9.33333 6 11 6C12.1167 6 13.1293 6.275 14.038 6.825C14.946 7.375 15.6667 8.1 16.2 9H25L28 12L23.5 16.5L21.5 15L19.5 16.5L17.375 15H16.2C15.6667 15.9 14.946 16.625 14.038 17.175C13.1293 17.725 12.1167 18 11 18ZM11 16C11.9333 16 12.7543 15.7167 13.463 15.15C14.171 14.5833 14.6417 13.8667 14.875 13H18L19.45 14.025L21.5 12.5L23.275 13.875L25.15 12L24.15 11H14.875C14.6417 10.1333 14.171 9.41667 13.463 8.85C12.7543 8.28333 11.9333 8 11 8C9.9 8 8.95833 8.39167 8.175 9.175C7.39167 9.95833 7 10.9 7 12C7 13.1 7.39167 14.0417 8.175 14.825C8.95833 15.6083 9.9 16 11 16Z" />
  </SvgIcon>
);

export default Key;

// @flow
import React from "react";
import type { StatelessFunctionalComponent, Node } from "react";
import { Box, MenuItem, Select } from "@mui/material";
import type { Option } from "@fas/cpa-cabinet-ui";
import LegendItem from "./LegendItem";
import { type LineInfoType } from "./types/Chart.types";
import LinearGradient from "../LinearGradient/LinearGradient";

type Props = {
  payload: LineInfoType[],
  onChangeLegend: (string, boolean) => mixed,
  period: string,
  disabled: boolean,
  periods: Option[],
  onChangePeriod: (string) => mixed,
}

const ChartLegend: StatelessFunctionalComponent<Props> = ({
  payload, onChangeLegend, period, periods, onChangePeriod, disabled,
}: Props) => (
  <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ marginTop: { xs: "10px", md: "20px" } }}>
    <Box>
      <svg height={0} width={0} style={{ position: "absolute" }}>
        <defs>
          {payload.map(({ dataKey, colors }: LineInfoType): Node => <LinearGradient key={dataKey} id={`legend-${dataKey}`} colors={colors} />)}
        </defs>
      </svg>
      {payload.map((item: LineInfoType): Node => (
        <LegendItem
          key={item.dataKey}
          hide={item.hide}
          dataKey={item.dataKey}
          label={item.labelLegend}
          onChange={onChangeLegend}
          style={{ fill: `url(#legend-${item.dataKey})` }}
        />
      ))}
    </Box>
    <Select
      variant="filled"
      disableUnderline
      data-testid="period-select"
      sx={{
        width: 160,
        lineHeight: 1.5,
        borderBottomLeftRadius: 4,
        borderBottomRightRadius: 4,
        "& .MuiSelect-select": {
          paddingTop: "13px",
          paddingBottom: "13px",
        },
      }}
      value={period}
      onChange={({ target: { value } }: *): mixed => onChangePeriod(value)}
      disabled={disabled}
    >
      {periods.map(({ title, value }: Option): Node => <MenuItem key={value} value={value}>{title}</MenuItem>)}
    </Select>
  </Box>
);

export default ChartLegend;

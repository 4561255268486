// @flow
/* eslint-disable import/max-dependencies */

import type { UseHandleRequestHook } from "@fas/cpa-state-manager/redux/hooks";
import { useHandleRequest, useTable } from "@fas/cpa-state-manager/redux/hooks";
import { useSelector } from "react-redux";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import {
  type Sorting,
} from "@fas/cpa-state-manager/redux/actions/table/actions";
import { getTableExportParams, getTableFields, getTableSorting } from "@fas/cpa-state-manager/services/selectors/table";
import type { Environment } from "@fas/cpa-state-manager/services/auth";
import { useEnvironment } from "@fas/cpa-state-manager/hooks";
import { fetchData, type TableResponse } from "../useTableFilters/useTableFilters";
import { mainReportColumnsMap, transactionReportColumnsMap } from "../../components/Reports";
import { MAIN_REPORT_TABLE, TRANSACTION_REPORT_TABLE } from "../../constants";
import { downloadCsv, getFileNameFromHeaders } from "../../utils";

export type UseExportReportHook = {
  disabled?: boolean,
  isLoading?: boolean,
  onClick: () => *,
};

export const useExportReport: (string) => UseExportReportHook = (param) => {
  const tableConfigMap: * = {
    main: {
      tableKey: MAIN_REPORT_TABLE,
      columnsMap: mainReportColumnsMap,
      exportMethod: "mainReportExport",
    },
    transaction: {
      tableKey: TRANSACTION_REPORT_TABLE,
      columnsMap: transactionReportColumnsMap,
      exportMethod: "transactionReportExport",
    },
  };
  const { tableKey = "", columnsMap = {}, exportMethod: method }: * = tableConfigMap[param] || {};

  const environment: Environment = useEnvironment();
  const { data }: * = useTable(tableKey);
  const isLoading: boolean = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, tableKey));
  const isLoadingExport: boolean = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, `${tableKey}-export`));
  const { filters = {} }: * = useSelector((state: *): * => getTableExportParams(state, tableKey));
  const sorting: Sorting = useSelector((state: *): Sorting => getTableSorting(state, tableKey));
  const selectedFields: string[] = useSelector((state: *): string[] => getTableFields(state, tableKey));

  const {
    changeLoading: changeLoadingExport,
    onFail: onFailExport,
    onFinally: onFinallyExport,
  }: UseHandleRequestHook = useHandleRequest(`${tableKey}-export`);

  const fields: string[] = Object.keys(columnsMap).filter((k: string): boolean => selectedFields.includes(k));

  const onClick: () => mixed = () => {
    changeLoadingExport(true);

    fetchData({
      filters,
      sorting,
      fields: fields.map((key: string): * => ({ fieldName: columnsMap[key].field, name: columnsMap[key].label })),
      path: environment.endpoints.post[method],
    })
      .then(({ data: csv, headers }: TableResponse<string>) => {
        const name: string = getFileNameFromHeaders(headers);
        downloadCsv(csv, name);
      })
      .catch(onFailExport)
      .finally(onFinallyExport);
  };

  return {
    disabled: isLoadingExport || isLoading || data.length === 0 || fields.length === 0,
    isLoading: isLoadingExport,
    onClick,
  };
};

// @flow
import React, { type Element } from "react";

const OutstandingBalance: ({ name: string }) => Element<*> = ({ name = "", ...props }) => (
  <svg {...props} height="40" viewBox="0 0 58 40" fill="none" xmlns="http://www.w3.org/2000/svg" data-testid="PaidIcon">
    <path
      d="M16.2921 20C16.2921 27.6762 20.0416 34.4717 25.7992 38.6354C23.8371 39.3005 21.7357 39.661 19.5506 39.661C8.75305 39.661 0 30.8585 0 20C0 9.14149 8.75305 0.338989 19.5506 0.338989C21.7357 0.338989 23.8371 0.699473 25.7992 1.3646C20.0416 5.52833 16.2921 12.3238 16.2921 20Z"
      fill={`url(#paint0_linear_3915_11887-${name})`}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M58 20C58 30.8585 49.247 39.661 38.4494 39.661C27.6519 39.661 18.8989 30.8585 18.8989 20C18.8989 9.14149 27.6519 0.338989 38.4494 0.338989C49.247 0.338989 58 9.14149 58 20ZM37.2232 32.452V29.966C36.1935 29.864 35.2755 29.5877 34.4689 29.1372C33.6624 28.6783 33.0318 28.0366 32.577 27.2122C32.1309 26.3877 31.9164 25.3678 31.9335 24.1524L31.9593 24.0886H35.1125C35.1125 24.8961 35.2454 25.5505 35.5114 26.052C35.786 26.545 36.1549 26.9062 36.6182 27.1357C37.0816 27.3566 37.605 27.4671 38.1884 27.4671C38.7976 27.4671 39.3167 27.3651 39.7457 27.1611C40.1748 26.9486 40.5008 26.6512 40.7238 26.2687C40.9555 25.8862 41.0713 25.4315 41.0713 24.9045C41.0713 24.4201 40.9684 23.9952 40.7625 23.6296C40.5651 23.2642 40.2433 22.9328 39.7972 22.6352C39.3596 22.3378 38.7762 22.0573 38.0468 21.7938C36.8285 21.3774 35.8118 20.9184 34.9967 20.417C34.1901 19.9154 33.5852 19.312 33.1819 18.6066C32.7873 17.9011 32.5899 17.0512 32.5899 16.0568C32.5899 14.6374 33.0275 13.4772 33.9027 12.5763C34.7778 11.6754 35.9705 11.1399 37.4806 10.9699V8.2034H39.5141V11.0081C40.9898 11.2206 42.1438 11.8369 42.9761 12.8568C43.8083 13.8767 44.2203 15.2196 44.2117 16.8855L44.1087 16.9237H41.007C41.007 15.8528 40.7711 15.0114 40.2991 14.3994C39.8358 13.7875 39.1966 13.4815 38.3815 13.4815C37.5235 13.4815 36.88 13.7152 36.4509 14.1827C36.0305 14.6416 35.8203 15.2621 35.8203 16.044C35.8203 16.5285 35.9147 16.9535 36.1035 17.3189C36.2922 17.6759 36.6097 17.9989 37.0559 18.2878C37.502 18.5768 38.1155 18.8616 38.8963 19.142C40.1147 19.584 41.1228 20.0599 41.9207 20.5699C42.7273 21.0799 43.3279 21.6791 43.7226 22.3676C44.1172 23.056 44.3146 23.8932 44.3146 24.8791C44.3146 26.341 43.8599 27.5138 42.9504 28.3978C42.0495 29.2732 40.8182 29.7917 39.2566 29.9532V32.452H37.2232Z"
      fill={`url(#paint1_linear_3915_11887-${name})`}
    />
    <defs>
      <linearGradient id={`paint0_linear_3915_11887-${name}`} x1="0" y1="20" x2="58" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
      <linearGradient id={`paint1_linear_3915_11887-${name}`} x1="0" y1="20" x2="58" y2="20" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
    </defs>
  </svg>
);

export default OutstandingBalance;

// @flow
import { type DefaultTheme } from "../themes";

const checkbox: (DefaultTheme) => * = () => ({
  root: {
    "&.Mui-checked > .MuiSvgIcon-root": {
      fill: "url(#selected)",
    },
    "&.Mui-checked.Mui-disabled > .MuiSvgIcon-root": {
      fill: "url(#selected-disabled)",
    },
  },
});

export default checkbox;

// @flow

import React, { type Node, type StatelessFunctionalComponent } from "react";
import {
  Box,
  Button,
  CircularProgress,
  Typography,
} from "@mui/material";
import Download from "../../icons/download";

type Props = {
  onClick: () => *,
  children: Node,
  Icon?: StatelessFunctionalComponent<*>,
  disabled?: boolean,
  isLoading?: boolean,
  visible?: boolean,
}

const AdditionalTabButton: StatelessFunctionalComponent<Props> = ({
  isLoading,
  onClick,
  children,
  disabled,
  Icon = Download,
  visible = true,
  ...props
}): Node => visible && (
  <Box
    mb={1}
    display="flex"
    flexDirection="row"
    alignItems="center"
    sx={(theme: *): * => ({
      borderBottom: `1px solid ${theme.palette.divider}`,
      [theme.breakpoints.down("lg")]: {
        borderBottom: "none",
        position: "absolute",
        top: "40px",
        right: "20px",
      },
      [theme.breakpoints.down("md")]: {
        top: "15px",
        right: "20px",
      },
      [theme.breakpoints.down("sm")]: {
        right: "10px",
      },
    })}
  >
    <Typography
      variant="bodyBold"
      noWrap
      sx={(theme: *): * => ({
        paddingBottom: { sm: "10px" },
        fontWeight: "700",
        [theme.breakpoints.down("lg")]: {
          fontSize: "12px",
        },
      })}
    >
      {children}
    </Typography>
    <Button
      {...props}
      disabled={disabled}
      onClick={onClick}
      sx={(theme: *): * => ({
        ml: 1,
        mb: 0.8,
        height: 50,
        width: 50,
        p: 0.5,
        [theme.breakpoints.down("lg")]: {
          height: 40,
          width: 40,
          minWidth: 40,
        },
        [theme.breakpoints.down("sm")]: {
          mb: 0,
        },
      })}
      variant="transparent"
      data-testid="export-btn"
    >
      { isLoading ? (
        <CircularProgress size={24} />
      ) : (
        <Icon />
      )}
    </Button>
  </Box>
);

export default AdditionalTabButton;

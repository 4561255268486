// @flow
import { useCallback, useState } from "react";

export type UseStorageHook<S> = [S, (S) => void];

function useLocalStorage<S>(key: string, initialValue: S): UseStorageHook<S> {
  return useStorage(window.localStorage, key, initialValue);
}

function useSessionStorage<S>(key: string, initialValue: S): UseStorageHook<S> {
  return useStorage(window.sessionStorage, key, initialValue);
}

function useStorage<S>(
  storage: { getItem: (string) => *, setItem: (string, *) => * },
  key: string,
  initialValue: S
): UseStorageHook<S> {
  const [storedValue, setStoredValue]: [S, ((S => S) | S) => void] = useState((): S => {
    try {
      const item: string = storage.getItem(key);
      return item ? JSON.parse(item) : initialValue;
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
      return initialValue;
    }
  });

  const setValue: (S) => void = useCallback((value: S) => {
    try {
      const valueToStore: S = value;
      setStoredValue(valueToStore);
      storage.setItem(key, JSON.stringify(valueToStore));
    }
    catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  }, [key]);
  return [storedValue, setValue];
}

export {
  useLocalStorage,
  useSessionStorage,
};

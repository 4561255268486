// @flow
import { type DefaultTheme } from "../themes";

const paginations: (DefaultTheme) => * = (theme) => ({
  root: {
    minWidth: "fit-content",
    margin: "0 auto",
    "& .MuiPaginationItem-root": {
      minWidth: "40px",
      padding: "12px 10px",
      lineHeight: "1",
      margin: "0px 5px",
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
        padding: "6px 5px",
        minWidth: "24px",
      },
      [theme.breakpoints.down("md")]: {
        minWidth: "40px",
        padding: "12px 10px",
        fontSize: "16px",
      },
      [theme.breakpoints.down("sm")]: {
        minWidth: "30px",
        padding: "9px 10px",
        fontSize: "14px",
      },
    },
  },
});

export default paginations;

// @flow

import { useDispatch, useSelector } from "react-redux";
import type { StoreWithDictionaries } from "@fas/cpa-state-manager/services/selectors";
import type { DropDownObjItemType, Option } from "@fas/cpa-state-manager/redux/reducers";
import { getDictionaryOption } from "@fas/cpa-state-manager/services/selectors";
import { useCallback } from "react";
import { setDictionary } from "@fas/cpa-state-manager/redux/actions";

export type UseMemoryOptionsHook = {|
  options: string[],
  setOptions: (string[]) => *,
|};

export const useMemoryOptions: (string) => UseMemoryOptionsHook = (nameKey) => {
  const dispatch: <A>(A) => A = useDispatch();

  const options: string[] = useSelector(
    (state: StoreWithDictionaries): Option[] => getDictionaryOption(state, nameKey)
  ).map(({ value }: Option): string => value);

  const setOptions: (string[]) => * = useCallback((data: string[]) => {
    dispatch(setDictionary({
      [nameKey]: data.map((value: string): DropDownObjItemType => ({ label: value, value })),
    }));
  }, [dispatch, nameKey]);

  return { options, setOptions };
};

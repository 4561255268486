// @flow
import { type DefaultTheme } from "../themes";

const navBarMenuComponentStyles: (DefaultTheme) => * = (theme) => ({
  listComponent: {
    paddingTop: 0,
    flex: 1,
    "& .Mui-selected": {
      background: theme.palette.background.default,
      borderLeft: `2px solid ${theme.palette.gradient.start}`,
      "& span": {
        ...theme.typography.button,
        [theme.breakpoints.down("lg")]: {
          ...theme.typography.buttonSmall,
        },
        color: theme.palette.text.main,
      },
      "&:hover": {
        background: theme.palette.background.default,
      },
      "& .MuiSvgIcon-root": {
        fill: "url(#selected)",
      },
    },
  },
  menuItem: {
    borderLeft: "2px solid transparent",
    height: "46px",
    color: theme.palette.transparent.default,
    paddingLeft: theme.spacing(2),
    paddingRight: 0,
    marginBottom: theme.spacing(1),
    [theme.breakpoints.between("md", "lg")]: {
      height: "36px",
    },
    "&:hover": {
      background: theme.palette.background.default,
    },
    "& svg": {
      color: theme.palette.transparent.default,
    },
    "& span": {
      ...theme.typography.button,
      [theme.breakpoints.down("lg")]: {
        ...theme.typography.buttonSmall,
      },
      [theme.breakpoints.down("md")]: {
        display: "none",
      },
    },
  },
});

export default navBarMenuComponentStyles;

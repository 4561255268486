// @flow
import React, { type Node, type StatelessFunctionalComponent, useState } from "react";
import {
  Autocomplete,
  autocompleteClasses,
  Box,
  Button,
  Checkbox,
  checkboxClasses,
  createFilterOptions,
  Input,
  InputAdornment,
  inputClasses,
  svgIconClasses,
  Typography,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import { makeStyles, withStyles } from "@mui/styles";
import { getTitleByValue } from "@fas/cpa-cabinet-ui/lib/Table/SearchComponent";
import type { Option } from "@fas/cpa-state-manager/redux/reducers";
import { mergeClasses } from "@fas/cpa-cabinet-ui/lib/utils";
import { formFieldInputStyles } from "../../styles";

const useStylesHideSearch: * = makeStyles((): * => ({
  root: {
    // instead display: none, for normal working anchorEl popup list box options
    height: 0,
    opacity: 0,
    padding: 0,
    pointerEvents: "none",
  },
}));

const useStyles: * = makeStyles((theme: *): * => ({
  root: {
    [`& .${inputClasses.root} .${inputClasses.input}`]: {
      padding: "13px 10px",
    },
  },
  popper: {
    position: "relative!important", // override mui popper style
    transform: "none!important", // override mui popper style
    width: "inherit!important",

    [`& .${autocompleteClasses.paper}`]: {
      boxShadow: "none",
      background: "none",
    },
    [`& .${autocompleteClasses.paper} .${autocompleteClasses.listbox}`]: {
      background: "none",
      padding: 0,
      maxHeight: "calc(40dvh)",
      minHeight: "80px",

      flexWrap: "wrap",
      display: "flex",

      [`& .${autocompleteClasses.option}:nth-child(odd)`]: {
        marginRight: ({ isSplitListBoxColumn }: *): * => (isSplitListBoxColumn ? theme.spacing(1) : 0),
      },
      [`& .${autocompleteClasses.option}`]: {
        width: ({ isSplitListBoxColumn }: *): * => (isSplitListBoxColumn ? `calc(50% - ${theme.spacing(0.5)})` : "100%"),
        wordBreak: "break-word",

        padding: 6,
        [`& .${checkboxClasses.root}`]: {
          display: ({ multiple }: *): * => (multiple ? "" : "none"),
          marginRight: 8,
          padding: 0,
        },

        "&[aria-selected=\"true\"]": {
          [`& .${checkboxClasses.root} .${svgIconClasses.root}`]: {
            fill: "url(#selected)",
          },
        },
      },
    },
  },
}));

const StyledInput: StatelessFunctionalComponent<*> = withStyles(formFieldInputStyles)(Input);

export type Props = {
  onClose?: () => *,
  value: *,
  label?: string,
  onChange: (*) => *,
  options: Option[],
  isLoading: boolean,
  disableCloseOnSelect?: boolean,
  isSplitListBoxColumn?: boolean,
  disableSearch?: boolean,
  classes?: *,
  defaultValue: string | string[],
  getOptionDisabled?: (*) => boolean,
}
const filterOptions: * = createFilterOptions({
  stringify: (option: Option): * => `${option.value} ${option.title}`,
  trim: true,
});

function toOption(value?: string, options: Option[]): Option {
  // $FlowFixMe options have param
  return ({ value, title: String(getTitleByValue(value, options)) });
}

export function toOptionValue(value?: string | string[], options: Option[]): Option[] | Option {
  return Array.isArray(value) ? value.map((v: string): Option => toOption(v, options)) : toOption(value, options);
}

const SelectBody: StatelessFunctionalComponent<Props> = ({
  onClose,
  value,
  onChange,
  options,
  isLoading,
  disableCloseOnSelect,
  disableSearch,
  defaultValue = "",
  label,
  classes: propClasses = {},
  isSplitListBoxColumn,
  getOptionDisabled,
}) => {
  const multiple: boolean = Array.isArray(value || defaultValue);
  const [searchValue, setSearchValue]: * = useState<string>("");

  const hideSearchClasses: { [string]: * } = useStylesHideSearch();
  const ownClasses: { [string]: * } = mergeClasses(useStyles({
    multiple,
    isSplitListBoxColumn,
  }), disableSearch ? hideSearchClasses : {});

  const classes: { [string]: * } = mergeClasses(ownClasses, propClasses);

  const defaultValueOption: Option[] | Option = toOptionValue(defaultValue, options);
  const valueOption: Option[] | Option = toOptionValue(value, options);
  const currentValue: Option[] | Option = value ? valueOption : defaultValueOption;

  return (
    <>
      <Box display="flex" justifyContent="space-between" className={classes.header} pb={1.5}>
        <Box>
          <Typography variant="h5">{label}</Typography>
          { multiple && <Typography variant="subtext" color="transparent.dark">You can choose several items</Typography> }
        </Box>
        <Box>
          <Button data-testid="clear-all" variant="gradientText" onClick={(): * => onChange(defaultValue)}>
            Clear all
          </Button>
        </Box>
      </Box>
      <Autocomplete
        classes={classes}
        className={classes.searchInput}
        sx={{ pb: 1.5 }}
        multiple={multiple}
        open
        disableCloseOnSelect
        filterOptions={filterOptions}
        loading={isLoading}
        onClose={(event: *, reason: *) => {
          if (reason === "escape") {
            onClose && onClose();
          }
        }}
        value={currentValue}
        onChange={(event: *, newValue: *, reason: *) => {
          if (reason === "clear" || (event.type === "keydown" && event.key === "Backspace" && reason === "removeOption")) {
            return;
          }
          onChange(Array.isArray(newValue) ? newValue.map((option: *): * => option.value) : newValue.value);
          if (disableCloseOnSelect === undefined ? !multiple : !disableCloseOnSelect) {
            onClose && onClose();
          }
          setSearchValue("");
        }}
        disablePortal
        renderTags={(): * => null}
        options={options}
        getOptionLabel={(option: *): string => (option && option.title) || ""}
        isOptionEqualToValue={(
          option: *, current: *
        ): * => current === option.value || (current && current.value === option.value)}
        getOptionDisabled={getOptionDisabled}
        inputValue={searchValue}
        onInputChange={(e: *, v: *, r: *): * => (r === "input" && setSearchValue(v))}
        renderOption={(props: *, option: *, { selected }: *): Node => (
          <li {...props}>
            <Checkbox
              disableRipple
              size="small"
              checked={selected}
            />
            {option.title}
          </li>
        )}
        renderInput={(params: *): * => (
          <StyledInput
            data-testid="search"
            fullWidth
            disableUnderline
            variant="outlined"
            margin="dense"
            ref={params.InputProps.ref}
            inputProps={params.inputProps}
            endAdornment={(
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            )}
            autoFocus={!disableSearch}
            placeholder="Search..."
          />
        )}
      />
    </>
  );
};

export default SelectBody;

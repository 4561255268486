// @flow
import { type DefaultTheme } from "../themes";

const formFieldGradientIconStyles: (DefaultTheme) => * = () => ({
  root: {
    color: "inherit",
    "& .MuiSvgIcon-root": {
      fill: "url(#selected)",
    },
  },
});

export const gradientIconStyles: (DefaultTheme) => * = () => ({
  root: {
    color: "inherit",
    fill: "url(#selected)",
  },
});

export default formFieldGradientIconStyles;

// @flow
import { type DefaultTheme } from "../themes";

const paymentHistory: (DefaultTheme) => * = (theme) => ({
  exportContainer: {
    position: "absolute",
    top: "40px",
    right: "30px",
    "& .MuiTypography-root": {
      fontWeight: "700",
      [theme.breakpoints.down("lg")]: {
        fontSize: "12px",
      },
    },
    [theme.breakpoints.down("lg")]: {
      top: "20px",
      right: "20px",
    },
    [theme.breakpoints.down("md")]: {
      top: "15px",
      right: "20px",
    },
    [theme.breakpoints.down("sm")]: {
      right: "10px",
    },
  },
  exportButton: {
    marginLeft: theme.spacing(1),
    padding: "13px",
    height: 50,
    width: 50,
    p: 0.5,
    [theme.breakpoints.down("lg")]: {
      height: 40,
      width: 40,
      minWidth: 40,
    },
    [theme.breakpoints.down("sm")]: {
      mb: 0,
    },
  },
  filtersContainer: {
    margin: `${theme.spacing(1)} 0`,
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
      "& .MuiCoreUi-wrapperDatePicker": {
        width: "100%",
      },
    },
  },
  applyFiltersBtn: {
    marginLeft: "auto",
    whiteSpace: "noWrap",
    minWidth: "fit-content",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      marginTop: theme.spacing(1),
    },
  },
});

export default paymentHistory;

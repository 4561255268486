// @flow

import { type DefaultTheme } from "../themes";

const reportTable: (DefaultTheme) => * = (theme) => ({
  table: {
    minWidth: "fit-content",
    borderCollapse: "separate",
    borderSpacing: "0",
    [theme.breakpoints.down("md")]: {
      borderCollapse: "collapse",
      display: "grid",
      gridTemplateColumns: "min-content min-content min-content",
      gridTemplateRows: "auto auto auto",
      gridTemplateAreas: `"caption caption caption"
       "head total body"`,

      "&[data-empty=\"true\"] > thead": {
        display: "none",
      },
      "& thead": {
        zIndex: 1,
        gridArea: "head",
        position: "sticky",
        left: 0,
        backgroundColor: theme.palette.background.default,
      },
    },
    [theme.breakpoints.down("lg")]: {
      paddingBottom: "10px",
    },
  },
  tableBody: {
    [theme.breakpoints.down("md")]: {
      display: "flex",
      gridArea: "body",
    },
  },
  row: {

    "&.MuiTableRow-hover:hover": {
      backgroundColor: theme.palette.transparent.main,
    },
    [theme.breakpoints.up("sm")]: {
      "&:nth-child(even)": {
        backgroundColor: theme.palette.transparent.light,
      },
    },
    [theme.breakpoints.up("md")]: {
      "& td:first-child": {
        borderTopLeftRadius: theme.shape.borderRadius,
        borderBottomLeftRadius: theme.shape.borderRadius,
      },
      "& td:last-child": {
        borderTopRightRadius: theme.shape.borderRadius,
        borderBottomRightRadius: theme.shape.borderRadius,
      },
    },
  },
  cell: {
    border: "none",
    textAlign: "center",
    whiteSpace: "nowrap",
    padding: "6px 10px",
    fontSize: "14px",
    fontWeight: "400",
    lineHeight: "16px",

    [theme.breakpoints.down("lg")]: {
      padding: "1px 10px",
      fontSize: "11px",
      lineHeight: "18px",
    },
    [theme.breakpoints.down("md")]: {
      display: "block",
      border: `1px solid ${theme.palette.background.light}`,
      padding: "10px",
      minHeight: "40px",
      ...theme.typography.subtext,
    },
    [theme.breakpoints.down("sm")]: {
      display: "block",
      border: `1px solid ${theme.palette.background.default}`,
      background: theme.palette.background.light,
      padding: "2px 3px",
      fontSize: "9px",
      lineHeight: "18px",
      minHeight: "24px",
    },
  },
  headerRow: {
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  headerCell: {
    color: theme.palette.transparent.default,
    zIndex: 1,
    padding: "0 10px",
    fontSize: "16px",
    fontWeight: "500",

    [theme.breakpoints.down("lg")]: {
      ...theme.typography.subtextBold,
    },

    [theme.breakpoints.down("md")]: {
      display: "block",
      border: `1px solid ${theme.palette.background.light}`,
      background: theme.palette.background.light,
      top: "inherit",
      padding: "7px 7px 7px 0",
      minHeight: "40px",
      ...theme.typography.bodyBold,
    },

    [theme.breakpoints.down("sm")]: {
      display: "block",
      border: `1px solid ${theme.palette.background.default}`,
      background: theme.palette.background.default,
      top: "inherit",
      padding: "2px 3px",
      fontSize: "9px",
      lineHeight: "18px",
      minHeight: "24px",
    },
    [theme.breakpoints.up("md")]: {
      top: 0,
      background: theme.palette.background.light,
      position: "sticky",
      border: "none",
      textAlign: "center",
    },
    "& > div": {
      display: "flex",
      justifyContent: "center",
      whiteSpace: "nowrap",
      [theme.breakpoints.down("md")]: {
        justifyContent: "left",
      },
      [theme.breakpoints.down("sm")]: {
        justifyContent: "left",
        fontSize: "9px",
        "& .MuiSvgIcon-root": {
          fontSize: "18px",
        },
      },
    },
    "& .MuiTableSortLabel-icon": {
      marginRight: "-10px",
    },
  },
  tableContainer: {
    [theme.breakpoints.down("md")]: {
      minHeight: "auto",
    },
    minHeight: "60px",
    marginTop: theme.spacing(1),
    marginBottom: "10px",
  },
});

export default reportTable;

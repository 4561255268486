// @flow
import dayjs from "dayjs";
import { Chip } from "@mui/material";
import React from "react";
import DateRangeDateCellDefault from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangeDateCell";
import DateRangePresetsDefault from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePresets";
import { withStyles } from "@mui/styles";
import DialogComponent from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangeDialog";
import { maxDate, minDate, presets } from "../Reports/presets";
import DateRangeFooter from "../DateRangeFooter/DateRangeFooter";

const DateRangePicker: * = withStyles({})(DialogComponent);

const filterComponentMap = {
  date: {
    EditComponent: ({ onClose, onChange, value }: *): * => {
      const changeDates: * = ({ startDate: start, endDate: end }: { startDate: *, endDate: *}) => {
        onChange({
          date: {
            from: dayjs(start).format("YYYY-MM-DD"),
            to: dayjs(end).format("YYYY-MM-DD"),
          },
        });
        onClose();
      };

      return (
        <DateRangePicker
          applyOnClose
          applyOnSelect
          startDate={value.from}
          endDate={value.to}
          onChange={changeDates}
          onChangePreset={changeDates}
          onClose={onClose}
          currentPreset=""
          presets={presets}
          minDate={minDate}
          maxDate={maxDate}
          CellComponent={DateRangeDateCellDefault}
          PresetsComponent={DateRangePresetsDefault}
          FooterComponent={DateRangeFooter}
        />
      );
    },
    RenderComponent: ({
      filterKey, filters, WrapChipLabel, ...props
    }: *) => (
      <Chip
        {...props}
        onDelete={(): * => null}
        label={(
          <WrapChipLabel>
            { filters[filterKey].from }
            {" "}
            -
            {" "}
            { filters[filterKey].to }
          </WrapChipLabel>
        )}
      />
    ),
  },
};

export default filterComponentMap;

// @flow
/* eslint-disable import/max-dependencies */
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import type { Group, List } from "@fas/cpa-cabinet-ui/lib/SmartlinkGenerator/SmartlinkGenerator.types";
import { type LinkGeneratorProps, useLinkGenerator } from "@fas/cpa-state-manager/redux/hooks/useLinkGenerator";
import {
  setGeneratedLinks,
  setSelectedValues,
} from "@fas/cpa-state-manager/redux/actions/linkGenerator/actions";
import { addNotification } from "@fas/cpa-state-manager/redux/actions/notifications";
import type { UseHandleRequestHook } from "@fas/cpa-state-manager/redux/hooks";
import { useHandleRequest, useIsAffiliate } from "@fas/cpa-state-manager/redux/hooks";
import type { UseStateType } from "@fas/ui-core/lib/SearchComponentsV2/helpers/types";
import type { AppState } from "@fas/cpa-state-manager/redux/reducers/linkGenerator/reducer";
import { getGeneratedLinks } from "@fas/cpa-state-manager/services/selectors";
import {
  generateSmartOffer,
  smartOfferCheck,
} from "../../services/deprecatedApi";
import type { SmartOfferType } from "../../components/SmartlinkList/types/SmartlinkList.types";

export type UseSmartlinkGeneratorHook = {
  title?: string,
  groups: Array<Group>,
  optionsList: List,
  values: {
    [string]: string,
  },
  handleChange: (string, string) => *,
  handleGenerateClick: (SyntheticMouseEvent<HTMLButtonElement>) => void,
  handleClearClick: (SyntheticMouseEvent<HTMLButtonElement>) => void,
};

export const useSmartlinkGenerator: () => UseSmartlinkGeneratorHook = () => {
  const {
    isLoading,
    changeLoading,
    onFail,
    onFinally,
  }: UseHandleRequestHook = useHandleRequest("smartlinkGenerator");
  const isAffiliate: boolean = useIsAffiliate();
  const linkGeneratorProps: LinkGeneratorProps = useLinkGenerator();
  const dispatch: <A>(A) => A = useDispatch();
  const [isAwailableGenerateSmartlink, setIsAwailableGenerateSmartlink]: UseStateType<boolean> = useState(false);
  const list: SmartOfferType[] = useSelector((state: AppState): SmartOfferType[] => getGeneratedLinks(state));

  function runCheckSmartOffer() {
    changeLoading(true);
    smartOfferCheck(linkGeneratorProps.selectedValues)
      .then((v: boolean): void => setIsAwailableGenerateSmartlink(v))
      .catch(onFail)
      .finally(onFinally);
  }

  useEffect(() => {
    runCheckSmartOffer();
  }, [linkGeneratorProps.selectedValues]);

  const handleClearClick: () => * = () => {
    dispatch(setSelectedValues({
      isTraffic: "0",
      isStraight: "1",
      isMainstream: "0",
      isPreLander: "1",
      isPPL: "1",
    }));
  };

  const handleGenerateClick: () => void = () => {
    changeLoading(true);
    generateSmartOffer(linkGeneratorProps.selectedValues)
      .then((offer: SmartOfferType): * => {
        dispatch(setGeneratedLinks([offer, ...list]));
        dispatch(addNotification({ severity: "success", message: "Link was successfully generated" }));
        setIsAwailableGenerateSmartlink(false);
      })
      .catch(onFail)
      .finally(onFinally);
  };

  return {
    groups: linkGeneratorProps.groups,
    optionsList: linkGeneratorProps.optionsList,
    values: linkGeneratorProps.selectedValues,
    handleChange: linkGeneratorProps.onChangeLinkOption,
    disabled: isLoading || !isAffiliate,
    generateButtonProps: {
      variant: "gradient",
      disabled: isLoading || !isAffiliate || !isAwailableGenerateSmartlink,
    },
    clearButtonProps: {
      variant: "transparent",
    },
    handleClearClick,
    handleGenerateClick,
  };
};

// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Payment: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M18 13.5C18 12.1193 16.8807 11 15.5 11C14.1193 11 13 12.1193 13 13.5C13 14.8807 14.1193 16 15.5 16C16.8807 16 18 14.8807 18 13.5ZM13 11.512C12.581 11.194 12.065 11 11.5 11C10.119 11 9 12.119 9 13.5C9 14.881 10.119 16 11.5 16C12.065 16 12.581 15.806 13 15.488C12.396 15.032 12 14.315 12 13.5C12 12.685 12.396 11.968 13 11.512ZM4 4C2.897 4 2 4.897 2 6V18C2 19.103 2.897 20 4 20H20C21.103 20 22 19.103 22 18V6C22 4.897 21.103 4 20 4H4ZM4 6H20L20.002 18H4V6Z" />
  </SvgIcon>
);

export default Payment;

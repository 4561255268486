// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Button } from "@mui/material";
import { Refresh } from "@mui/icons-material";

type Props = {
  disabled?: boolean,
  disableApply?: boolean,
  onApply?: () => *,
};

const ApplyButton: StatelessFunctionalComponent<Props> = ({
  disabled, disableApply, onApply, ...props
}) => (
  <Button
    {...props}
    disabled={disabled || disableApply}
    onClick={onApply}
    sx={{
      mr: 1,
      height: { sm: "40px", lg: "50px" },
      minWidth: { sm: "40px", lg: "50px" },
      padding: { sm: "7px 10px", lg: "10px 16px" },
    }}
    variant="transparent"
    data-testid="apply-btn"
  >
    <Refresh />
  </Button>
);

export default ApplyButton;

// @flow
import {
  POSTBACKS_PAGE,
  DEFAULT_POSTBACK_TAB,
  ADDITIONAL_POSTBACKS_TAB,
  INFO_POSTBACKS_TAB,
} from "../../constants";
import { useParamTabs } from "..";

export type UsePostbacksHook = {
  param: string,
};

export const paramsToTabs: { [string]: string, } = {
  defaulPostback: DEFAULT_POSTBACK_TAB,
  additionalPostback: ADDITIONAL_POSTBACKS_TAB,
  infoPostback: INFO_POSTBACKS_TAB,
};

export const usePostbacks: () => UsePostbacksHook = () => useParamTabs({
  paramKey: "type",
  pageKey: POSTBACKS_PAGE,
  paramsToTabs,
});

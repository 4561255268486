// @flow
import { type DefaultTheme } from "../themes";

const uploadPhoto: (DefaultTheme) => * = (theme) => {
  const transparentButton: * = theme.components.MuiButton.variants.find((v: *): boolean => v.props.variant === "transparent") || {};
  return ({
    button: {
      ...transparentButton.style,
      color: theme.palette.text.main,
      padding: "12px 30px",

      [theme.breakpoints.down("sm")]: {
        width: "100%",
        marginLeft: 0,
        marginTop: theme.spacing(2),
      },
    },
    badgeContentIcon: {
      background: "red",
      color: "black",
    },
  });
};

export default uploadPhoto;

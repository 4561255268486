// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Smartlink: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M5 3C3.897 3 3 3.897 3 5V19C3 20.103 3.897 21 5 21H13C13.131 21 13.26 20.974 13.381 20.924C13.502 20.874 13.613 20.801 13.707 20.707L20.707 13.707C20.793 13.621 20.854 13.52 20.903 13.414C20.917 13.384 20.925 13.353 20.936 13.321C20.964 13.237 20.982 13.151 20.987 13.062C20.989 13.041 21 13.021 21 13V5C21 3.897 20.103 3 19 3H5ZM19 5V12H13C12.447 12 12 12.448 12 13V19H5V5H19ZM14 14H17.586L14 17.586V14Z" />
  </SvgIcon>
);

export default Smartlink;

/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Form } from "@fas/ui-core";
import withForm, { type Props as FormProps } from "@fas/ui-framework/lib/services/form/withForm";
import { Typography, Button } from "@mui/material";
import { AUTOLOGIN_FORM } from "@fas/cpa-state-manager/redux/constants";
import { EmailOutlined } from "@mui/icons-material";
import { Helmet } from "react-helmet";
import TextField from "../TextFieldForm";
import AuthFormGrid from "../AuthFormGrid";
import schema from "./validation";
import { getValidation } from "../../utils";

const AutologinForm: StatelessFunctionalComponent<FormProps> = withForm(Form);

const typographySx: * = { mb: 4, textAlign: "center", minWidth: { sm: "max-content" } };
const inputSx: * = {};
const btnSx: * = { mb: 1 };

type Props = {
  isLoading: boolean,
  onKeyPress: (SyntheticKeyboardEvent<*>) => mixed,
  onSave: () => mixed,
};

const Autologin: StatelessFunctionalComponent<Props> = ({
  isLoading,
  onKeyPress,
  onSave,
}) => (
  <>
    <Helmet>
      <title>Login</title>
    </Helmet>
    <AutologinForm id={AUTOLOGIN_FORM} validate={getValidation(schema)} onSave={onSave}>
      <AuthFormGrid>
        <Typography variant="gradientH1" sx={typographySx}>Log In</Typography>
        <TextField
          name="email"
          label="Your Email"
          placeholder="Input your email"
          autoFocus
          StartIcon={<EmailOutlined />}
          onKeyPress={onKeyPress}
          sx={inputSx}
          inputProps={{ inputMode: "email" }}
        />
        <Button
          sx={btnSx}
          disabled={isLoading}
          data-testid="autologin-save"
          fullWidth
          variant="gradient"
          type="submit"
        >
          Log in
        </Button>
      </AuthFormGrid>
    </AutologinForm>
  </>
);

export default Autologin;

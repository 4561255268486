// @flow
import React, { type StatelessFunctionalComponent } from "react";
import {
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { withStyles } from "@mui/styles";
import checkbox from "../../styles/checkbox";

const StyledCheckbox: StatelessFunctionalComponent<*> = withStyles(checkbox)(Checkbox);

export type Props = {|
  checked: boolean,
  disabled?: boolean,
  label: string,
  onChange: (boolean) => mixed,
|};

const CheckBox: StatelessFunctionalComponent<Props> = ({
  checked, label, onChange, disabled,
}) => (
  <FormGroup>
    <FormControlLabel
      disabled={disabled}
      control={(
        <StyledCheckbox
          checked={checked}
          onChange={(e: SyntheticInputEvent<HTMLInputElement>): mixed => onChange(e.target.checked)}
          data-testid={`${label}-checkbox`}
        />
      )}
      label={label}
    />
  </FormGroup>
);

export default CheckBox;

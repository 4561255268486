// @flow
import { useTabs as useTabsBase, type UseTabsHook as BaseUseTabsHook } from "@fas/cpa-state-manager/redux/hooks/useTabs";
import { useNavigate, type ContextRouter } from "react-router-dom";
import { useDispatch } from "react-redux";
import environment from "environment";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import {
  USER_INFO_TAB,
  CONTACT_INFO_TAB,
  PAYMENT_METHODS_TAB,
  MAIN_REPORT_TAB,
  TRANSACTION_REPORT_TAB,
  DEFAULT_POSTBACK_TAB,
  ADDITIONAL_POSTBACKS_TAB,
  INFO_POSTBACKS_TAB,
} from "../../constants";

export type UseTabsArgs = { key: string, };

export type UseTabsHook = {
  ...BaseUseTabsHook,
  onSwitchTab: (string) => mixed,
};

export const tabsToParams: { [string]: string } = {
  [USER_INFO_TAB]: environment.links.profile.replace(":page", "user"),
  [CONTACT_INFO_TAB]: environment.links.profile.replace(":page", "contact"),
  [PAYMENT_METHODS_TAB]: environment.links.profile.replace(":page", "payment"),
  [MAIN_REPORT_TAB]: environment.links.reports.replace(":type", "main"),
  [TRANSACTION_REPORT_TAB]: environment.links.reports.replace(":type", "transaction"),
  [DEFAULT_POSTBACK_TAB]: environment.links.postbacks.replace(":type", "defaulPostback"),
  [ADDITIONAL_POSTBACKS_TAB]: environment.links.postbacks.replace(":type", "additionalPostback"),
  [INFO_POSTBACKS_TAB]: environment.links.postbacks.replace(":type", "infoPostback"),
};

export const useTabs: (UseTabsArgs) => UseTabsHook = ({ key }) => {
  const dispatch: <A>(A) => A = useDispatch();

  const { onSwitchTab: switchTab, ...rest }: BaseUseTabsHook = useTabsBase(key);
  const navigate: $PropertyType<ContextRouter, "history"> = useNavigate();

  const onSwitchTab: (string) => mixed = (current) => {
    switchTab(current);
    dispatch(setErrors({}));
    navigate(tabsToParams[current]);
  };

  return { ...rest, onSwitchTab };
};

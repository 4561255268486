// @flow
/* eslint-disable import/max-dependencies */
import React, { type Node, type StatelessFunctionalComponent } from "react";
import { Masonry } from "@mui/lab";
import { withStyles } from "@mui/styles";
import { SmartlinkCard, withHookPropsState } from "@fas/cpa-cabinet-ui";
import { useDispatch } from "react-redux";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { Box } from "@mui/material";
import managerSmartlinkCard from "../../styles/managerSmartlinkCard";
import affiliateSmartlinkCard from "../../styles/affiliateSmartlinkCard";
import type { SmartOfferType } from "./types/SmartlinkList.types";
import LoadingLinks from "./LoadingLinks";

export function useCopy(defaultText: string): (currentText?: string) => void {
  const dispatch: <A>(A) => A = useDispatch();
  return (currentText?: string) => {
    navigator.clipboard.writeText(typeof currentText === "string" ? currentText : defaultText).then((): * => dispatch(addNotification({ severity: "success", message: "Copied successfully" })));
  };
}

const ManagerSmartlinkCard: StatelessFunctionalComponent<{}> = withHookPropsState(
  withStyles(managerSmartlinkCard)(SmartlinkCard),
  ({ link, disabled }: { link: string, disabled: boolean }): * => ({ copyButtonProps: { disabled, variant: "gradient", onClick: useCopy(link) } })
);
const AffiliateSmartlinkCard: StatelessFunctionalComponent<{}> = withHookPropsState(
  withStyles(affiliateSmartlinkCard)(SmartlinkCard),
  ({ link, disabled }: { link: string, disabled: boolean }): * => ({ copyButtonProps: { disabled, variant: "gradientContrast", onClick: useCopy(link) } })
);

type Props = { isLoading: boolean, disabled: boolean, list: SmartOfferType[] }

function SmartlinkList({ isLoading, list, disabled }: Props): Node {
  if (!isLoading && list.length === 0) return null;
  return (
    <Box sx={{ marginRight: -2 }}>
      <Masonry
        spacing={2}
        columns={{
          xs: 1, sm: 1, md: 2, lg: 1, xl: 2,
        }}
      >
        {isLoading ? (
          <LoadingLinks />
        ) : (
          list.map((obj: SmartOfferType): Node => (obj.options.length ? (
            <AffiliateSmartlinkCard {...obj} key={obj.link} hookProps={{ link: obj.link, disabled }} />
          ) : (
            <ManagerSmartlinkCard {...obj} key={obj.link} hookProps={{ link: obj.link, disabled }} />
          )))
        )}
      </Masonry>
    </Box>
  );
}

export default SmartlinkList;

// @flow
import React, { useState, type Element } from "react";
import { IconButton } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { withStyles } from "@mui/styles";
import { gradientIconStyles } from "../../styles/formFieldGradientIcon";

type UseState<T> = [T, (((T) => T) | T) => void];

export type UsePasswordFieldHook = {
  type?: string,
  EndIcon?: ?Element<*>,
};

const StyledVisibility = withStyles(gradientIconStyles)(Visibility);
const StyledVisibilityOff = withStyles(gradientIconStyles)(VisibilityOff);

export const usePasswordField: () => UsePasswordFieldHook = () => {
  const [type, setType]: UseState<string> = useState("password");

  const onClick: () => mixed = () => setType((prevType: string): string => (prevType === "password" ? "text" : "password"));
  const onMouseDown: (SyntheticEvent<*>) => mixed = (e) => e.preventDefault();

  const EndIcon: Element<*> = (
    <IconButton data-testid="end-icon" onClick={onClick} onMouseDown={onMouseDown}>
      {type === "password"
        ? <StyledVisibility data-testid="visibility-on-icon" />
        : <StyledVisibilityOff data-testid="visibility-off-icon" />}
    </IconButton>
  );

  return { type, EndIcon };
};

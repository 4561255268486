// @flow
/* eslint-disable import/max-dependencies */
import {
  getTableFilters,
  getTableSorting,
  getTablePage,
  getTablePageSize,
  getTablePendingFields,
} from "@fas/cpa-state-manager/services/selectors/table";
import {
  changeTableFilters,
} from "@fas/cpa-state-manager/redux/actions/table";
import { useSelector, useDispatch } from "react-redux";
import { useHandleRequest, type UseHandleRequestHook } from "@fas/cpa-state-manager/redux/hooks";
import { type Response, type PromiseResponse } from "@fas/ui-framework/lib/services/request";
import { getRequestService } from "@fas/cpa-state-manager/services/request";
import {
  type Filters,
  type Sorting,
} from "@fas/cpa-state-manager/redux/actions/table/actions";
import { useEffect } from "react";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import ToolTip from "../../components/Reports/Tooltip";
import { isEqualPreviousBuilder } from "./utils";
import { MAP_API_TIMEOUT } from "../../constants";

type FetchDataArgs = {|
  filters: Filters,
  sorting: Sorting,
  page?: number,
  limit?: number,
  fields: $ReadOnlyArray<string | {fieldName: string, name: string}>,
  path: string,
|};

export type TableResponse<T> = {
  data: T,
  headers: *,
};

export type UseTableFiltersArgs = {|
  tableKey: string,
|};

export type UseTableFiltersHook = {
  isLoading?: boolean,
  disableApply?: boolean,
  filters: *,
  onChange: (*) => mixed,
  onApply: () => mixed,
  onDeleteAll: () => mixed,
  onDelete: (*) => mixed,
};

export function fetchData<T>({
  filters,
  sorting,
  page,
  limit,
  fields,
  path,
}: FetchDataArgs): PromiseResponse<TableResponse<T>> {
  const {
    // $FlowFixMe
    date: { from, to } = {},
    groupBy,
    ...restFilters
  }: Filters = filters;

  const [[current, direction] = []]: * = Object.entries(sorting);

  return getRequestService().post(path,
    {
      filters: Object
        .keys(restFilters)
        .filter((key: string): boolean => (
          Array.isArray(filters[key]) ? Boolean(filters[key].length) : Boolean(filters[key])))
        .reduce((acc: *, key: string): * => ({
          ...acc,
          [key]: typeof filters[key] === "string" ? [filters[key].trim()] : filters[key],
        }), {
          date: [{ from, to }],
        }),
      group: groupBy ? [groupBy] : undefined,
      sorting: current ? [{ current, direction }] : undefined,
      fields,
      page,
      limit,
    }, { timeout: MAP_API_TIMEOUT })
    .then(({ data, headers }: Response<TableResponse<*>>): TableResponse<*> => ({
      data, headers,
    }));
}

const tablesCompareCache: { [string]: (*) => boolean } = {};

export function isEqualPrevious(tableKey: string, data: *): boolean {
  tablesCompareCache[tableKey] = tablesCompareCache[tableKey] || isEqualPreviousBuilder();
  return tablesCompareCache[tableKey](data);
}

const defaultSorting: Sorting = { "": "asc" };
export function getAllowedSorting(sorting: Sorting, fields: string[]): Sorting {
  const [[current = ""] = []]: * = Object.entries(sorting);
  return fields.includes(current) ? sorting : defaultSorting;
}

export const useApplyFilters: (UseTableFiltersArgs) => () => mixed = ({ tableKey }) => {
  const dispatch: <A>(A) => A = useDispatch();
  return () => dispatch({ type: tableKey });
};

function getClearFilters(tableFilters: Filters): Filters {
  const canClear: (string) => boolean = (key: string): boolean => !["date", "groupBy"].includes(key);
  return Object.keys(tableFilters).reduce((acc: Filters, key: string): Filters => ({
    ...acc,
    [key]: canClear(key) ? "" : tableFilters[key],
  }), {});
}

export const useTableFilters: (UseTableFiltersArgs) => UseTableFiltersHook = ({ tableKey }) => {
  const dispatch: <A>(A) => A = useDispatch();

  const {
    isLoading,
  }: UseHandleRequestHook = useHandleRequest(tableKey);

  const isLoadingExport: boolean = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, `${tableKey}-export`));
  const tableFilters: Filters = useSelector((state: *): Filters => getTableFilters(state, tableKey));
  const tableSorting: Sorting = useSelector((state: *): Sorting => getTableSorting(state, tableKey));
  const page: number = useSelector((state: *): number => getTablePage(state, tableKey));
  const limit: number = useSelector((state: *): number => getTablePageSize(state, tableKey));
  const pendingFields: string[] = useSelector((state: *): string[] => getTablePendingFields(state, tableKey));

  const filters = Object.keys(tableFilters)
    .filter((key) => !["groupBy"].includes(key) && isValidFilter(tableFilters[key]))
    .reduce((acc, key) => ({ ...acc, [key]: tableFilters[key] }), {});

  const onApply: * = useApplyFilters({ tableKey });

  useEffect(() => {
    if (!isEqualPrevious(tableKey, { tableSorting, page, limit })) {
      onApply();
    }
  }, [tableKey, tableSorting, page, limit]);

  function isValidFilter(filter): boolean {
    return Array.isArray(filter) ? !!filter.length : !!filter;
  }

  return {
    isLoading: isLoading || isLoadingExport,
    disableApply: pendingFields.length < 2,
    filters,
    onApply,
    onChange: (newFilters) => {
      dispatch(changeTableFilters(tableKey, {
        ...tableFilters,
        ...newFilters,
      }));
      onApply();
    },
    onDeleteAll: () => {
      dispatch(changeTableFilters(tableKey, getClearFilters(tableFilters)));
    },
    onDelete: ({ date, ...newFilters }) => {
      dispatch(changeTableFilters(tableKey, {
        ...tableFilters,
        ...newFilters,
        ...(date ? { date } : {}), // don't clear require filter
      }));
    },
    WrapChipLabel: ToolTip,
  };
};

// @flow

import { useSelector } from "react-redux";
import type { StoreWithTable } from "@fas/cpa-state-manager/services/selectors";
import { getTableData } from "@fas/cpa-state-manager/services/selectors";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";

export type UseTableNoDataHook = {
  data: *[],
  isLoading: boolean,
  isFetched?: boolean,
};

export const useTableNoData: ({ tableKey: string }) => UseTableNoDataHook = ({ tableKey }) => {
  const data: * = useSelector((state: StoreWithTable): * => getTableData(state, tableKey));
  const isLoading: boolean = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, tableKey));
  const isFetched: * = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, `${tableKey}-fetched`));
  return {
    data,
    isLoading,
    isFetched,
  };
};

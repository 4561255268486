// @flow
import { type DefaultTheme } from "../themes";

const paginationSelect: (DefaultTheme) => * = (theme) => ({
  select: {
    height: "40px",
    padding: "8px 20px",
    [theme.breakpoints.down("lg")]: {
      height: "24px",
      padding: "3px 10px",
      fontSize: "12px",
    },
  },

});

export default paginationSelect;

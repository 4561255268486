// @flow
import { useSelector, useDispatch } from "react-redux";
import { getTablePendingFields } from "@fas/cpa-state-manager/services/selectors/table";
import { setTablePendingFields } from "@fas/cpa-state-manager/redux/actions/table";
import { useTable, type UseTableHook } from "@fas/cpa-state-manager/redux/hooks";
import {
  changeTableSorting,
  type Filters,
  type Sorting,
} from "@fas/cpa-state-manager/redux/actions/table/actions";
import { useApplyFilters } from "../useTableFilters/useTableFilters";

export type UseConfigureReportHookArgs = {|
  tableKey: string,
|};

export type UseConfigureReportHook = {
  filters: Filters,
  fields: string[],
  onChangeFilters: (Filters) => mixed,
  onChangeSorting: (Sorting) => mixed,
  onChangeFields: (string[]) => mixed,
  onApply?: () => mixed,
};

export const useConfigureReport: (UseConfigureReportHookArgs) => UseConfigureReportHook = ({ tableKey }) => {
  const dispatch: <A>(A) => A = useDispatch();
  const { filters, onChangeFilters }: UseTableHook<*> = useTable(tableKey);
  const fields: string[] = useSelector((state: *): string[] => getTablePendingFields(state, tableKey));

  const onChangeFields: (string[]) => mixed = (newFields) => dispatch(setTablePendingFields(tableKey, newFields));
  const onChangeSorting: (Sorting) => mixed = (newSorting) => dispatch(changeTableSorting(tableKey, newSorting));
  const onApply: * = useApplyFilters({ tableKey });

  return {
    filters,
    fields,
    onChangeFilters,
    onChangeFields,
    onChangeSorting,
    onApply,
  };
};

// @flow
/* eslint-disable import/max-dependencies */
import React, { type StatelessFunctionalComponent } from "react";
import { withHookPropsState } from "@fas/cpa-cabinet-ui";
import type { Column } from "@fas/cpa-cabinet-ui/lib/Table/Table.types";
import dayjs from "dayjs";
import DateRangeDateCellDefault from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangeDateCell";
import DateRangePresetsDefault from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePresets";
import { withStyles } from "@mui/styles";
import DialogComponent from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangeDialog";
import type { Dictionaries } from "@fas/cpa-state-manager/redux/reducers";
import { type ComponentProps } from "@fas/cpa-cabinet-ui/lib/withState/withState.types";
import { type UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import DateRangeFooter from "../DateRangeFooter";
import { maxDate, minDate, presets } from "./presets";
import SelectBody from "../SelectPopup/SelectBody";
import type { Props } from "../SelectPopup/SelectBody";
import { useDictionaryValueList } from "../../hooks/useDictionaryValueList";
import { SelectPopupField } from "../SelectPopup/SelectPopup";
import TextFieldMultiselectBody from "../TextFieldMultiselect/TextFieldMultiselectBody";
import type { Props as PropsBody } from "../TextFieldMultiselect/TextFieldMultiselectBody";
import type { UseMemoryOptionsHook } from "../../hooks/useMemoryOptions";
import { useMemoryOptions } from "../../hooks/useMemoryOptions";

const StyledTextFieldMultiselectBody: StatelessFunctionalComponent<
  ComponentProps<PropsBody, string, UseMemoryOptionsHook>
  > = withHookPropsState(withStyles({ popper: { maxWidth: "360px" } })(TextFieldMultiselectBody), useMemoryOptions);

const DateRangePicker: * = withStyles({})(DialogComponent);
const CountrySelect: StatelessFunctionalComponent<
  ComponentProps<Props, Dictionaries, UseDictionaryType>
> = withHookPropsState(SelectBody, useDictionaryValueList);
const Select: StatelessFunctionalComponent<*> = SelectPopupField;

const formControlSx: * = { "& > .MuiFormLabel-root > .MuiTypography-root": { fontWeight: 700, lineHeight: "30px", fontSize: "18px" } };

// eslint-disable-next-line import/prefer-default-export
export const transactionReportColumnsMap: { [string]: Column<*> } = {
  date: {
    field: "date",
    label: "Date",
    searchable: true,
    sortable: true,
    // $FlowFixMe onClose
    SearchComponent: ({ onClose, onChange, value }: *): * => {
      const changeDates: * = ({ startDate: start, endDate: end }: { startDate: *, endDate: *}) => {
        onChange({
          date: {
            from: dayjs(start).format("YYYY-MM-DD"),
            to: dayjs(end).format("YYYY-MM-DD"),
          },
        });
        onClose();
      };

      return (
        <DateRangePicker
          applyOnClose
          applyOnSelect
          startDate={value.from}
          endDate={value.to}
          onChange={changeDates}
          onChangePreset={changeDates}
          onClose={onClose}
          currentPreset=""
          presets={presets}
          minDate={minDate}
          maxDate={maxDate}
          CellComponent={DateRangeDateCellDefault}
          PresetsComponent={DateRangePresetsDefault}
          FooterComponent={DateRangeFooter}
        />
      );
    },
  },
  conversion_time: {
    label: "Conversion time",
    field: "conversion_time",
  },
  commission: {
    label: "Commission $",
    field: "commission",
  },
  country: {
    field: "country",
    label: "Country",
    searchable: true,
    sortable: true,
    SearchComponent: ({ ...props }: *): * => (
      <CountrySelect
        {...props}
        label="Country"
        hookProps="country"
        onChange={(country: string | string[]): * => props.onChange({ country })}
        defaultValue={[]}
        isSplitListBoxColumn
      />
    ),
  },
  subId: {
    label: "Subid",
    field: "subId",
    searchable: true,
    sortable: true,
    SearchComponent: ({ value, onChange }: *): * => (
      <StyledTextFieldMultiselectBody
        hookProps="subIdTransaction"
        value={value}
        label="Subid"
        placeholder="Input subId"
        name="subId"
        onChange={(subId: string | string[]): * => onChange({ subId })}
      />
    ),
  },
  platform: {
    label: "Platform",
    field: "platform",
    sortable: true,
    searchable: true,
    SearchComponent: ({ ...props }: *): * => (
      <Select
        {...props}
        disableSearch
        isLoading={false}
        formControlSx={formControlSx}
        options={[{ title: "Web", value: "web" }, { title: "Mobile", value: "mobile" }]}
        name="platform"
        data-testid="search-platform"
        placeholder="Select platform"
        onChange={(platform: string | string[]): * => props.onChange({ platform })}
        defaultValue=""
      />
    ),
  },
  status: {
    label: "Status",
    field: "status",
    sortable: true,
    searchable: true,
    SearchComponent: ({ ...props }: *): * => (
      <Select
        {...props}
        disableSearch
        isLoading={false}
        formControlSx={formControlSx}
        options={[{ title: "Approved", value: "Approved" }, { title: "Declined", value: "Declined" }]}
        name="status"
        placeholder="Select status"
        onChange={(status: string | string[]): * => props.onChange({ status })}
        defaultValue=""
      />
    ),
  },
  decline_reason: {
    label: "Decline reason",
    field: "decline_reason",
    sortable: true,
  },
  clickId: {
    label: "Clickid",
    field: "clickId",
    searchable: true,
    SearchComponent: ({ value, onChange }: *): * => (
      <StyledTextFieldMultiselectBody
        hookProps="clickIdTransaction"
        value={value}
        label="Clickid"
        placeholder="Input clickid"
        name="clickId"
        onChange={(clickId: string | string[]): * => onChange({ clickId })}
      />
    ),
  },
  subId2: {
    label: "Subid2",
    field: "subId2",
    searchable: true,
    SearchComponent: ({ value, onChange }: *): * => (
      <StyledTextFieldMultiselectBody
        hookProps="subId2Transaction"
        value={value}
        label="Subid2"
        placeholder="Input subid2"
        name="subId2"
        onChange={(subId2: string | string[]): * => onChange({ subId2 })}
      />
    ),
  },
  smartlink_name: {
    label: "Smartlink name",
    field: "smartlink_name",
    sortable: true,
  },
  ip: {
    label: "IP",
    field: "ip",
    sortable: true,
  },
  device_type: {
    label: "Device type",
    field: "device_type",
    sortable: true,
  },
  device_brand: {
    label: "Device brand",
    field: "device_brand",
    sortable: true,
  },
  device_os: {
    label: "Device OS",
    field: "device_os",
    sortable: true,
  },
  browser: {
    label: "Browser",
    field: "browser",
    sortable: true,
  },
  connection_type: {
    label: "Connection type",
    field: "connection_type",
    sortable: true,
  },
  utm_term: {
    label: "Utm term",
    field: "utm_term",
    sortable: true,
    searchable: true,
    render: ({ utm_term: utmTerm }: *): * => (utmTerm === null ? "" : utmTerm),
    SearchComponent: ({ value, onChange }: *): * => (
      <StyledTextFieldMultiselectBody
        hookProps="utm_termTransaction"
        value={value}
        label="Utm term"
        placeholder="Input utm term"
        name="utm_term"
        onChange={(utmTerm: string | string[]): * => onChange({ utm_term: utmTerm })}
      />
    ),
  },
  pixel: {
    label: "Pixel",
    field: "pixel",
    sortable: true,
  },
};

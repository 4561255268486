// @flow
import { type DefaultTheme } from "../themes";

const outstandingBalance: (DefaultTheme) => * = (theme) => ({
  layout: {
    marginBottom: theme.spacing(1),
    "& p": {
      width: "100%",
      background: `linear-gradient(to right, ${theme.palette.gradient.start}, ${theme.palette.gradient.stop})`,
      padding: theme.spacing(1),
      borderTopLeftRadius: theme.shape.cardBorderRadius,
      borderTopRightRadius: theme.shape.cardBorderRadius,
      textAlign: "center",
    },
    "& p + div": {
      width: "100%",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      background: theme.palette.transparent.light,
      borderBottomLeftRadius: theme.shape.cardBorderRadius,
      borderBottomRightRadius: theme.shape.cardBorderRadius,
      "& svg": {
        [theme.breakpoints.between("md", "lg")]: {
          maxHeight: 28,
        },
        [theme.breakpoints.only("sm")]: {
          maxHeight: 24,
        },
        [theme.breakpoints.down("sm")]: {
          maxHeight: 20,
        },
      },

      [theme.breakpoints.only("sm")]: {
        padding: 0,
        background: "none",
        flexDirection: "column",
      },
    },
    "& div": {
      width: "100%",
    },
  },
  comment: {
    ...theme.typography.h5,
    [theme.breakpoints.down("lg")]: {
      ...theme.typography.subtextBold,
    },
    [theme.breakpoints.only("sm")]: {
      display: "none",
    },
  },
  currency: {
    ...theme.typography.h5,
    [theme.breakpoints.between("md", "lg")]: {
      ...theme.typography.subtextBold,
    },
    [theme.breakpoints.only("sm")]: {
      fontWeight: 500,
      fontSize: "10px",
      lineHeight: "18px",
      color: theme.palette.transparent.default,
      marginTop: "4px",
    },
    [theme.breakpoints.down("sm")]: {
      ...theme.typography.bodyBold,
    },
    "&[data-disabled=\"true\"]": {
      color: theme.palette.transparent.disabled,
    },
  },
});

export default outstandingBalance;

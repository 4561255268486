// @flow
import { useRef } from "react";
import useResizeObserver from "@react-hook/resize-observer";
import { useSessionStorage } from "../useStorage";

export type UseConfigureAccordionHook = {
  onChange: () => void,
  expanded: boolean,
  ref: {current: Element | null},
};

type Props = {
  name: string,
  onUpdatePosition?: () => void,
}

export function useConfigureAccordion({
  name,
  onUpdatePosition,
}: Props): UseConfigureAccordionHook {
  const ref = useRef<Element | null>(null);

  useResizeObserver(ref, (): * => onUpdatePosition && onUpdatePosition());

  const [open, setOpen] = useSessionStorage<boolean>(name, false);
  return {
    ref,
    expanded: open,
    onChange: (): * => setOpen(!open),
  };
}

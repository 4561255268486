// @flow
import Joi from "joi";

const schema: * = Joi.object({
  login: Joi.string().required().email({ tlds: false }),
  password: Joi.string().required(),
})
  .messages({
    "string.empty": "This field is required",
    "string.email": "Email is not valid",
  });

export default schema;

/* eslint-disable import/max-dependencies */
// @flow
import React, { type Node, type StatelessFunctionalComponent } from "react";
import {
  type CabinetRouterProps,
  ManagerInfo,
  withState,
  type ManagerInfoProps,
} from "@fas/cpa-cabinet-ui";
import { type UseAuthHook, useLogout } from "@fas/cpa-state-manager/hooks";
import { Navigate, Outlet } from "react-router-dom";
import {
  Box, Divider, Skeleton, Typography,
} from "@mui/material";
import { Provider } from "react-redux";
import NotificationsComponent from "@fas/cpa-cabinet-ui/lib/Notifications";
import withNotifications from "@fas/cpa-state-manager/services/notifications";
import type { UseManagerInfoHook } from "@fas/cpa-state-manager/redux/hooks";
import { useManagerInfo } from "@fas/cpa-state-manager/redux/hooks";
import { withStyles } from "@mui/styles";
import CabinetAppBar from "../../components/CabinetAppBar";
import CabinetNavBar from "../../components/NavBar";
import CabinetNavBarMob from "../../components/NavBar/CabinetNavBarMob";
import configureStore, { type CabinetStore } from "./store";
import ProfilePage from "../../components/Profile";
import PostbacksPage from "../../components/Postbacks";
import ReportsPage from "../../components/Reports";
import PaymentHistoryPage from "../../components/PaymentHistory";
import {
  useOutstandingBalance, usePostbacks, useProfile, useReports,
} from "../../hooks";
import DashboardPage from "../../components/Dashboard";
import Smartlinks from "../../components/Smartlinks";
import { APP_BAR_HEIGHT, APP_BOTTOM_BAR_HEIGHT } from "./types/Cabinet.types";
import FaqPage from "../../components/Faq";
import NoPaymentDetailsModalComponent from "../../components/NoPaymentDetailsModal";
import { useNoPaymentDetailsModal } from "../../hooks/useNoPaymentDetailsModal";
import NatureOfTrafficModalComponent from "../../components/NatureOfTrafficModal";
import { useNatureOfTrafficModal } from "../../hooks/useNatureOfTrafficModal";
import { NO_PAYMENT_DETAILS_MODAL, NATURE_OF_TRAFFIC_MODAL } from "../../constants";
import { useModalSwitch } from "../../hooks/useModalSwitch";
import { formatCurrency, RenderMediaQueryComponent } from "../../utils";
import OutstandingBalanceIcon from "../../icons/OutstandingBalanceIcon";
import { managerInfoStyles } from "../../styles";

// $FlowFixMe
const Notifications: StatelessFunctionalComponent<{}> = withNotifications(NotificationsComponent);

const Dashboard: StatelessFunctionalComponent<{}> = () => <DashboardPage />;
const Links: StatelessFunctionalComponent<{}> = () => <Smartlinks />;
const Profile: StatelessFunctionalComponent<{}> = withState(ProfilePage, useProfile);
const Reports: StatelessFunctionalComponent<{}> = withState(ReportsPage, useReports);
const NoPaymentDetailsModal: StatelessFunctionalComponent<{}> = withState(
  NoPaymentDetailsModalComponent,
  useNoPaymentDetailsModal
);
const Postbacks: StatelessFunctionalComponent<{}> = withState(PostbacksPage, usePostbacks);
const Faq: StatelessFunctionalComponent<{}> = () => <FaqPage />;
const PaymentHistory: StatelessFunctionalComponent<{}> = () => <PaymentHistoryPage />;
const Logout: StatelessFunctionalComponent<{}> = withState((props: *): Node => <Navigate {...props} />, useLogout);
const NatureOfTrafficModal: StatelessFunctionalComponent<{}> = withState(
  NatureOfTrafficModalComponent,
  useNatureOfTrafficModal
);

export const CabinetComponents: $Diff<CabinetRouterProps, UseAuthHook> = {
  Dashboard,
  Links,
  Postbacks,
  Profile,
  Reports,
  PaymentHistory,
  Faq,
  Logout,
};

const StyledManagerInfo: StatelessFunctionalComponent<ManagerInfoProps> = withStyles(managerInfoStyles)(ManagerInfo);

const Manager: StatelessFunctionalComponent<$Diff<ManagerInfoProps, UseManagerInfoHook>> = withState(
  StyledManagerInfo,
  useManagerInfo
);
const OutstandingBalance: StatelessFunctionalComponent<*> = withState(({ balance, isLoading, disabled }) => (
  <Box
    sx={{
      p: 1, borderRadius: 3.75, display: "flex", flexDirection: "column", alignItems: "center", backgroundColor: "transparent.light",
    }}
    data-testid="outstanding_balance_info-container"
  >
    <Box display="flex" alignItems="center">
      <OutstandingBalanceIcon name="dashboard" style={{ height: 16, paddingRight: 5 }} />
      <Typography data-testid="title" variant="gradientSubtextBold">Balance</Typography>
    </Box>
    <Box width="100%" display="flex" justifyContent="center" pt={0.6} pb={0.2}>
      {isLoading ? (
        <Skeleton height={18} width="50%" />
      ) : (
        <Typography
          data-testid="balance"
          variant="subtextBold"
          sx={{
            color: disabled ? "transparent.disabled" : "inherit",
          }}
        >
          {formatCurrency("USD", balance)}
        </Typography>
      )}
    </Box>
  </Box>
), useOutstandingBalance);

const ModalSwitch: StatelessFunctionalComponent<{}> = withState(
  ({ children, name }: *): * => (
    <>
      {React.Children.toArray(children).find((child: *): * => child.props.name === name)}
    </>
  ),
  useModalSwitch
);

const store: CabinetStore = configureStore();

const CabinetLayout: StatelessFunctionalComponent<{}> = () => (
  <Provider store={store}>
    <Notifications />
    <ModalSwitch>
      <NatureOfTrafficModal name={NATURE_OF_TRAFFIC_MODAL} />
      <NoPaymentDetailsModal name={NO_PAYMENT_DETAILS_MODAL} />
    </ModalSwitch>
    <Box>
      <CabinetAppBar />
    </Box>
    <Box
      sx={{
        height: { sx: "inherit", sm: "100%" },
        display: "flex",
        flexDirection: { xs: "column", sm: "row" },
      }}
    >
      <RenderMediaQueryComponent mediaKey="sm" method="up">
        <CabinetNavBar />
      </RenderMediaQueryComponent>
      <Box
        p={{ xs: 0, sm: 2 }}
        pl={{ sm: 0, md: 2 }}
        mt={APP_BAR_HEIGHT}
        mb={{ xs: APP_BOTTOM_BAR_HEIGHT, sm: 0 }}
        sx={{
          flexGrow: 1,
          minWidth: "0px",
          overflow: "auto",
          display: { xs: "flex", sm: "block" },
          flexDirection: "column",
          minHeight: { xs: "calc(100vh - 185px)", sm: "initial" },
        }}
      >
        <RenderMediaQueryComponent mediaKey="sm" method="down">
          <Box display="flex" width={1} p={1}>
            <Box height={1} width={1} mr={0.5}>
              <OutstandingBalance comment="Balance" renderIcon={<OutstandingBalanceIcon name="dashboard" />} />
            </Box>
            <Box height={1} width={1} ml={0.5}>
              <Manager title="Personal manager" />
            </Box>
          </Box>
          <Divider
            sx={{
              backgroundColor: "transparent.light",
            }}
            orientation="horizontal"
            flexItem
          />
        </RenderMediaQueryComponent>
        <Outlet />
      </Box>
      <RenderMediaQueryComponent mediaKey="sm" method="down">
        <CabinetNavBarMob />
      </RenderMediaQueryComponent>
    </Box>
  </Provider>
);

export default CabinetLayout;

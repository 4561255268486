// @flow
/* eslint-disable react/require-default-props, react/default-props-match-prop-types */
import React, { type Node } from "react";

type Props = {
  x: number,
  y: number,
  payload: {
    value: string,
  },
  textAnchor: string,
  axisStyle: {
    fill: string,
    fontSize: string|number,
  },
  colorLine: string,
  tickFormatter: (string) => string,
}

function XAxisContent({
  tickFormatter, axisStyle, x, y, payload, textAnchor, colorLine,
}: Props): Node {
  const circleOffset: number = 22;
  return (
    <>
      <circle
        r="2.5"
        fill={colorLine}
        cx={x}
        cy={y - circleOffset}
      />
      <text textAnchor={textAnchor} x={x} y={y} style={axisStyle}>{ tickFormatter(payload.value) }</text>
    </>
  );
}

export default XAxisContent;

// @flow
import React, { type Node } from "react";
import { Divider } from "@mui/material";

const DividerBlock: ({ sx?: * }) => Node = ({ sx }): Node => (
  <>
    <Divider
      sx={{
        width: "1px",
        backgroundColor: "#273d4d",
        display: { xs: "none", lg: "block" },
        ml: { xs: 0, lg: 2 },
        ...sx,
      }}
      orientation="vertical"
      flexItem
    />
    <Divider
      sx={{
        width: "1px",
        backgroundColor: "#000",
        display: { xs: "none", lg: "block" },
        mr: { xs: 0, lg: 2 },
        ...sx,
      }}
      orientation="vertical"
      flexItem
    />
  </>
);

export default DividerBlock;

/* eslint-disable import/max-dependencies */
// @flow
import React, { type Element, type Node, type StatelessFunctionalComponent as FunctionComponent } from "react";
import { Helmet } from "react-helmet";
import { withStyles } from "@mui/styles";
import {
  SetupCard,
  withState,
  type CardSetupProps,
  TrackingCodeV2,
  type TrackingCodeV2Props,
  AdditionalPostbacks as AdditionalPostbacksList,
  type AdditionalPostbacksProps as AdditionalPostbacksListProps,
  type TabsSwitcherProps,
  withHookPropsState,
  EventPostbackList,
} from "@fas/cpa-cabinet-ui";
import { useEventsPostback, type UseEventsPostback } from "@fas/cpa-state-manager/redux/hooks/useEventsPostback";
import {
  useTrackingTools,
  useAdditionalPostbacks,
  type UseTrackingTools,
  type UseAdditionalPostbacksHook,
} from "@fas/cpa-state-manager/redux/hooks";
import { Navigate } from "react-router-dom";
import environment from "environment";
import TabsSwitcher from "@fas/cpa-cabinet-ui/lib/TabsSwitcher";
import { Box } from "@mui/material";
import {
  setupCardStyles,
  trackingCodeStyles,
  additionalPostbackStyles,
  eventPostbackStyles,
} from "../../styles";
import {
  useAddInfoPostbacks,
  useTabs,
  type UseTabsArgs,
} from "../../hooks";
import { POSTBACKS_PAGE } from "../../constants";
import PostbackLayout from "./PostbackLayout";
import withNoData from "../WithNoData";
import NoData from "../NoPostbacksData";
import ToolTip from "../Reports/Tooltip";
import tabsSwitcher from "../../styles/tabsSwitcher";
import SelectPopup from "../SelectPopup";
import { hiddenSearchInputClearIconSelectPopup } from "../../styles/hiddenSearchInputSelectPopup";
import AdditionalTabButton from "../Reports/AdditionalTabButton";

type Props = {
  param: string,
};

type InfoPostbackProps = $Diff<*, UseEventsPostback>;
type TrackingCodeProps = $Diff<TrackingCodeV2Props, UseTrackingTools>;
type AdditionalPostbacksProps = $Diff<AdditionalPostbacksListProps, UseAdditionalPostbacksHook>;
type AdditionalComponent = FunctionComponent<AdditionalPostbacksProps>;

const EventPostbacks: FunctionComponent<InfoPostbackProps> = withNoData(
  (): Element<*> => <NoData title="No info postback yet" description="You currently have no info postbacks. Set them up to see other events (such as confirmations or payments) besides those set in the main postback. To do this, please go to the informational events postback section, press the Create new postback button in the upper right corner, and follow the instructions." />,
  // $FlowFixMe
  EventPostbackList
);

const StyledSetupCard: FunctionComponent<CardSetupProps> = withStyles(setupCardStyles)(SetupCard);
const StyledInfoPostbacks: FunctionComponent<InfoPostbackProps> = withStyles(eventPostbackStyles)(EventPostbacks);
const InfoPostbacks: FunctionComponent<InfoPostbackProps> = withState(StyledInfoPostbacks, useEventsPostback);
const StyledTrackingCode: FunctionComponent<TrackingCodeV2Props> = withStyles(trackingCodeStyles)(TrackingCodeV2);
const TrackingCode: FunctionComponent<TrackingCodeProps> = withState(StyledTrackingCode, useTrackingTools);
const AdditionalPostbacks: FunctionComponent<AdditionalPostbacksProps> = withState(
  AdditionalPostbacksList,
  useAdditionalPostbacks
);
const StyledAdditionalPostbacks: AdditionalComponent = withStyles(additionalPostbackStyles)(AdditionalPostbacks);
const StyledTabs: FunctionComponent<$Diff<TabsSwitcherProps, { classes: * }>> = withStyles(tabsSwitcher)(TabsSwitcher);
const Tabs: FunctionComponent<{
  hookProps: UseTabsArgs,
  allowScrollButtonsMobile?: boolean,
  scrollButtons?: string | boolean,
  variant?: string,
}> = withHookPropsState(StyledTabs, useTabs);
const AddPostbackButton: FunctionComponent<*> = withState(AdditionalTabButton, useAddInfoPostbacks);

export const paramsToComponents: { [string]: FunctionComponent<*> } = {
  defaulPostback: (): Node => (
    <PostbackLayout>
      <Helmet>
        <title>Default postback</title>
      </Helmet>
      <Box sx={{ marginBottom: { xs: "40px" } }}>
        <TrackingCode
          label="Tracking code"
          helperText={"See \"how to\" block below for the details"}
          textFieldVariant="standard"
          buttonSize="small"
        />
      </Box>
    </PostbackLayout>
  ),
  additionalPostback: (): Node => (
    <PostbackLayout>
      <Helmet>
        <title>Additional postbacks</title>
      </Helmet>
      <Box mb={4}>
        <StyledAdditionalPostbacks NoData={(): Element<*> => <NoData title="No additional postback yet" description="You currently have no additional postbacks. If you need to add postbacks from different tracking platforms, please go to the additional postbacks section. To set up an additional postback, feel free to contact your personal manager." />} Tooltip={ToolTip} />
      </Box>
    </PostbackLayout>
  ),
  infoPostback: (): Node => (
    <PostbackLayout>
      <Helmet>
        <title>Info event postbacks</title>
      </Helmet>
      <Box sx={{ marginBottom: { xs: "40px" } }}>
        <InfoPostbacks
          sx={{ marginBottom: { xs: "40px", sm: "0" } }}
          components={{
            SelectField: withStyles(
              hiddenSearchInputClearIconSelectPopup
            )(withState(SelectPopup, (): * => ({ formControlSx: {}, disableSearch: true }))),
            MultiSelectField: withStyles({ inputField: { "& ::placeholder": { opacity: 1 } } })(withState(SelectPopup, (): * => ({ formControlSx: {}, isSplitListBoxColumn: true, placeholder: "All" }))),
          }}
        />
      </Box>
    </PostbackLayout>
  ),
  default: (): Node => <Navigate to={environment.links.notFound} />,
};

const Postbacks: FunctionComponent<Props> = ({ param }) => (
  <StyledSetupCard title="Tracking Tools">
    <Box display="flex" alignItems="flex-end">
      <Box flexGrow={1} minWidth={0}>
        <Tabs hookProps={{ key: POSTBACKS_PAGE }} />
      </Box>
      <AddPostbackButton />
    </Box>
    {paramsToComponents[param]()}
  </StyledSetupCard>
);

export default Postbacks;

// @flow

import type { Presets, Preset } from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePicker.types";
import dayjs from "dayjs";
import en from "dayjs/locale/en-au";

dayjs.locale(en);

// eslint-disable-next-line import/prefer-default-export
export const presets: Presets = [
  {
    label: "Today",
    value: "today",
    start: dayjs().format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Yesterday",
    value: "yesterday",
    start: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "day").format("YYYY-MM-DD"),
  },
  {
    label: "This week",
    value: "thisWeek",
    start: dayjs().startOf("week").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Past week",
    value: "pastWeek",
    start: dayjs().subtract(7, "day").startOf("week").format("YYYY-MM-DD"),
    end: dayjs().subtract(6, "day").endOf("week").format("YYYY-MM-DD"),
  },
  {
    label: "Last 7 days",
    value: "last7day",
    start: dayjs().subtract(6, "day").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "This month",
    value: "thisMonth",
    start: dayjs().startOf("month").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Last 30 days",
    value: "last30day",
    start: dayjs().subtract(29, "day").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
];

export const defaultPresetReport: Preset = presets[4];

export const minDate: string = dayjs(new Date()).subtract(2, "year").format("YYYY-MM-DD");
export const maxDate: string = dayjs(new Date()).format("YYYY-MM-DD");

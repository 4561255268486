// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Download: () => Node = () => (
  <SvgIcon width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_7405_21781)">
      <path fillRule="evenodd" clipRule="evenodd" d="M11 14.17L7.41 10.59L6 12L12 18L18 12L16.59 10.59L13 14.17L13 2L11 2L11 14.17ZM19 20V22H5V20H19Z" />
    </g>
    <defs>
      <clipPath id="clip0_7405_21781">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </SvgIcon>
);

export default Download;

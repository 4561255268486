// @flow
import { useEffect } from "react";
import { useParams, type Match } from "react-router-dom";
import { useDispatch } from "react-redux";
import switchTab from "@fas/cpa-state-manager/redux/actions/tabs";

export type UseParamTabsArgs = {
  pageKey: string,
  paramKey: string,
  paramsToTabs: { [string]: string, ..., },
};

export type UseParamTabsHook = {
  param: string,
};

export const useParamTabs: (UseParamTabsArgs) => UseParamTabsHook = ({
  pageKey,
  paramKey,
  paramsToTabs,
}) => {
  const dispatch: <A>(A) => A = useDispatch();
  const params: $PropertyType<Match, "params"> = useParams();

  const param: string = params[paramKey] && Object.keys(paramsToTabs).includes(params[paramKey]) ? params[paramKey] : "default";

  useEffect(() => {
    if (Object.keys(paramsToTabs).includes(param)) {
      dispatch(switchTab(pageKey, paramsToTabs[param]));
    }
  }, [dispatch, pageKey, param, paramsToTabs]);

  return { param };
};

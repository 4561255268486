// @flow

const openZoneBackground: * = {
  root: {
    height: "100%",

    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    backgroundImage: "url(/web-bg.jpeg)",
  },
};

export default openZoneBackground;

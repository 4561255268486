// @flow
import dayjs from "dayjs";
import en from "dayjs/locale/en-au";
import type { Presets, Preset } from "@fas/cpa-cabinet-ui/lib/DateRangePicker/DateRangePicker.types";

dayjs.locale(en);

/**
 * Presets for dashboard
 */
export const presets: Presets = [
  {
    label: "This week",
    value: "thisWeek",
    start: dayjs().startOf("w").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Last 7 days",
    value: "last7day",
    start: dayjs().subtract(6, "day").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Last week",
    value: "lastWeek",
    start: dayjs().subtract(1, "week").startOf("week").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "week").endOf("week").format("YYYY-MM-DD"),
  },
  {
    label: "This month",
    value: "thisMonth",
    start: dayjs().startOf("month").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Last month",
    value: "lastmonth",
    start: dayjs().subtract(1, "month").startOf("month").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  },
  {
    label: "Last 6 months",
    value: "last6Month",
    start: dayjs().subtract(6, "month").startOf("month").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "month").endOf("month").format("YYYY-MM-DD"),
  },
  {
    label: "This year",
    value: "thisYear",
    start: dayjs().startOf("year").format("YYYY-MM-DD"),
    end: dayjs().format("YYYY-MM-DD"),
  },
  {
    label: "Last year",
    value: "lastYear",
    start: dayjs().subtract(1, "year").startOf("year").format("YYYY-MM-DD"),
    end: dayjs().subtract(1, "year").endOf("year").format("YYYY-MM-DD"),
  },
];

export const defaultPreset: Preset = presets[1];

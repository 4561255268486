/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Outlet } from "react-router-dom";
import { Provider } from "react-redux";
import NotificationsComponent from "@fas/cpa-cabinet-ui/lib/Notifications";
import withNotifications from "@fas/cpa-state-manager/services/notifications";
import { withStyles } from "@mui/styles";
import { Paper } from "@mui/material";
import configureStore, { type CabinetStore } from "./store";
import { openZoneBackgroundStyles } from "../../styles";

const Background: StatelessFunctionalComponent<*> = withStyles(openZoneBackgroundStyles)(Paper);

// $FlowFixMe
const Notifications: StatelessFunctionalComponent<{}> = withNotifications(NotificationsComponent);

const store: CabinetStore = configureStore();

export const serviceRoutes: string[] = ["/unsubscribe/:token"];

// eslint-disable-next-line react/function-component-definition
const Service: StatelessFunctionalComponent<{}> = () => (
  <Provider store={store}>
    <Notifications />
    <Background>
      <Outlet />
    </Background>
  </Provider>
);

export default Service;

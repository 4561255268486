// @flow
import defaultObject from "./default";

export type { DefaultTheme } from "./default";

const themeObject: * = {
  defaultObject,
};

export default themeObject;

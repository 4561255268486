/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent } from "react";
import { Skeleton, Typography } from "@mui/material";
import { BEST_OFFERS_LIST } from "@fas/cpa-state-manager/redux/constants";
import { withStyles } from "@mui/styles";
import { Table, withHookPropsState, withState } from "@fas/cpa-cabinet-ui";
import { useSelector } from "react-redux";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import { reportTableStyles } from "../../styles";
import withNoData from "../WithNoData";
import NoBestOffersData from "../NoBestOffersData";
import { useBestOffers } from "../../hooks";
import ReportSkeletonLoading from "../Report/ReportSkeletonLoading";
import { useBestOffersInfo } from "../../hooks/useBestOffers/useBestOffers";

const StyledBestOffers = withStyles(reportTableStyles)(Table);

const BestOffersWithLoading = withNoData(NoBestOffersData, StyledBestOffers);
// $FlowFixMe
const BestOffers = withState(BestOffersWithLoading, useBestOffers);

const ReportLoading = withHookPropsState(ReportSkeletonLoading, ({ tableKey }: { tableKey: string }): * => {
  const isLoading = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, tableKey));
  const isFetched = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, `${BEST_OFFERS_LIST}-fetched`));
  return {
    isLoading: isLoading && !isFetched,
    fieldsLength: 3,
  };
});

type Props = {};

const BestOffersTitle = withState(({ isLoading, title }) => (
  <>
    {isLoading ? (
      <Skeleton variant="rounded" sx={{ height: 42 }} />
    ) : (
      <Typography variant="gradientH4" noWrap data-testid="best-opportunities-title">{ title || "Best Opportunities" }</Typography>
    )}
  </>
), useBestOffersInfo);

const BestOffersComponent: StatelessFunctionalComponent<Props> = ({}) => (
  <>
    <BestOffersTitle />
    <BestOffers>
      <ReportLoading hookProps={{ tableKey: BEST_OFFERS_LIST }} />
    </BestOffers>
  </>
);

export default BestOffersComponent;

/* eslint-disable import/max-dependencies */
// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import { Form } from "@fas/ui-core";
import withForm, { type Props as FormProps } from "@fas/ui-framework/lib/services/form/withForm";
import {
  Button,
  Box,
} from "@mui/material";
import { useDictionary } from "@fas/cpa-state-manager/redux/hooks";
import { withHookPropsState } from "@fas/cpa-cabinet-ui";
import {
  InsertLink,
  AssignmentIndOutlined,
  BusinessCenterOutlined,
  LocationOnOutlined,
  LockOutlined,
} from "@mui/icons-material";
import { Helmet } from "react-helmet";
import { withStyles } from "@mui/styles";
import type { ComponentProps } from "@fas/cpa-cabinet-ui/lib/withState/withState.types";
import { type UseDictionaryType } from "@fas/cpa-state-manager/redux/hooks/useDictionary/useDictionary";
import type { Dictionaries } from "@fas/cpa-state-manager/redux/reducers";
import { CONTACT_INFO_FORM } from "../../constants";
import TextField from "../TextFieldForm";
import schema from "./validation";
import { getValidation } from "../../utils";
import { SelectPopupField, type Props as SelectProps } from "../SelectPopup/SelectPopup";
import ShowByValue from "../UserInfo/ShowByValue";
import Home from "../../icons/Home";
import Phone from "../../icons/Phone";
import Tax from "../../icons/Tax";

const ContactInfoForm: StatelessFunctionalComponent<FormProps> = withForm(Form);

const CountrySelect: StatelessFunctionalComponent<
  ComponentProps<SelectProps, Dictionaries, UseDictionaryType>
> = withHookPropsState(SelectPopupField, useDictionary);

type Props = {
  isLoading: boolean,
  disabled: boolean,
  onSave: () => mixed,
  onKeyPress: (SyntheticKeyboardEvent<*>) => mixed,
};
const Layout: StatelessFunctionalComponent<*> = withStyles((theme: *): * => ({
  root: {
    maxWidth: "800px",
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    [theme.breakpoints.up("md")]: {
      "& > div:nth-child(1n)": {
        width: `calc(50% - ${theme.spacing(2)})`,
      },
      "& > div:has([data-testid=\"address\"])": {
        width: "100%",
      },
    },
    "& > hr": {
      width: "100%",
      border: 0,
      margin: 0,
    },

    "& > button": {
      display: "block",
      marginTop: theme.spacing(1),
      maxWidth: "210px",
    },
    [theme.breakpoints.down("sm")]: {
      "& > button": {
        maxWidth: "100%",
      },
    },
  },
}))(({ children, classes }: *): Node => <Box className={classes.root}>{children}</Box>);

const ContactInfo: StatelessFunctionalComponent<Props> = ({
  isLoading,
  disabled,
  onSave,
  onKeyPress,
}) => {
  const tooltipIconProps = {
    ...(disabled ? { EndIcon: <LockOutlined /> } : {}),
    slotProps: {
      tooltip: {
        title: disabled && "For any updates in this field, please contact your account manager.",
      },
    },
  };

  return (
    <>
      <Helmet>
        <title>Contact info</title>
      </Helmet>
      <ContactInfoForm id={CONTACT_INFO_FORM} onSave={onSave} validate={getValidation(schema)}>
        <Layout>
          <TextField
            name="companyType"
            label="I am"
            StartIcon={<AssignmentIndOutlined />}
            onKeyPress={onKeyPress}
            disabled
            EndIcon={<LockOutlined />}
            slotProps={{
              tooltip: {
                title: "For any updates in this field, please contact your account manager.",
              },
            }}
          />
          <ShowByValue name="companyType" compare={(companyType: string): boolean => companyType === "Company Representative"}>
            <TextField
              name="companyName"
              label="Company Name"
              placeholder="What is your company name?"
              StartIcon={<BusinessCenterOutlined />}
              onKeyPress={onKeyPress}
              disabled
              EndIcon={<LockOutlined />}
              slotProps={{
                tooltip: {
                  title: "For any updates in this field, please contact your account manager.",
                },
              }}
            />
            <TextField
              name="companyUrl"
              label="Website"
              placeholder="What is your website URL?"
              StartIcon={<InsertLink />}
              onKeyPress={onKeyPress}
              disabled
              EndIcon={<LockOutlined />}
              slotProps={{
                tooltip: {
                  title: "For any updates in this field, please contact your account manager.",
                },
              }}
            />
          </ShowByValue>
          <CountrySelect
            name="country"
            label="Country"
            placeholder="Select your country"
            hookProps="countryId"
            StartIcon={<LocationOnOutlined />}
            disabled
            EndIcon={<LockOutlined />}
            slotProps={{
              tooltip: {
                title: "For any updates in this field, please contact your account manager.",
              },
            }}
            defaultValue=""
            getOptionDisabled={(option) => option.hidden}
          />
          <TextField
            name="address"
            label="Address"
            placeholder="Your ZIP/postcode, city, st., bldg."
            StartIcon={<Home />}
            onKeyPress={onKeyPress}
            disabled
            EndIcon={<LockOutlined />}
            slotProps={{
              tooltip: {
                title: "For any updates in this field, please contact your account manager.",
              },
            }}
          />
          <TextField
            {...tooltipIconProps}
            name="phoneNumber"
            label="Phone number"
            placeholder="What is your phone number?"
            StartIcon={<Phone />}
            disabled={isLoading || disabled}
            inputProps={{ inputMode: "tel" }}
          />
          <TextField
            {...tooltipIconProps}
            name="taxSSN"
            label="Tax SNN/EIN (USA only)"
            placeholder="What is your Tax SNN/EIN?"
            StartIcon={<Tax />}
            onKeyPress={onKeyPress}
            disabled={isLoading || disabled}
          />
          <hr />
          <Button
            data-testid="save-contact-info"
            fullWidth
            variant="gradient"
            type="submit"
            disabled={isLoading || disabled}
          >
            Update
          </Button>
        </Layout>
      </ContactInfoForm>
    </>
  );
};

export default ContactInfo;

// @flow
import React, { type StatelessFunctionalComponent, type Node } from "react";
import { Box } from "@mui/material";

type Props = {
  children?: Node,
};

const ScrollableCardContent: StatelessFunctionalComponent<Props> = ({ children, ...props }) => (
  <Box {...props} style={{ height: "100%", overflowY: "auto" }}>
    {children}
  </Box>
);

export default ScrollableCardContent;

// @flow
import { type DefaultTheme } from "../themes";

type Props = {
  authenticated: string,
  default: boolean,
  disabled: boolean,
  ...,
};

const formFieldInput: (DefaultTheme) => * = (theme) => {
  const autoFillStyles: * = {
    border: "none",
    "-webkit-text-fill-color": theme.palette.text.main,
    caretColor: theme.palette.text.main,
    "-webkit-box-shadow": `0 0 0px 1000px ${theme.palette.transparent.full} inset`,
    "transition": "background-color 5000s ease-in-out 0s",
  };

  return {
    root: {
      color: (props: Props): string => (props.default ? theme.palette.transparent.text : theme.palette.text.main),
      "& input:-webkit-autofill, input:-webkit-autofill:hover": autoFillStyles,
    },
    focused: {
      background: theme.palette.transparent.main,
      "& input:-webkit-autofill:focus, input:-webkit-autofill:hover": autoFillStyles,
    },
    formControl: {
      borderLeft: (props: Props): string => (!props.authenticated === "true" ? `2px solid ${theme.palette.gradient.start}` : "none"),
    },
    disabled: {
      cursor: "not-allowed",
      color: theme.palette.transparent.disabled,
      "-webkit-text-fill-color": `${theme.palette.transparent.disabled} !important`,
    },
  };
};

export default formFieldInput;

// @flow
import { type DefaultTheme } from "../themes";

const popover: (DefaultTheme) => * = (theme) => ({
  paper: {
    marginTop: theme.spacing(1),
    borderRadius: theme.shape.cardBorderRadius,
    padding: theme.spacing(2),
    overflowY: "auto",
    boxShadow: "0px 0px 50px 0px rgba(255, 255, 255, 0.2)",

    [theme.breakpoints.down("sm")]: {
      marginTop: 0,
      borderRadius: 0,
      maxHeight: "100dvh",
      height: "100dvh",
      maxWidth: "100vw",
      width: "100%",
      padding: theme.spacing(1),
    },
  },
});

export default popover;

// @flow
import type { Environment } from "@fas/cpa-state-manager/services/auth";

const getEndpoints: ({ baseUrl: string, reCaptchaKey?: string }) => Environment = ({ baseUrl, reCaptchaKey = "" }) => ({
  baseUrl,
  reCaptchaKey,
  links: {
    blog: "https://adsempire.com/blog/ ",
    home: "/dashboard",
    notFound: "/notFound",
    restorePassword: "/restorePassword",
    login: "/login",
    logout: "/logout",
    dashboard: "/dashboard",
    links: "/links",
    reports: "/reports/:type",
    postbacks: "/postbacks/:type",
    faq: "/faq",
    userInfo: "/profile/user",
    userPaymentDetails: "/profile/payment",
    paymentHistory: "/paymentHistory",
    profile: "/profile/:page",
  },
  endpoints: {
    get: {
      userInfo: `${baseUrl}/api/v1/userInfo`,
      managerInfo: `${baseUrl}/api/v1/managerContactInfo`,
      getPaymentInfo: `${baseUrl}/api/v1/paymentInfo`,
      getDropdownList: `${baseUrl}/api/v1/dropdown`,
      contactInfo: `${baseUrl}/api/v1/contactInfo`,
      infoPostback: `${baseUrl}/api/v1/infoPostback`,
      defaultPostback: `${baseUrl}/api/v1/defaultPostback`,
      additionalPostbacks: `${baseUrl}/api/v1/additionalPostback`,
      outstandingBalance: `${baseUrl}/api/v1/outstandingBalance`,
      topOffers: `${baseUrl}/api/v1/topOffers`,
      smartOfferCheck: `${baseUrl}/api/v1/smartOfferCheck`,
      getSmartOfferList: `${baseUrl}/api/v1/smartOffer`,
      getCustomLinkList: `${baseUrl}/api/v1/affCustomLinks`,
      getModals: `${baseUrl}/api/v1/modal`,
      getNatureOfTraffic: `${baseUrl}/api/v1/natureOfTraffic`,
    },
    post: {
      login: `${baseUrl}/api/v1/auth/login`,
      changePassword: `${baseUrl}/api/v1/auth/changePassword`,
      restorePassword: `${baseUrl}/api/v1/auth/restorePassword`,
      autologin: `${baseUrl}/api/v1/auth/autologin`,
      userInfo: `${baseUrl}/api/v1/userInfo`,
      paymentInfo: `${baseUrl}/api/v1/paymentInfo`,
      contactInfo: `${baseUrl}/api/v1/contactInfo`,
      infoPostback: `${baseUrl}/api/v1/infoPostback`,
      defaultPostback: `${baseUrl}/api/v1/defaultPostback`,
      mainReport: `${baseUrl}/api/v1/reportMain`,
      mainReportExport: `${baseUrl}/api/v1/reportMainExport`,
      getDashboardData: `${baseUrl}/api/v1/reportDashboard`,
      payoutHistory: `${baseUrl}/api/v1/payoutHistory`,
      payoutHistoryExport: `${baseUrl}/api/v1/payoutHistoryExport`,
      transactionReport: `${baseUrl}/api/v1/reportTransaction`,
      transactionReportExport: `${baseUrl}/api/v1/reportTransactionExport`,
      unsubscribe: `${baseUrl}/api/v1/auth/unsubscribe`,
      updateNatureOfTraffic: `${baseUrl}/api/v1/natureOfTraffic`,
    },
    put: {
      infoPostback: `${baseUrl}/api/v1/infoPostback`,
      generateSmartOffer: `${baseUrl}/api/v1/smartOffer`,
    },
    delete: {
      infoPostback: `${baseUrl}/api/v1/infoPostback`,
    },
    redirect: {},
  },
});

export default getEndpoints;

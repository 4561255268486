// @flow
import React, { type Node } from "react";
import { SvgIcon } from "@mui/material";

const Home: () => Node = () => (
  <SvgIcon width="26" height="28" viewBox="0 0 26 28" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7 21H10V15H16V21H19V12L13 7.5L7 12V21ZM5 23V11L13 5L21 11V23H14V17H12V23H5Z" />
  </SvgIcon>
);

export default Home;

// @flow
import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getOutstandingBalanceInfoBalance } from "@fas/cpa-state-manager/services/selectors/outstandingBalanceInfo";
import { setOutstandingBalanceInfo } from "@fas/cpa-state-manager/redux/actions/outstandingBalanceInfo";
import { useHandleRequest, type UseHandleRequestHook } from "@fas/cpa-state-manager/redux/hooks";
import { setLoading } from "@fas/cpa-state-manager/redux/actions/loading";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import { OUTSTANDING_BALANCE } from "../../constants";
import { getOutstandingBalance } from "../../services/deprecatedApi";

export type UseOutstandingBalanceHook = {
  isLoading?: boolean,
  balance: number,
  comment: string,
};

export const useOutstandingBalance: () => UseOutstandingBalanceHook = () => {
  const comment: string = "Outstanding balance";
  const balance: number = useSelector(getOutstandingBalanceInfoBalance);
  const dispatch: <A>(A) => A = useDispatch();
  const isFetched: * = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, `${OUTSTANDING_BALANCE}-fetched`));

  const {
    isLoading,
    changeLoading,
    onFail,
    onFinally,
  }: UseHandleRequestHook = useHandleRequest(OUTSTANDING_BALANCE);

  useEffect(() => {
    changeLoading(true);

    getOutstandingBalance()
      .then((newBalance: string): * => {
        dispatch(setOutstandingBalanceInfo({ balance: Number(newBalance.split("$")[1]) }));
        dispatch(setLoading(`${OUTSTANDING_BALANCE}-fetched`, true));
      })
      .catch(onFail)
      .finally(onFinally);
  }, []);

  return {
    isLoading: isLoading && !isFetched,
    balance,
    comment,
    disabled: isLoading,
  };
};

// @flow
/* eslint-disable import/max-dependencies */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHandleRequest, type UseHandleRequestHook, useIsAffiliate } from "@fas/cpa-state-manager/redux/hooks";
import { useEnterPress, type UseEnterPressHook } from "@fas/cpa-state-manager/hooks";
import { setFormData } from "@fas/ui-framework/lib/redux/actions/form";
import { getFormData } from "@fas/ui-framework/lib/redux/selectors/form";
import { addNotification } from "@fas/ui-framework/lib/redux/actions/notifications";
import { setErrors } from "@fas/ui-framework/lib/redux/actions/errors";
import { prepareFetchErrors } from "@fas/ui-framework/lib/services/generators";
import { CONTACT_INFO_FORM } from "../../constants";
import { getUserContactInfo, postUserContactInfo, type UserContactInfo } from "../../services/deprecatedApi";

export type UseContactInfoFormHook = {
  isLoading: boolean,
  disabled: boolean,
  onSave: () => mixed,
  onKeyPress: (SyntheticKeyboardEvent<*>) => mixed,
};

export const useContactInfoForm: () => UseContactInfoFormHook = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const {
    isLoading,
    changeLoading,
    onFail,
    onFinally,
  }: UseHandleRequestHook = useHandleRequest(CONTACT_INFO_FORM);
  const form: * = useSelector((state: *): * => getFormData(state, CONTACT_INFO_FORM));

  useEffect(() => {
    changeLoading(true);

    getUserContactInfo()
      .then((data: UserContactInfo): mixed => dispatch(setFormData(CONTACT_INFO_FORM, data)))
      .catch(onFail)
      .finally(onFinally);
  }, []);

  const onSave: () => mixed = () => {
    changeLoading(true);

    const { phoneNumber, taxSSN }: * = form;
    postUserContactInfo({ phoneNumber, taxSSN })
      .then((): mixed => dispatch(addNotification({ severity: "success", message: "Contact info updated successfully" })))
      .catch(async (err: *) => {
        await onFail(err);

        if (err.isAxiosError
          && err.response
          && err.response.data
          && err.response.data.errors
          && Array.isArray(err.response.data.errors)) {
          dispatch(setErrors(prepareFetchErrors(err.response.data.errors)));
        }
      })
      .finally(onFinally);
  };

  const { onKeyPress }: UseEnterPressHook = useEnterPress(onSave);

  return {
    isLoading,
    disabled: !useIsAffiliate(),
    onSave,
    onKeyPress,
  };
};

// @flow
import { type DefaultTheme } from "../themes";

type Props = {
  authenticated: string,
  ...,
};

// eslint-disable-next-line import/prefer-default-export
export const formFieldLabelStyles: (DefaultTheme) => * = (theme) => ({
  root: {
    color: theme.palette.text.main,
    textAlign: (props: Props): string => (props.authenticated === "true" ? "left" : "center"),
    "& > p": {
      fontWeight: (props: Props): number => (props.authenticated === "true" ? theme.typography.bodyBold.fontWeight : theme.typography.h5.fontWeight),
      fontSize: (props: Props): string => (props.authenticated === "true" ? theme.typography.bodyBold.fontSize : theme.typography.h5.fontSize),
      lineHeight: (props: Props): string | number => (props.authenticated === "true" ? theme.typography.bodyBold.lineHeight : theme.typography.h5.lineHeight),
    },
  },
  focused: {
    color: `${theme.palette.text.main} !important`,
    "& > p": {
      fontWeight: 700,
    },
  },
});

// @flow
import Joi from "joi";

const schema: * = Joi.object({
  password: Joi.string().required(),
  confirmation: Joi
    .string()
    .required()
    .equal(Joi.ref("password"))
    .options({ messages: { "any.only": "Confirm password doesn't match" } }),
})
  .messages({
    "string.empty": "This field is required",
    "string.email": "Email is not valid",
  });

export default schema;

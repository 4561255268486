// @flow

import {
  Skeleton, TableBody, TableCell, TableRow,
} from "@mui/material";
import React from "react";
import type { Node, StatelessFunctionalComponent } from "react";

type Props = {
  isLoading: boolean,
  fieldsLength: number,
  ...,
};

const ReportSkeletonLoading: StatelessFunctionalComponent<Props> = ({
  isLoading,
  fieldsLength = 0,
}) => (isLoading ? (
  <TableBody sx={{ display: { xs: "flex", md: "table-row-group" }, gridArea: "total" }}>
    {Array.from({ length: 10 }, (_: *, index: number): Node => (
      <TableRow key={index}>
        <TableCell colSpan={500} sx={{ px: 0, py: "1px", display: { xs: "none", md: "table-cell" } }}>
          <Skeleton
            variant="rounded"
            sx={{
              height: {
                md: 20, lg: 28,
              },
            }}
          />
        </TableCell>
        {Array.from({ length: fieldsLength }).map((__: *, idx: number): Node => (
          // eslint-disable-next-line react/no-array-index-key
          <TableCell key={idx} sx={{ padding: "1px", display: { xs: "block", md: "none" }, minWidth: "100px" }}>
            <Skeleton
              variant="rect"
              sx={{
                height: {
                  xs: 22,
                  sm: 38,
                },
              }}
            />
          </TableCell>
        ))}
      </TableRow>
    ))}
  </TableBody>
) : null);

export default ReportSkeletonLoading;

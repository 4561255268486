// @flow
import React, { type Node } from "react";

const WarningIcon: ({ className?: string }) => Node = ({ className }) => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
    <path d="M1.75 10C1.75 5.44365 5.44365 1.75 10 1.75C14.5563 1.75 18.25 5.44365 18.25 10C18.25 14.5563 14.5563 18.25 10 18.25C5.44365 18.25 1.75 14.5563 1.75 10ZM10 0.25C4.61522 0.25 0.25 4.61522 0.25 10C0.25 15.3848 4.61522 19.75 10 19.75C15.3848 19.75 19.75 15.3848 19.75 10C19.75 4.61522 15.3848 0.25 10 0.25ZM10 5C10.4142 5 10.75 5.33579 10.75 5.75V10.25C10.75 10.6642 10.4142 11 10 11C9.58579 11 9.25 10.6642 9.25 10.25V5.75C9.25 5.33579 9.58579 5 10 5ZM11 13.75C11 14.3023 10.5523 14.75 10 14.75C9.44771 14.75 9 14.3023 9 13.75C9 13.1977 9.44771 12.75 10 12.75C10.5523 12.75 11 13.1977 11 13.75Z" fill="url(#paint0_linear_4400_4568)" />
    <defs>
      <linearGradient id="paint0_linear_4400_4568" x1="0.25" y1="10" x2="19.75" y2="10" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FF9000" />
        <stop offset="1" stopColor="#FFD33A" />
      </linearGradient>
    </defs>
  </svg>
);

export default WarningIcon;

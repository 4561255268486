// @flow
import { type DefaultTheme } from "../themes";

const affiliateSmartlinkCard: (DefaultTheme) => * = (theme) => ({
  root: {
    boxShadow: "none",
    borderRadius: theme.shape.borderRadius,
    color: theme.palette.common.black,
    background: `linear-gradient(to right, ${theme.palette.gradient.start}, ${theme.palette.gradient.stop})`,
    padding: theme.spacing(2),
  },
  content: {
    padding: 0,
  },
  nameContainer: {
    marginBottom: "10px",
  },
  name: {
    ...theme.typography.responsiveH5,
    color: theme.palette.common.black,
    wordBreak: "break-all",
  },
  link: {
    marginTop: 0,
    ...theme.typography.subtext,
    color: theme.palette.transparent.contrast,
    overflowWrap: "anywhere",
  },
  actions: {
    padding: 0,
    marginTop: theme.spacing(1),
  },
  copyBtn: {
    width: "140px",
  },
  optionsContainer: {
    display: "flex",
    flexWrap: "wrap",
  },
  option: {
    borderRadius: theme.shape.borderRadius,
    alignItems: "center",
    backgroundColor: "rgba(0, 0, 0, 0.1)",
    display: "flex",
    justifyContent: "center",
    marginRight: "10px",
    marginBottom: "10px",

    padding: theme.spacing(1),
    height: "38px",

    "&.MuiTypography-root": {
      ...theme.typography.subtextBold,
    },
  },
});

export default affiliateSmartlinkCard;

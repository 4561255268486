// @flow
/* eslint-disable import/max-dependencies */
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setGeneratedLinks } from "@fas/cpa-state-manager/redux/actions/linkGenerator/actions";
import type { UseHandleRequestHook } from "@fas/cpa-state-manager/redux/hooks";
import { useHandleRequest } from "@fas/cpa-state-manager/redux/hooks";
import type { AppState } from "@fas/cpa-state-manager/redux/reducers/linkGenerator/reducer";
import { getGeneratedLinks } from "@fas/cpa-state-manager/services/selectors";
import type { StoreWithLoading } from "@fas/cpa-state-manager/services/selectors/loading";
import { getLoadingState } from "@fas/cpa-state-manager/services/selectors/loading";
import { setLoading } from "@fas/cpa-state-manager/redux/actions/loading";
import { getAffiliateSmartOfferList, getManagerCustomLinkList } from "../../services/deprecatedApi";
import type { SmartOfferType } from "../../components/SmartlinkList/types/SmartlinkList.types";

export type UseSmartlinkListHook = {
  isLoading: boolean,
  disabled: boolean,
  list: SmartOfferType[],
};

export const useSmartlinkList: () => UseSmartlinkListHook = () => {
  const dispatch: <A>(A) => A = useDispatch();
  const {
    isLoading,
    changeLoading,
    onFail,
    onFinally,
  }: UseHandleRequestHook = useHandleRequest("smartOfferListLoading");
  const isFetched: * = useSelector((state: StoreWithLoading): boolean => getLoadingState(state, "smartOfferListLoading-fetched"));

  const list: SmartOfferType[] = useSelector((state: AppState): SmartOfferType[] => getGeneratedLinks(state));

  async function getList() {
    try {
      changeLoading(true);
      const [affiliateLinkList, managerLinkList]: [SmartOfferType[], SmartOfferType[]] = await Promise.all([
        getAffiliateSmartOfferList(),
        getManagerCustomLinkList(),
      ]);
      dispatch(setGeneratedLinks([...affiliateLinkList, ...managerLinkList]));
      dispatch(setLoading("smartOfferListLoading-fetched", true));
    }
    catch (e) {
      onFail(e);
    }
    finally {
      onFinally();
    }
  }

  useEffect(() => {
    getList();
  }, []);

  return {
    isLoading: isLoading && !isFetched,
    disabled: isLoading,
    list,
  };
};
